import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/index.css";
import axios from "axios";

import LogoMissionLanding from "../../images/logos/fyxd-logo-header-mission-page.svg";
import LogoBack from "../../images/logos/fyxd-logo-header-back.svg";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import { Navbar } from "../components/navbar";
import Tab from "../../components/MissionTab";
import { useNavigate } from "react-router-dom";

import MissionBg from "../../images/icons/v4/missiondetail/shopout.png";
import IconCoffee from "../../images/icons/v4/missiondetail/coffee_icon.svg";
import IconStar from "../../images/icons/v4/missiondetail/star_icon.svg";
import IconCup from "../../images/icons/v4/missiondetail/cup_icon.svg";
import IconTrophy from "../../images/icons/v4/missiondetail/trophy_w_icon.svg";

export default function MissionDetailPage() {
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;

  const [accessToken, setAccessToken] = useState("");
  const [transactionList, setTransactionList] = useState([]);

  let Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    // userName = name;
    Email = email;
  }

  const requestToken = async () => {
    await requestAccessToken();
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    requestToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if (Email.length > 0 && accessToken.length > 0) getTransaction();
    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div className="w-full" style={{ width: "100vw", height: "130vh" }}>
          <div className="static w-full bg-gray-100 p-3   text-center text-white">
            <div className="mt-1 flex text-center ">
              <a href="#/missionlanding/0">
                <img
                  src={LogoBack}
                  alt="Logo"
                  style={{ display: "inline-block" }}
                  className="mx-auto w-[10px] h-[30px]"
                />
              </a>
              <img
                src={LogoMissionLanding}
                alt="Logo"
                style={{ marginLeft: "0.5rem", display: "inline-block" }}
                className="mx-auto w-[298px] h-[38px]"
              />
            </div>
          </div>
          <div>
            <img className="w-full" src={MissionBg} alt="" />
          </div>
          <div className="px-4 text-center">
            <h1 className="mt-3 font-bold mb-2 text-xl">Shop Out Loud</h1>
            <span className="text-gray-500">
              Make 10 transactions at the Dilly Dally Kaffe to receive a reward.
              Each transaction must have a value of over 100 FYX-D to receive an
              e-stamp. Each e-stamp can only be collected automatically once
              daily.
            </span>
          </div>
          <div className="px-4 mt-3">
            <div className="relative mt-3">
              <img
                src={IconCoffee}
                className="absolute -top-3 left-0 w-[60px] z-2"
                alt=""
              />
              <div className="border-2 border-yellow-500 rounded-3xl p-2 text-center">
                <span>Free 1 DTGO mug</span>
              </div>
            </div>
          </div>
          <div className="mt-5 px-4">
            <span>Purchase 4 items from Forget-Me-Not-Shop</span>
          </div>
          <div className="mt-1 px-4">
            <div className="px-2 py-2 grid grid-cols-4 gap-3 border-2 border-yellow-500 rounded-md">
              <div className="bg-yellow-400 p-4 rounded-full">
                <img src={IconStar} />
              </div>
              <div className="bg-yellow-400 p-4 rounded-full">
                <img src={IconStar} />
              </div>
              <div className="bg-yellow-400 p-4 rounded-full">
                <img src={IconStar} />
              </div>
              <div className="bg-yellow-400 p-4 rounded-full">
                <img src={IconStar} />
              </div>
            </div>
            {/* <img src={IconCup} />
            <img src={IconTrophy} /> */}
          </div>
          <div className="mt-4 px-4">
            <span>Buy 4 pieces of cake</span>
          </div>
          <div className="mt-1 px-4">
            <div className="bg-yellow-400 rounded-xl p-4 text-center items-center">
              <span className="text-white text-4xl font-black inline-block">
                Completed
              </span>
              <img src={IconTrophy} className="mx-2 w-20 inline-block" />
            </div>
          </div>
          <div className="mt-2 px-4">
            <button className="p-4 bg-gray-400 w-full rounded-2xl">
              Redeem Reward
            </button>
          </div>
          <a href="#/missionlanding/1">
            <div className="text-center mt-2 mb-2">
              <span className="text-yellow-500">View all rewards {" >"}</span>
            </div>
          </a>
          {/* <div className="mb-4 ">
            <Tab />
          </div> */}
          {/* Navbar */}
          <Navbar path="/missionlanding/0" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
