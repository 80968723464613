import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

// Sample app imports
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import LogoFyxD from '../images/logos/fyxd-logo-color.svg';

export default function PlanConsentTCPage() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [isAcceptConsent, setIsAcceptConsent] = useState(false);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const handleChange = (event) => {
        setIsAcceptConsent(event.target.checked);
    };
    
    const handleAccept = async() => {
        if(!isAcceptConsent)
            return;
        // navigate('/planconsentprivacy');
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/consent`, 
            { "plan_id": 3, "type": "tc" }, { headers: { Authorization: 'Bearer ' + accessToken } });

            navigate('/planconsentprivacy');
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "70vh"}}>
                      <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            <span className="float-left font-semibold text-xl cursor-pointer" onClick={goBack}>{'<'}</span>
                            <span className="text-center text-black font-semibold text-xl">Dai Perm</span>
                        </div>
                      </nav>
                      <div>
                        <div className="mt-3">
                            <img src={LogoFyxD} alt="" /><br/>
                            <span className="text-2xl text-gray-500 font-black mb-3 mt-5">Terms and Conditions</span><br/><br/>
                 
                            <span className="mt-3 mb-3">
                                Last updated: 29th Nov 2023
                            </span><br/><br/>
                      
                            <p>
                            แผน “ได้เพิ่ม” <br/>เป็นโครงการส่งเสริมและตอบแทนสิทธิประโยชน์ให้แก่พนักงานตลอดจนบุคลากรของดีทีจีโอและกลุ่มบริษัทในเครือ ที่ได้เข้าร่วมและใช้งานฟิกซ์-ดี อันเป็นระบบเงินอิเล็กทรอนิกส์ที่พัฒนาและใช้งานในวงจำกัดแบบปิดเพื่อและภายในธุรกิจร้านค้าในระบบนิเวศน์ของดีทีจีโอเท่านั้น ซึ่ง ดีทีจีโอ มีวัตถุประสงค์ที่จะมอบผลประโยชน์ตอบแทนในรูปแบบที่ดีทีจีโอกำหนด ให้แก่แฟมที่สมัครใจเข้าร่วมแผนได้เพิ่ม<br/>
                            <u>ดีทีจีโอ ขอเน้นย้ำและแจ้งให้ทราบว่า โครงการ&quot;ได้เพิ่ม&quot;นี้ จัดทำขึ้นเพื่อส่งเสริมและสนับสนุนเรื่องวินัยทางการเงินของพนักงานและให้ผลประโยชน์ตอบแทนแก่พนักงานของกลุ่มบริษัทดีทีจีโอเท่านั้น  ดีทีจีโอ มิได้ดำเนินธุรกรรมในการรับการฝากเงิน ธุรกรรมเงินกู้ หรือธุรกรรมอื่นใดเยี่ยงธนาคารพาณิชย์ หรือเพื่อแสวงหาผลประโยชน์แต่ประการใด</u>
                            <br/><br/>

 
                            ทั้งนี้ กลุ่มบริษัทดีทีจีโอ ขอสงวนสิทธิ์ว่า แผนได้เพิ่ม เป็นโครงการที่จัดขึ้นครั้งเดียวตามระยะเวลาที่กำหนดไว้ด้านล่างของข้อกำหนดและเงื่อนไขฉบับนี้ ไม่มีข้อผูกพันต่อเนื่องเป็นสวัสดิการหรือสภาพการจ้างให้แก่พนักงานของแต่ละบริษัทในกลุ่มบริษัทดีทีจีโอ   โดยแฟมผู้เข้าร่วมแผนได้เพิ่มได้รับทราบและตกลงจะปฏิบัติตามข้อกำหนดและเงื่อนไขดังต่อไปนี้<br/>
<br/>
                            <u>คำจำกัดความ</u><br/>
 <br/>
 การจ้างงาน หมายความถึง อยู่ภายใต้เงื่อนไขการจ้างของดีทีจีโอหรือกลุ่มบริษัทดีทีจีโอ ทั้งนี้หมายถึง การจ้างแรงงาน ทั้งพนักงานประจำ หรือพนักงานจ้างแบบมีกำหนดระยะเวลา (ตามแต่ที่ดีทีจีโอหรือกลุ่มบริษัทดีทีจีโอจะกำหนดต่อไป) การจ้างที่ปรึกษาหรือผู้เชี่ยวชาญ ให้บริการในฐานะปรึกษาของดีทีจีโอและหรือกลุ่มบริษัทดีทีจีโอ 
 กลุ่มบริษัทดีทีจีโอ ให้หมายความรวมถึงบริษัทที่เกี่ยวข้องและ/หรือ มีการถือหุ้นโดยดีทีจีโอ ทั้งนี้รายละเอียดให้เป็นไปตามที่ดีทีจีโอกำหนด 
 ข้อกำหนดและเงื่อนไข หมายความถึง ข้อกำหนดและเงื่อนไขของแผนได้เพิ่มนี้ และตามที่ได้แก้ไขเพิ่มเติม (ถ้ามี) 
     แฟม หมายความถึง พนักงาน ลูกจ้าง ที่ปรึกษา ของกลุ่มบริษัทดีทีจีโอ ตามที่กลุ่มบริษัทดีทีจีโอกำหนด  
 ฟิกซ์-ดี หรือ FYX-D หมายความถึง เงินอิเล็กทรอนิกส์ที่ถูกพัฒนาขึ้นเพื่อใช้ชำระค่าสินค้าและ/หรือบริการ ภายในดีทีจีโอในลักษณะระบบนิเวศในวงจำกัดแบบปิดเท่านั้น 
 ผู้เข้าร่วมแผน หมายถึง แฟมที่เข้าร่วมแผนได้เพิ่มผ่านทางระบบฟิกซ์-ดี 
 แผนได้ดี 1.0  หมายถึง โปรแกรมที่ดีทีจีโอกำหนดขึ้นและมอบให้แก่แฟมที่อยู่ภายใต้การจ้างงานและที่มีคุณสมบัติตามดีทีจีโอกำหนด โดยแผนได้ดี 1.0  จะครบกำหนดและสิ้นสุดแผนในวันที่ 31 ธันวาคม 2566 
 ดีทีจีโอ หมายถึง บริษัท ดีทีจีโอ คอร์ปอเรชั่น จำกัด  
<br/><br/> 
<u>ข้อกำหนดและเงื่อนไข</u><br/><br/>
1.	แผนได้เพิ่ม คือการนำฟิกซ์-ดี ตามจำนวนที่ต้องการ เข้าร่วมโครงการผ่านการดำเนินการทางระบบอิเล็กทรอนิกส์แบบปิดในวงจำกัด  ให้ดีทีจีโอทำการล็อกฟิกซ์-ดีจำนวนดังกล่าวไว้ ไม่นำออกมาใช้ และเมื่อครบกำหนดโครงการ ดีทีจีโอจะให้ผลตอบแทนเป็นรางวัลโดยเพิ่มมูลค่าฟิกซ์-ดีให้กับแฟม ทั้งนี้ วัตถุประสงค์ก็เพื่อส่งเสริมให้แฟมมีการเข้าร่วมใช้ฟิกซ์-ดี อันเป็นโครงการของดีทีจีโอและมีได้รับผลตอบแทนงอกเงยจากฟิกซ์-ดีนั้น  แฟมที่ประสงค์จะสมัครเป็นผู้เข้าร่วมแผนได้เพิ่มจะต้องมีสถานภาพการจ้างงาน และมีคุณสมบัติครบถ้วนตามที่ดีทีจีโอ กำหนด ก่อนหรือในวันสุดท้ายของวันประกาศรับสมัครแผนได้เพิ่ม<br/>  
&nbsp;&nbsp;&nbsp;&nbsp;1.1  แผนได้เพิ่ม จะมีระยะเวลาของโครงการประมาณ 1 (หนึ่ง) ปี เริ่มต้นตั้งแต่ วันที่ 20 มกราคม 2567 และสิ้นสุดลงในวันที่ 31 ธันวาคม 2567<br/> 
&nbsp;&nbsp;&nbsp;&nbsp;1.2  ผู้เข้าร่วมแผน แต่ละท่าน สามารถเข้าร่วมแผนได้เพิ่ม 1 (หนึ่ง) ครั้งเท่านั้น<br/><br/>

 
2. ขั้นตอนและวิธีการในการเข้าร่วมแผนได้เพิ่ม  
กำหนดขั้นต่ำของฟิกซ์-ดีที่จะเข้าร่วมแผนได้เพิ่มได้คือ 35,000 (สามหมื่นห้าพัน) ฟิกซ์-ดี ซึ่งสามารถดำเนินการได้ดังต่อไปนี้ 
 <br/>
 &nbsp;&nbsp;&nbsp;&nbsp;2.1	กรณีผู้เข้าร่วมแผน ประสงค์จะนำฟิกซ์-ดี ที่ได้มาจากแผนได้ดี 1.0 มาเข้าร่วมแผนได้เพิ่ม <br/>
 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ก)	ผู้เข้าร่วมแผนเลือกระบุจำนวนไม่ว่าทั้งหมดหรือบางส่วน ตกลงและยืนยันให้ดีทีจีโอนำฟิกซ์-ดีจำนวนดังกล่าวไปใช้เข้าร่วมแผนได้เพิ่ม  โดยดีจีโอตกลงจะหักฟิกซ์-ดีจำนวนดังกล่าวไปเข้าแผนได้เพิ่ม  ณ วันที่แผนได้เพิ่มจะเริ่มต้น<br/>
 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ข)	ฟิกซ์-ดีที่เข้าร่วมแผนได้เพิ่ม จะถูกนำไปรวมและแสดงผลในยอดจำนวนเข้าร่วมแผนได้เพิ่ม และไม่สามารถแลกเปลี่ยนหรือคืนไม่ว่าจะทั้งหมดหรือบางส่วนกลับไปยังกระเป๋าเงินฟิกซ์-ดี ไม่ว่ากรณีใดๆ เว้นแต่ดีจีทีโอจะกำหนดเป็นประการอื่น จนกว่าจะครบกำหนดระยะเวลาของแผน<br/>
 &nbsp;&nbsp;&nbsp;&nbsp;2.2	กรณีผู้เข้าร่วมแผน ประสงค์จะเติมเงิน (Top-Up) เพื่อนำจำนวนเงินที่เติมเข้ามาเป็นฟิกซ์-ดีนั้นเข้าร่วมแผนได้เพิ่ม<br/>
 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ก)	ผู้เข้าร่วมแผนจะต้องเติมเงินเข้าฟิกซ์-ดี เพื่อให้มีจำนวนคงเหลือตามจำนวนความประสงค์ที่ผู้เข้าร่วมแผนประสงค์จะเข้าร่วมแผน  จึงระบุจำนวนฟิกซ์-ดี ที่จะเข้าร่วมแผนได้เพิ่ม ทำการตกลงและยืนยันให้ฟิกซ์-ดีที่ระบุนั้นเข้าร่วมแผนได้เพิ่ม โดยดีจีโอตกลงจะหักฟิกซ์-ดีจำนวนดังกล่าวไปเข้าแผนได้เพิ่ม ตามกำหนดเวลาที่ตั้งไว้ ณ วันที่แผนได้เพิ่มจะเริ่มต้น<br/>
 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ข)	การเติมเงินนั้นจำกัดสูงสุดไม่เกิน 100,000 (หนึ่งแสน) บาท ต่อครั้ง<br/> 
 &nbsp;&nbsp;&nbsp;&nbsp;2.3	ดีทีจีโอจะนำจำนวนตามข้อ 2.1 และ 2.2 ซึ่งก็คือจำนวนฟิกซ์-ดีที่ผู้เข้าร่วมแผนแสดงเจตนาจะเข้าร่วมแผน มาดำเนินการตามแผนโครงการ โดยแยกแสดงจำนวนฟิกซ์-ดี ที่อยู่ในแผนและปิดกั้นการใช้จ่ายของฟิกซ์-ดี ทั้งนี้ ทางดีทีจีโอสงวนสิทธิจำกัดจำนวนฟิกซ์-ดี ที่จะเข้าร่วมแผนได้ไม่เกิน 1,000,000 (หนึ่งล้าน) บาท ต่อแฟม 1 (หนึ่ง) ท่าน <br/>
 &nbsp;&nbsp;&nbsp;&nbsp;2.4	ผู้เข้าร่วมแผนจะไม่สามารถใช้หรือแลกเปลี่ยนหรือคืนจำนวนฟิกซ์-ดีที่ดีเข้าร่วมแผนได้เพิ่มระหว่างวันที่ 20 มกราคม 2567 – 31 ธันวาคม 2567 เว้นแต่การมีเหตุการณ์สิ้นสุดของแผนได้เพิ่มเกิดขึ้น และเป็นไปตามที่ดีทีจีโอกำหนด  <br/>
 &nbsp;&nbsp;&nbsp;&nbsp;2.5	ผู้เข้าร่วมแผนและดีทีจีโอสามารถยืนยันตกลงเข้าร่วมแผนได้เพิ่ม โดยการยืนยันตอบรับข้อกำหนดและเงื่อนไขการเข้าร่วมแผนได้เพิ่มฉบับนี้ <br/><br/>
 
3.	เมื่อครบกำหนดเวลาของแผนได้เพิ่มตามข้อ 1.1 ผู้เข้าร่วมแผนที่จะได้รับรางวัลตอบแทนตามเงื่อนไขของแผนได้เพิ่ม ดังนี้    <br/> 
&nbsp;&nbsp;&nbsp;&nbsp;3.1	ผู้เข้าร่วมแผนจะได้รับสิทธิประโยชน์ตอบแทน เท่ากับร้อยละ 5 (ห้า) ตลอดระยะเวลาโครงการ โดยคำนวณจากยอดจำนวนฟิกซ์-ดี ที่เข้าร่วมแผนใน ข้อ 2.3 เป็นรายปี โดยดีทีจีโอตกลงจะทำจ่ายสิทธิประโยชน์ตอบแทนเพิ่มเติมนี้เข้าสู่กระเป๋าเงินฟิกซ์-ดี เป็นจำนวน 2 (สอง) ครั้งตลอดโครงการ  คือสิ้นเดือนมิถุนายนและสิ้นเดือนธันวาคม<br/>
&nbsp;&nbsp;&nbsp;&nbsp;3.2	ในกรณีที่ผู้เข้าร่วมแผน สิ้นสุดการจ้างงานกับดีทีจีโอหรือกลุ่มบริษัทดีทีจีโอในระหว่างกำหนดระยะเวลาในข้อ 1.1 นั้น ผู้เข้าร่วมแผนจะได้รับประโยชน์ตอบแทนเพิ่มเติมคิดคำนวณให้ถึงรอบกำหนดจ่ายสิทธิประโยชน์ที่ผ่านมาแล้วเท่านั้น (เช่น ในกรณีที่ลาออกจากการเป็นพนักงานในเดือนสิงหาคม ผู้เข้าร่วมแผนจะได้รับสิทธิประโยชน์คิดให้จนถึงรอบสิ้นเดือนมิถุนายนเท่านั้น) <br/><br/>
4.	เหตการณ์สิ้นสุดของแผนได้เพิ่ม  <br/>
&nbsp;&nbsp;&nbsp;&nbsp;4.1	เมื่อครบกำหนดระยะเวลาของแผนได้เพิ่ม <br/>
&nbsp;&nbsp;&nbsp;&nbsp;4.2	ผู้เข้าร่วมแผนสิ้นสุดการจ้างงาน   <br/>
&nbsp;&nbsp;&nbsp;&nbsp;4.3 เหตุอื่นๆ ใดที่อาจจะกำหนดเพิ่มเติมได้ โดยผู้เข้าร่วมแผนและดีจีทีโอตกลงร่วมกัน  <br/>
5.	ผลของการสิ้นสุดของแผนได้เพิ่ม  <br/>
&nbsp;&nbsp;&nbsp;&nbsp;5.1	เมื่อครบกำหนดตามข้อ 4.1 ดีทีจีโอตกลงจะส่งคืนจำนวนฟิกซ์-ดีตามข้อ 2.3 และมอบสิทธิประโยชน์ตามข้อ 3.1 ให้แก่ผู้เข้าร่วมแผน ตามวิธีการที่ดีทีจีโอกำหนด และผู้เข้าร่วมแผนตกลงรับคืนจำนวนดังกล่าว ทั้งนี้แฟมสามารถเปลี่ยนฟิกซ์-ดีที่นำมาเข้าร่วมโครงการตามข้อ 2.2 กลับเป็นเงินสดได้ แต่ส่วนสิทธิประโยชน์จากโครงการและจำนวนฟิกซ์-ดีตามข้อ 2.1 จะยังถูกเก็บไว้ในระบบกระเป๋าเงินฟิกซ์-ดี จนกว่าแฟมจะพ้นสภาพการจ้างงานจากกลุ่มบริษัทดีทีจีโอ  <br/>
&nbsp;&nbsp;&nbsp;&nbsp;5.2	 เมื่อสิ้นสุดตามข้อ 4.2  ดีทีจีโอตกลงจะส่งคืนจำนวนฟิกซ์-ดีตามข้อ 2.3 โดยคิดคำนวณตามข้อ 3.2 ให้แก่ผู้เข้าร่วมแผน ตามวิธีการที่ดีทีจีโอกำหนด และผู้เข้าร่วมแผนตกลงรับคืนจำนวนดังกล่าว <br/>
<br/>
ทั้งนี้ การคืนฟิกซ์-ดีเข้าสู่ระบบกระเป๋าเงินฟิกซ์-ดีนั้น จะใช้เวลาทำการประมาณ 14 (สิบสี่) วันจึงจะแสดงผลยอดปรับปรุงกลับไปยังกระเป๋าเงินฟิกซ์-ดี และเพื่อป้องกันกรณีเป็นที่สงสัย กำหนดระยะเวลาโครงการได้เพิ่มนั้นคือประมาณ 1 (หนึ่ง) ปีตามข้อ 1.1 และระยะเวลา 14 (สิบสี่) วัน นี้เป็นเพียงระยะเวลาดำเนินการปรับปรุงยอด จะไม่มีการคิดคำนวณสิทธิประโยชน์ให้ในกำหนดระยะเวลาดังกล่าว 
 <br/><br/>
6.	แฟมจะสามารถตรวจสอบแผนได้เพิ่ม และ/หรือสิทธิประโยชน์ ของตน ได้ผ่านช่องทาง Web application ของ ดีทีจีโอ หรือ ผ่านระบบอินทราเน็ตของ ดีทีจีโอ หรือช่องทางอื่นใดตามที่ ดีทีจีโอ จะกำหนดและแจ้งให้ผู้เข้าร่วมทราบในภายหลัง ทั้งนี้ แฟมสามารถศึกษาข้อมูลรายละเอียดของแผนได้เพิ่มด้วยตนเองได้ที่ลิงก์  <a href="https://m365tabfae124.z23.web.core.windows.net/index.html#/info">Link</a>
 <br/><br/>
7.	สิทธิและ/หรือสิทธิประโยชน์อื่นใดที่ได้รับจากการเข้าร่วมแผนได้เพิ่มเป็นสิทธิเฉพาะตัวของผู้เข้าร่วมแผนแต่ละท่าน จึงไม่สามารถโอนให้แก่ผู้เข้าร่วมแผนท่านอื่นหรือบุคคลภายนอกได้ ทั้งนี้ สิทธิและ/หรือผลประโยชน์อื่นใดที่ได้รับนั้นสามารถใช้งานภายใต้เงื่อนไขที่ ดีทีจีโอ กำหนด ซึ่งจะได้มีการกำหนดในภายหลัง 
<br/><br/>
8.	ภาระค่าใช้จ่าย ค่าธรรมเนียม ค่าภาษีใดๆ (ถ้ามี) ที่อาจจะเกิดขึ้นจากการได้รับประโยชน์ใดๆ จากแผนได้เพิ่มไม่ว่าทั้งหมดหรือบางส่วนใดๆ จะมีการหักภาษี ณ ที่จ่ายและคิดคำนวณเข้าเป็นส่วนหนึ่งของเงินได้พึงประเมินของแฟมที่ได้รับจากกลุ่มบริษัทดีทีจีโอตามกฎหมายกำหนด
<br/><br/>
9.	กฎหมายที่ใช้บังคับ ข้อกำหนดและเงื่อนไขนี้ให้ใช้บังคับและตีความตามกฎหมายไทยและให้ศาลไทยเป็นศาลที่มีเขตอำนาจในการพิจารณาข้อพิพาทที่เกิดขึ้นเกี่ยวกับการใช้บริการตามข้อกำหนดและเงื่อนไขนี้ 
<br/><br/>
10.	ข้อกำหนดและเงื่อนไขนี้ไม่ว่าจะแปลเป็นภาษาใดก็ตาม ไม่ได้มีเจตนาใด ๆ ที่จะเป็นการปรับเปลี่ยนหรือแก้ไขข้อกำหนดและเงื่อนไขนั้นแต่อย่างใด กรณีที่มีข้อความขัดแย้งกันระหว่างฉบับภาษาไทยและภาษาอื่น ๆ ที่ไม่ใช่ภาษาไทย ให้ยึดถือข้อความตามฉบับภาษาไทย
<br/><br/>
11.	กรณีมีข้อสงสัยหรือต้องการข้อมูลเพิ่มเติมเกี่ยวกับแผนได้เพิ่ม แฟมสามารถติดต่อสอบถามได้ที่ 
อีเมลหรือ Microsoft Team ที่  fyx-d_support@dtgo.com หรือช่องทางที่ดีทีจีโออาจกำหนดเพิ่มเติมในอนาคต
                            </p>
                        
                            <div style={{height: "25vh"}}>
                            </div>
                            <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-t border-ggrey-200">
                                <div className="bg-brown-200 py-3 px-4 rounded-2xl mb-2">
                                    <label className="inline-block">
                                        <span className="inline-block text-sm">
                                            <input
                                                type="checkbox"
                                                checked={isAcceptConsent}
                                                onChange={handleChange}
                                                className="align-top inline-block w-4 h-4 bg-gray-100 rounded focus:ring-yyellow-900 dark:focus:ring-yyellow-900 border-yyellow-900"
                                            />
                                            <span className="ml-2 align-top">
                                                I agree to the <u>Terms and Conditions</u>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer', !isAcceptConsent && 'bg-ggrey-200 text-neutral-500 disabled', isAcceptConsent && 'bg-yyellow-500  text-black')} onClick={handleAccept}>Accept</button> 
                            </div>
                        </div>
                      </div>
                </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
