import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import IconTopUp from '../images/icons/v3/topup-icon.svg';
import IconCancelPreorderBtn from '../images/icons/v3/cancel-preorder-btn.svg';
import LogoDColor from '../images/logos/d-logo-color.svg';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { showDigit } from '../utils/helpers';

// Sample app imports
import Error from './error-page';
import { useNavigate, useParams } from 'react-router-dom';
import usePlan from '../hooks/usePlan';

export default function ScanPayPage() {
    let { id } = useParams();
    const navigate = useNavigate();
    
    const goBack = () => {
        navigate('/');
    };

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [transaction, setTransaction] = useState(null);

    const inputRef = useRef(null);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const getTransaction = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/pay/transaction`, 
            { "transaction_id": id }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
            setTransaction(res.data.data.transaction)
            // setTotalFyxDBalance(res.data.data.member.fyxd + res.data.data.member.bonus_fyxd + res.data.data.member.pending_bonus_fyxd);

        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const confirm = async () => {
        navigate('/');
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0) {
            getTransaction();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "70vh"}}>
                      <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            {/* <span onClick={goBack} className="float-left font-semibold text-xl cursor-pointer">{'<'}</span> */}
                            <span className="text-center text-black font-semibold text-xl">Payment Successful</span>
                        </div>
                      </nav>
                      { transaction !== null && <>
                        <div>
                        <div className="mt-3 mb-2">
                            <div className="">
                            <span>Merchant</span>
                            <span className="float-right">{transaction.fyxdmerchant.name}</span>
                            </div>
                            <hr className="mt-2 mb-2" />
                            <div className="mb-2">
                            <span>Merchant ID</span>
                            <span className="float-right">{transaction.merchant_id}</span>
                            </div>
                            <hr className="mt-2 mb-2" />
                            <div className="mb-5">
                            <span className="font-bold">Payment Amount</span>
                            <span className="font-bold float-right">
                                <img className="inline-block w-5 h-5 mr-1" src={LogoDColor} />
                                {parseFloat(transaction.total_amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </span>
                            </div>
                            <div className="">
                                <span>Transaction ID</span>
                                <span className="float-right text-xs">{transaction.transaction_id}</span>
                            </div>
                            <hr className="mt-2 mb-2" />
                            <div>
                                <span>Transaction date</span>
                                <span className="float-right">{transaction.created_at.split("T")[0]}</span>
                            </div>
                            <hr className="mt-2 mb-2" />
                            <div>
                                <span>Transaction time</span>
                                <span className="float-right">{transaction.created_at.split("T")[1].replace(".000Z", "")}</span>
                            </div>
                        </div>
                        <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-ggrey-200">
                            <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer bg-yyellow-500  text-black')} onClick={confirm}>Back to Home</button> 
                        </div>
                        </div>
                      </> 
}
                </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}