import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import LogoFyxd from '../images/logos/fyxd-logo-color.svg';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import Modal from 'react-modal';

// Sample app imports
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import usePlan from '../hooks/usePlan';

export default function PlanConfirmPage() {
    const navigate = useNavigate();
    const { FyxD, Returns } = usePlan();

    const goBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
  
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [fullName, setFullName] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isAcceptConsent, setIsAcceptConsent] = useState(true);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const getUserProfile = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember/v1/fyxd/member/dashboard`, 
            { "upn": Email }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
            // console.log(res.data);
            setFullName(res.data.data.member.first_name + ' ' + res.data.data.member.last_name);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const openModal = async () => {
        setIsOpen(true);
    };

    const closeModal = async () => {
        setIsOpen(false);
    };

    const handleOpenConfirmModal = async () => {
        openModal();
    };

    const handleOpenCongrat = () => {
        navigate('/planotp');
    };

    const cofirmAcceptAgreement = (event) => {
        event.preventDefault();

        if(isButtonEnabled && isAcceptConsent)
            handleOpenCongrat();
    };

    const handleCheckboxChange = (event) => {
        setIsAcceptConsent(event.target.checked);
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0) {
            getUserProfile();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          minHeight: '20vh',
          minWidth: '40vh'
        },
    };

    const contentRef = useRef(null);

    const handleScroll = () => {
        const element = contentRef.current;
        if (element) {
          // Adding a small threshold to ensure compatibility across devices
          const threshold = 10;
          const atBottom = element.scrollHeight - element.scrollTop - element.clientHeight < threshold;
          setIsButtonEnabled(atBottom);
        }
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw"}} className="bg-brown-50 h-full">
                <nav className="w-full bg-white">
                    <div className="text-center py-3 border-b border-ggrey-200">
                            <span onClick={goBack} className="float-left font-semibold text-xl ml-3 cursor-pointer">{'<'}</span>
                        <span className="text-center text-black font-semibold text-xl">Confirm Your Order</span>
                    </div>
                </nav>
                <Container className="h-fit">
                    <div className="mt-3 bg-white p-4 rounded-xl h-fit">
                        <div>
                            <span>Bonus Plan</span>
                            <img src={LogoFyxd} width={60} className="inline-block float-right" />
                            <br/>
                            <span className="text-2xl font-black text-yyellow-900">Dai Perm</span>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <span className="xs:text-xs sm:text-xs text-xs font-bold">
                                    Buy Amount
                                </span>
                            </div>
                            <div>
                                <span className="xs:text-xs sm:text-xs text-xs font-bold">
                                    Guaranteed Bonus*
                                </span><br/>
                                {/* <span className="xs:text-xs sm:text-xs text-xs text-ggrey-700">
                                    (After 1 Jan 2025)
                                </span> */}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="mt-2">
                                <div className="inline-block float-left">
                                    <span className="font-black text-2xl text-yyellow-500">{FyxD.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                    <span className="text-sm">FYX-D</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div className="inline-block float-left">
                                    <span className="font-black text-2xl text-yyellow-500">{Returns.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                    <span className="text-sm">FYX-D</span>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        
                        <hr className="mt-3 border-brown-500 border-2"/>
                        <div className="mt-3 grid grid-cols-2 gap-2">
                            <div>
                                <span className="font-semibold text-xs">Order Type</span>
                            </div>
                            <div>
                                <span className="text-xs float-right">FYX-D Platform Order</span>
                            </div>
                        </div>
                        <hr className="mt-3 border-brown-500 border-2"/>
                        <div className="mt-3 grid grid-cols-2 gap-2">
                            <div>
                                <span className="font-semibold text-xs">Payment account</span>
                            </div>
                            <div className="">
                                <span className="text-xs float-right">FYX-D Account</span><br/><br/>
                                <span className="text-xs float-right">K. {fullName}</span><br/>
                                <span className="text-xs float-right">{Email}</span>
                            </div>
                        </div>
                        <hr className="mt-3 border-brown-500 border-2"/>
                        <div className="mt-3 grid grid-cols-2 gap-2">
                            <div>
                                <span className="font-semibold text-xs">Purchase Amount</span><br/>
                                <span className="mt-1 font-semibold text-xs">Fixed interest</span><br/>
                                <span className="mt-1 font-semibold text-xs">Exercise Amount</span><br/>
                                <span className="text-xxs">(Amount after interest paid)</span>
                            </div>
                            <div className="">
                                <span className="text-xs float-right">{FyxD.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} FYX-D</span><br/>
                                <span className="text-xs float-right">5%</span><br/>
                                <span className="text-xs float-right">{Returns.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} FYX-D</span>
                            </div>
                        </div>

                        <hr className="mt-3 border-brown-500 border-2"/>
                        <div className="mt-3 grid grid-cols-2 gap-2">
                            <div>
                                <span className="font-semibold text-xs">Trade Date</span><br/>
                                <span className="mt-1 font-semibold text-xs">Exercise Date</span><br/>
                                <span className="mt-1 font-semibold text-xs">Amount Release Date</span>
                            </div>
                            <div className="mt-1">
                                <span className="text-xs float-right"><small>20 Jan 2024 00:01:00</small></span><br/>
                                <span className="text-xs float-right"><small>31 Dec 2024 23:59:00</small></span><br/>
                                <span className="text-xs float-right"><small>31 Jan 2025 00:01:00</small></span>
                            </div>
                        </div>

                        <hr className="mt-3 mb-3 border-brown-500 border-2"/>
                        <span className="mt-3 text-xs text-ggrey-300">
                            Remark: Before you proceed with your order, please take a moment to review your choices and ensure all details are accurate. <br/>Thank you
                        </span>
                        
                        <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-t border-ggrey-200">
                            <span className="float-left font-bold text-xs">
                                Total Cost
                            </span>
                            <span className="float-right text-xs">
                                {FyxD.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} FYX-D
                            </span><br/>
                            <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl bg-yyellow-500  text-black')} onClick={handleOpenCongrat}>Confirm</button> 
                        </div>
                    </div>
                    <div style={{height: "15vh"}}>
                            </div>
                </Container>
                <Modal
                  isOpen={isOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                >
                  <div>
                      <h1 style={{textAlign : "center"}}><b>บันทึกการเข้าร่วมโครงการ/กิจกรรม</b></h1>
                        <div className="mt-2 overflow-y-auto h-64"  
                            onScroll={handleScroll}
                            ref={contentRef}>
                            {/* Long text content here */}
                            <p className="text-xs">
                            บันทึกการเข้าร่วมโครงการ/กิจกรรม (“บันทึก”) ฉบับนี้ เมื่อท่าน โดยการกด “ยืนยัน” หรือ “ยอมรับ” หรือ “ตกลง” หรือข้อความอื่นใดที่คล้ายกันต่อท้ายบันทึกฉบับนี้ หรือการสมัครเข้าร่วมโครงการได้เพิ่มผ่านการเข้าใช้งานกระเป๋าเงินฟิกซ์-ดี ถือเป็นการเข้าทำบันทึกฉบับนี้โดยวิธีการทางอิเล็กทรอนิกส์ที่สมบูรณ์ และก่อให้เกิดผลผูกพันทางกฎหมายและสามารถบังคับได้ระหว่างคู่สัญญา นับแต่วันที่เข้าทำบันทึกฉบับนี้เป็นต้นไป
                            <br/><br/>
                            ข้อกำหนดและเงื่อนไขการเข้าร่วมโครงการ/กิจกรรม (“ข้อกำหนดและเงื่อนไขนี้”) มีดังต่อไปนี้
                            <br/><br/>
                            1.	คำนิยาม
	“กลุ่มบริษัทดีทีจีโอ” หมายถึง บริษัท ดีทีจีโอ คอร์ปอเรชั่น จำกัด (“บริษัท”) รวมถึงบริษัทในเครือของดีจีทีโอซึ่งดีทีจีโอเป็นผู้ถือหุ้นในบริษัทดังกล่าวไม่จะทั้งทางตรงหรือทางอ้อม
	“กระเป๋าเงินฟิกซ์-ดี (FYX-D wallet)” หมายถึง บัญชีเงินอิเล็กทรอนิกส์สำหรับการใช้งานฟิกซ์-ดี
	“ได้เพิ่ม” หมายถึง เป็นโครงการส่งเสริมและตอบแทนสิทธิประโยชน์ให้แก่พนักงานตลอดจนบุคลากรของดีทีจีโอและกลุ่มบริษัทในเครือ ที่ได้เข้าร่วมและใช้งานฟิกซ์-ดี
	“แฟม” หมายถึง พนักงาน ลูกจ้าง ที่ปรึกษา ของกลุ่มบริษัทดีทีจีโอ ตามที่บริษัทจะกำหนด ซึ่งแสดงเจตนาและความประสงค์ในการเข้าร่วมโครงการ/กิจกรรม
	“ฟิกซ์-ดี (FYX-D)” หมายถึง เงินอิเล็กทรอนิกส์ของบริษัทซึ่งมีรูปแบบการใช้งานในวงจำกัดแบบปิดเฉพาะในกลุ่มบริษัทดีทีจีโอภายใต้ระบบนิเวศน์และเงื่อนไขที่กำหนดเท่านั้น
<br/><br/>
2.	โครงการ/กิจกรรมนี้มีวัตถุประสงค์ในการจัดทำขึ้นเพื่อส่งเสริมการใช้งานและเพิ่มพูนผลประโยชน์ของฟิกซ์-ดี ให้แก่ผู้เข้าร่วมภายใต้ข้อกำหนดและเงื่อนไขนี้ และเป็นโครงการที่จัดขึ้นครั้งเดียวภายในระยะเวลาที่กำหนดไว้ โดยไม่มีลักษณะเป็นข้อผูกพันต่อเนื่องเป็นสวัสดิการหรือเป็นสาระสำคัญต่อสภาพการจ้างให้แก่แฟมแต่อย่างใด
<br/><br/>
3.	คุณสมบัติของผู้เข้าร่วม<br/>
	(1)	เป็นผู้บรรลุนิติภาวะและเป็นบุคคลที่มีความสามารถตามกฎหมาย<br/>
	(2)	มีสถานภาพเป็นแฟมหรือมีคุณสมบัติตามที่บริษัทกำหนด<br/>
<br/><br/>
4.	โครงการ/กิจกรรมนี้มีรายละเอียดดังต่อไปนี้<br/>
	(1)	มีกำหนดระยะเวลา 1 ปี เริ่มตั้งแต่วันที่ 1 มกราคม 2567 สิ้นสุดวันที่ 31 ธันวาคม 2567<br/>
	(2)	ผู้เข้าร่วมโครงการ/กิจกรรมต้องมีคุณสมบัติตามที่บริษัทกำหนด<br/>
(3)	ผู้เข้าร่วมโครงการ/กิจกรรมต้องแสดงความจำนงหรือความประสงค์ในการเข้าร่วมผ่านระบบและ/หรือรูปแบบตามวิธีการที่บริษัทกำหนด จำกัดจำนวนฟิกซ์-ดีที่สามารถเข้าร่วมโครงการ/กิจกรรมไม่เกิน หนึ่งล้าน (1,000,000) ฟิกซ์-ดี ต่อ 1 ท่าน และยอดรวมฟิกซ์-ดี ตลอดโครงการ/กิจกรรมจำนวนไม่เกิน ห้าสิบ (50,000,000) ฟิกซ์-ดี โดยผู้แสดงความจำนงเข้าร่วมก่อนมีสิทธิ์ก่อนตามลำดับการบันทึกในระบบ<br/> 
(4)	อัตราผลตอบแทนที่จะได้รับจากการเข้าร่วมที่ ห้า (5) % ต่อปี ตลอดระยะเวลาโครงการ/กิจกรรม โดยมีช่วงเวลาปลอดภาระในการดำเนินการคืนฟิกซ์-ดีพร้อมผลตอบแทนเป็นเวลา สิบสี่ (14) วัน นับแต่วันที่สิ้นสุดโครงการ<br/>
(5)	สามารถได้รับผลตอบแทนแบ่งเป็น 2 ช่วง คือ<br/>
	ช่วงที่ 1:	ณ วันที่ 1 กรกฎาคม 2567<br/>
	ช่วงที่ 2:	ณ วันที่ 31 ธันวาคม 2567<br/>
(6)	กำหนดเวลาได้รับคืน ฟิกซ์-ดี ไม่ว่าจะเนื่องจากการสิ้นสุดระยะเวลาโครงการ/กิจกรรม หรือ โครงการ/กิจกรรมยุติลงไม่ว่าด้วยเหตุผลใด คือ ภายใน หกสิบ (60) วัน นับจากวันที่สิ้นสุดหรือวันที่ยุติแล้วแต่กรณี<br/>
<br/><br/>
5.	การรับและส่งมอบฟิกซ์-ดี<br/>
(1)	เมื่อเริ่มโครงการ/กิจกรรม ระบบจะหัก ฟิกซ์-ดี ของผู้เข้าร่วมโครงการ/กิจกรรมออกจากกระเป๋าเงินฟิกซ์-ดี ของผู้เข้าร่วมตามจำนวนที่ผู้เข้าร่วมโครงการ/กิจกรรมแสดงความประสงค์ไว้ในวันที่เริ่มต้นของโครงการ/กิจกรรม<br/>
(2)	เมื่อสิ้นสุดโครงการ/กิจกรรมและ/หรือโครงการ/กิจกรรมยุติลง ผู้เข้าร่วมโครงการ/กิจกรรมจะได้รับฟิกซ์-ดี คืนเป็นจำนวนเทียบเท่ากับจำนวนที่ใช้เข้าร่วมโครงการ/กิจกรรมพร้อมผลตอบแทนเป็น ฟิกซ์-ดี ตามจำนวนที่กำหนดเข้าในกระเป๋าเงินฟิกซ์-ดี ของผู้เข้าร่วมภายในระยะเวลาที่บริษัทกำหนด<br/>
<br/><br/>
6.	ภาษี<br/>
	เจ้าของโครงการตกลงเป็นผู้รับผิดชอบภาระในการชำระค่าภาษีเงินได้ ค่าภาษีหัก ณ ที่จ่าย และค่าอากรแสตมป์ที่เกิดขึ้นจากโครงการ/กิจกรรมนี้ตามอัตราที่กฎหมายกำหนด (ถ้ามี)<br/>
<br/><br/>
7.	คำรับรองของผู้เข้าร่วม<br/>
	(1)	ผู้เข้าร่วมรับทราบและตกลงว่า ผู้เข้าร่วมมีคุณสมบัติครบถ้วนสมบูรณ์ก่อนและขณะการเข้าร่วมโครงการ/กิจกรรมนี้<br/>
(2)	ผู้เข้าร่วมรับทราบและตกลงว่า โครงการ/กิจกรรมนี้เป็นไปเพื่อประโยชน์เฉพาะตัวของแฟมเท่านั้นและจะไม่ใช้หรือดำเนินการกิจกรรมใดๆ ในเชิงพาณิชย์หรือหาประโยชน์ในเชิงธุรกิจจากการเข้าร่วมโครงการ/กิจกรรมนี้ทุกกรณี<br/>
(3)	ผู้เข้าร่วมรับทราบและตกลงว่า ตนจะได้รับเพียงผลตอบแทนตามอัตราและจำนวนที่บริษัทกำหนดภายใต้ข้อกำหนดและเงื่อนไขนี้ โดยตนจะไม่เรียกร้องเบี้ยปรับ ค่าธรรมเนียม ค่าใช้จ่ายหรือผลประโยชน์อื่นใดนอกเหนือจากผลตอบแทนที่ตนพึงจะได้รับตามที่บริษัทกำหนดเท่านั้น กรณีที่ผู้เข้าร่วมยุติการเข้าร่วมโครงการ/กิจกรรมก่อนสิ้นสุดระยะเวลาของโครงการ/กิจกรรม ผู้เข้าร่วมรับทราบและตกลงว่า ตนจะไม่ได้รับผลตอบแทนตามที่บริษัทกำหนดไว้<br/>
บริษัทขอสงวนสิทธิ์ในการพิจารณาด้วยดุลยพินิจของบริษัทในการยกเลิกหรือยุติการเข้าร่วมโครงการ/กิจกรรมของผู้เข้าร่วมได้ทันทีหากพบว่าผู้เข้าร่วมผิดหรือไม่ปฏิบัติตามคำรับรองตามความในข้อนี้โดยให้ถือคำตัดสินของบริษัทเป็นที่สุดและสงวนสิทธิ์ในการดำเนินการทางกฎหมายกรณีเกิดความเสียหายใดๆ ต่อบริษัท (ถ้ามี)<br/>
<br/><br/>
8.	การสิ้นสุดของโครงการ/กิจกรรม<br/>
	(1)	สิ้นสุดระยะเวลาของโครงการ/กิจกรรมตามที่กำหนดไว้ในข้อ 4 (1)<br/>
	(2)	โครงการ/กิจกรรมนี้อาจจะยุติลงได้เมื่อเกิดเหตุดังต่อไปนี้<br/>
(ก)	ผู้เข้าร่วมสิ้นสุดสถานภาพการเป็นแฟม<br/>
(ข)	ผู้เข้าร่วมผิดคำรับรองตามที่ระบุไว้ในข้อ 6 หรือตามที่บริษัทกำหนดไว้ทั้งในปัจจุบันและ/หรือที่จะได้กำหนดในภายหน้า<br/>
(ค)	ผู้เข้าร่วมถึงแก่ความตายหรือบุคคลไร้ความสามารถตามกฎหมาย<br/>
(ง)	โครงการ/กิจกรรมนี้ยุติลงตามคำสั่งหรือผลของกฎหมาย<br/>
<br/><br/>
9.	ผลการสิ้นสุดของโครงการ/กิจกรรม<br/>
(1)	กรณีสิ้นสุดโครงการ/กิจกรรมตามความในข้อ 8 (1) ผู้เข้าร่วมโครงการจะได้รับคืนพร้อมผลตอบแทนเป็นฟิกซ์-ดี ตามจำนวนและวิธีการตามที่ระบุไว้ในข้อกำหนดและเงื่อนไขนี้ของบริษัท<br/>
(2)	กรณีโครงการ/กิจกรรมยุติลงตามความในข้อ 8 (2) (ก) หรือ (ข) หรือ (ค) หรือ (ง) ผู้เข้าร่วมโครงการจะได้รับคืนเฉพาะฟิกซ์-ดี ตามจำนวนที่ใช้เข้าโครงการ/กิจกรรม แต่จะไม่ได้รับผลตอบแทนอื่นใดนอกเหนือจากเงื่อนไขที่กำหนดไว้นับแต่วันที่ล่วงเลยช่วงกำหนดเวลาที่จะได้รับค่าตอบแทนก่อนเกิดการยุติโครงการ/กิจกรรมจากเหตุดังกล่าวนั้น<br/>
(3)	กรณีโครงการ/กิจกรรมยุติลงตามความในข้อ 8 (2) (ค) ทายาทโดยธรรมหรือผู้จัดการมรดกหรือผู้ปกครองตามกฎหมายของบุคคลดังกล่าวสามารถดำเนินการขอรับคืน ฟิกซ์-ดี ตามสิทธิเดิมที่บุคคลดังกล่าวมีได้โดยจะต้องแสดงพร้อมส่งมอบหลักฐานที่เกี่ยวข้องให้แก่บริษัทและตกลงจะรับผิดชอบค่าธรรมเนียมของธนาคารที่เกิดขึ้นจากการดำเนินธุรกรรมดังกล่าว (ถ้ามี)<br/>
<br/><br/>
10.	ข้อกำหนดอื่น<br/>
(1)	บันทึกและเอกสารแนบท้ายบันทึกการเข้าร่วมโครงการ/กิจกรรมนี้สามารถแปลเป็นภาษาอื่นได้โดยไม่ใช่การเปลี่ยนแปลงและแก้ไขเนื้อหาของบันทึกและเอกสารแนบท้ายบันทึกการเข้าร่วมโครงการ/กิจกรรมนี้แต่อย่างใด กรณีมีข้อความขัดแย้งระหว่างภาษาไทยกับภาษาอื่น ให้ยึดถือภาษาไทยเป็นหลัก<br/>
(2)	กรณีมีข้อกำหนดและเงื่อนไขหรือข้อความใดๆ ในบันทึกและเอกสารแนบท้ายบันทึกการเข้าร่วมโครงการ/กิจกรรมนี้ไม่สมบูรณ์ หรือไม่มีผลบังคับใช้ตามกฎหมาย ให้ข้อกำหนดและเงื่อนไขหรือข้อความส่วนที่เหลือซึ่งยังสมบูรณ์แยกออกจากส่วนที่ไม่สมบูรณ์หรือไม่มีผลบังคับใช้ตามกฎหมายนั้น และให้ยังคงมีผลใช้บังคับได้ต่อไป<br/>
(3)	บริษัทขอสงวนสิทธิ์ในการเพิ่ม เปลี่ยนแปลงข้อกำหนดและเงื่อนไขนี้ได้ตามแต่จะเห็นสมควรและผู้เข้าร่วมตกลงผูกพันปฏิบัติตามข้อกำหนดและเงื่อนไขที่เพิ่มหรือเปลี่ยนแปลงดังกล่าวทุกประการโดยบริษัทจะแจ้งให้ผู้เข้าร่วมทราบล่วงหน้าโดยการปิดประกาศ ณ สำนักงานของบริษัท และ/หรือมีหนังสือแจ้งไปยังผู้เข้าร่วมและ/หรือวิธีการอื่นใดตามที่บริษัทเห็นสมควร เว้นแต่การเพิ่มหรือแก้ไขเปลี่ยนแปลงที่ส่งผลให้ผู้เข้าร่วมเกิดภาระหรือความเสี่ยงเพิ่มเติมตามหลักเกณฑ์ที่มีกฎหมายที่เกี่ยวข้องกำหนดไว้ การเปลี่ยนแปลงดังกล่าวนั้นจะมีผลผูกพันเมื่อผู้เข้าร่วมให้ความยินยอม<br/>
(4)	บันทึกและเอกสารแนบท้ายบันทึกการเข้าร่วมโครงการ/กิจกรรมนี้อยู่ภายใต้บังคับและการตีความตามกฎหมายของแห่งราชอาณาจักรไทย<br/>
(5)	บันทึกและเอกสารแนบท้ายบันทึกการเข้าร่วมโครงการ/กิจกรรมนี้อาจมีการทำขึ้นเป็นคู่ฉบับ แต่ละฉบับให้ถือเสมือนเป็นเอกสารเดียวกันกับต้นฉบับ ทั้งสองฝ่ายยอมรับและตกลงว่า เอกสารที่มีการลงนามไม่ว่าจะด้วยวิธีทางอิเล็กทรอนิกส์หรือวิธีการใดซึ่งได้มีการส่งมอบให้แก่อีกฝ่ายไม่ว่าจะอยู่ในรูปแบบไฟล์อิเล็กทรอนิกส์หรือในรูปแบบใดทั้งที่เป็นที่ยอมรับอยู่ในปัจจุบันหรือที่อาจมีขึ้นในอนาคตถือเสมือนว่าเป็นการส่งต้นฉบับให้แก่อีกฝ่ายแล้ว และให้มีผลผูกพันตามกฎหมายเช่นเดียวกับเอกสารต้นฉบับและทั้งสองฝ่ายยอมให้ใช้อ้างอิงเป็นพยานหลักฐานยันตนได้ทุกประการ<br/>
<br/><br/>
11. การคุ้มครองข้อมูลส่วนบุคคล<br/>
บริษัทได้จัดให้มีข้อกำหนดด้านนโยบายความเป็นส่วนตัว (Privacy Policy) รวมถึงข้อกำหนดที่อาจมีการแก้ไขรายละเอียดเพิ่มเติมในอนาคต ผู้เข้าร่วมตกลงจะศึกษา ทำความเข้าใจ รายละเอียดต่างๆ ของข้อกำหนดดังกล่าวก่อนการเข้าร่วมโครงการ/กิจกรรมนี้ ทั้งนี้ ผู้เข้าร่วมสามารถเข้าถึง ศึกษา ตรวจสอบ และ จัดพิมพ์ ข้อกำหนดด้านนโยบายความเป็นส่วนตัวดังกล่าวได้ที่ https://www.dtgo.com/th/privacy โดยที่ผู้เข้าร่วมรับทราบว่า การเก็บรวบรวม ใช้ เปิดเผยหรือโอนข้อมูลส่วนบุคคลของผู้เข้าร่วมจะเป็นไปตามนโยบายตามเป็นส่วนตัวดังกล่าวนี้ซึ่งให้ถือเป็นส่วนหนึ่งของข้อกำหนดและเงื่อนไขนี้<br/>
</p>
                        </div>
                        <div className="bg-brown-200 py-1 px-2 rounded-2xl mb-2 flex items-start">
                            <label className="inline-block ml-2">
                                <span className="inline-block text-sm text-center">
                                    <input
                                        type="checkbox"
                                        checked={isAcceptConsent}
                                        onChange={handleCheckboxChange}
                                        className="inline-block w-4 h-4 bg-gray-100 rounded focus:ring-yyellow-900 dark:focus:ring-yyellow-900 border-yyellow-900"
                                        disabled={!isButtonEnabled}
                                    />
                                    <span className="ml-1">
                                        I accept memorandum of participation 
                                    </span>
                                </span>
                            </label>
                        </div>
                      <button className={clsx('mt-3 px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer', (!isAcceptConsent || !isButtonEnabled) && 'bg-ggrey-200 text-neutral-500 disabled', (isAcceptConsent && isButtonEnabled) && 'bg-yyellow-500  text-black')} onClick={cofirmAcceptAgreement}  disabled={!isButtonEnabled && !isAcceptConsent}>Confirm</button>
                  </div>
                </Modal>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
