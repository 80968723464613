import { useState, useEffect } from 'react';

const usePlan = () => {
  const [FyxD, setFyxD] = useState(0);
  const [Returns, setReturns] = useState(0);

  const saveFyxD = (newFyxD) => {
    localStorage.setItem('FyxD', newFyxD);
  };

  const saveReturns = (newReturns) => {
    localStorage.setItem('Returns', newReturns);
  };

  useEffect(() => {
    const storedFyxD = localStorage.getItem('FyxD');
    const storedReturns = localStorage.getItem('Returns');

    if (storedFyxD) {
      setFyxD(parseFloat(storedFyxD));
    }

    if (storedReturns) {
      setReturns(parseFloat(storedReturns));
    }
  }, []);

  return {
    FyxD,
    saveFyxD,
    Returns,
    saveReturns
  };
}

export default usePlan;