export const showDigit = (data) => {
    let result = data.toString().split(".")[1];

    if(result === undefined || result.length === 0)
      result = '00';

    if(result.length === 1)
      result = result + '0';

    return result;
}

export const subscribePlanStatus = (planStatus) => {
  if(planStatus === 6) {
    return 'Pre-ordered';
  } else if(planStatus === 2) {
    return 'Cancelled';
  } else if(planStatus === 1) {
    return 'Completed';
  }
};