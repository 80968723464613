import React from "react";

const ProgressCardButton = ({ title, progress, total, isRedeemed }) => {
  const progressPercentage = (progress / total) * 100;

  return (
    <div className="bg-[#FFF0D7] flex flex-row justify-between item-center  rounded-b-2xl">
      <div className="flex flex-col space-y-2 mx-2 py-3">
        <div className="flex flex-row justify-between">
          <h2 className="text-base font-bold">{title}</h2>
          <span className="text-sm text-gray-500 ">{`${progress}/${total}`}</span>
        </div>
        <div className="relative  w-52 md:w-52 lg:w-52 h-4 bg-white rounded-full ">
          <div
            className="absolute top-0 left-0 h-4 bg-[#FFC657] rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
      </div>
      <button className="mt-3 mb-3 mr-2  xxs:px-1 view-mission_button inline-block bg-[#FFC657] float-right">
        <div className="flex-initial">
          <div className="inline-block">
            <span className="ml-1 text-xs">View mission</span>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ProgressCardButton;
