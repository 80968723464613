import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import LogoFyxd from '../images/logos/fyxd-logo-color.svg';
import IconDaideeInterest from '../images/icons/v3/daidee-interest-icon.svg';
import IconExercisePlan from '../images/icons/v3/exercise-plan-icon.svg';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

// Sample app imports
import Error from './error-page';
import { useNavigate, useParams } from 'react-router-dom';

export default function PlanDetailPage() {
    let { id } = useParams();
    const navigate = useNavigate();

    const goBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
  
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [recieveAmount, setreceieveAmount] = useState(0);
    const [guaranteedReceivableAmount, setguaranteedReceivableAmount] = useState(0);
    const [bonusAmount, setbonusAmount] = useState(0);
    const [planName, setplanName] = useState('');
    const [exerciseDate, setplanExerciseDate] = useState(new Date());
    const [bonusCycle, setbonusCycle] = useState([]);
    const [exercise, setexercise] = useState([]);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const getSubscribedPlan = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/portfolios/detail`, { "plan_subscribe_id" : id }, {
                headers: {
                  Authorization: 'Bearer ' + accessToken
                }
              });
            // console.log(res.data);
            const { data } = res.data;
            setreceieveAmount(parseFloat(data.plan_subscribe.total_amount));
            setguaranteedReceivableAmount(parseFloat(data.guranteed_returns));
            const _bonus = parseFloat(data.guranteed_returns) - parseFloat(data.plan_subscribe.total_amount);
            setbonusAmount(_bonus);
            setplanName(data.plan.title);
            setplanExerciseDate(data.plan.exercised_date);
            setbonusCycle(data.plan_bonus_cycle);
            setexercise(data.plan_exerise);
            // console.log(data.plan_bonus_cycle)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0 && id.length > 0) {
            getSubscribedPlan();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated, id]);

    const authRequest = {
        ...loginRequest
    };

    const formatDate = (date) => {
        let day = date.getDate().toString().padStart(2, '0');
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        let year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
    };

    // Function to format the text
    const formatText = (text) => {
        const regex = /(\d+)(st|nd|rd|th)/g;
        const parts = text.split(regex);
    
        return parts.map((part, index) => {
        if (index % 3 === 1) {
            // Number part
            return <React.Fragment key={index}>{part}</React.Fragment>;
        } else if (index % 3 === 2) {
            // Superscript part
            return <sup key={index}>{part}</sup>;
        } else {
            // Other parts
            return <React.Fragment key={index}>{part}</React.Fragment>;
        }
        });
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div className="bg-brown-50 min-h-screen h-full w-full">
                <nav className="w-full bg-white">
                    <div className="text-center py-3 border-b border-ggrey-200">
                            <span onClick={goBack} className="float-left font-semibold text-xl ml-3 cursor-pointer">{'<'}</span>
                        <span className="text-center text-black font-semibold text-xl">{planName}</span>
                    </div>
                </nav>
                <Container className="h-fit">
                    <div className="mt-3 bg-white p-4 rounded-xl h-fit">
                        <div>
                            <span className="text-gray-400">Bonus Plan</span>
                            <img src={LogoFyxd} width={60} className="inline-block float-right" />
                            <br/>
                            <span className="text-md font-black text-black">{planName}</span>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <span className="xs:text-xxs text-xs font-bold">
                                    Receive Amount
                                </span>
                            </div>
                            <div>
                                <span className="xs:text-xxs text-xs font-bold">
                                    Guaranteed Bonus*
                                </span><br/>
                                {/* <span className="xs:text-xxs text-xs text-ggrey-700">
                                    (After {new Date(exerciseDate).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }) })
                                </span> */}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="mt-2">
                                <div className="inline-block float-left">
                                    <span className="font-black xs:text-xl text-2xl text-yyellow-500">{recieveAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                    <span className="text-sm">FYX-D</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <div className="inline-block float-left">
                                    <span className="font-black xs:text-xl text-2xl text-yyellow-500">{bonusAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                    <span className="text-sm">FYX-D</span>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div>
                            <span className="mt-1 text-xs text-gold-900 font-bold">
                                * final receive amount subject to terms and conditions and tax deduction
                            </span>
                        </div>
                    </div>
                    <div className="mt-3 bg-white p-4 rounded-xl h-fit">
                        <div>
                            <span className="text-black font-black">Bonus Cycle</span>
                        </div>
                        <div className="mt-1">
                            <span className="text-xs">
                                <b>Remark:</b> All store up value will be convert to FYX-D Bonus at the end of exercise date.
                            </span>
                        </div>
                        <div className="mt-2 mb-2">
                            <hr/>
                            { bonusCycle.map((item, index) => (
                                <>
                                    <div key={index} className="mt-3 mb-3">
                                        <div>
                                            <img className="inline-block align-top" src={IconDaideeInterest} />
                                            <div className="inline-block ml-2 w-3/4">
                                                <span className="text-sm">{planName}: {formatText(item.plan_detail)}</span><br/>
                                                <div>
                                                    <span className="inline-block text-sm text-green-500">+{item.bonus.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} FYX-D</span>
                                                    <div className="inline-block float-right">
                                                        <span className="text-xs font-bold">on {formatDate(new Date(item.created_at))}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                </>) )}

                          <div className="mt-3 mb-3">
                                <div>
                                    <span className="text-sm">
                                        <b>Exercise:</b> Convert {planName} {`>`} FYX-D Bonus
                                    </span>
                                    { exercise.map((item, index) => (
                                    <>
                                        <div className="mt-2 mb-2">
                                            <img className="inline-block align-top" src={IconExercisePlan} />
                                            <div className="inline-block ml-2 w-3/4">
                                                <span className="text-sm">{planName} Conversion: {item.type}</span><br/>
                                                <div>
                                                    <span className="inline-block text-sm text-green-500">+{item.total_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2})} FYX-D</span>
                                                    <div className="inline-block float-right">
                                                        <span className="text-xs font-bold">on {formatDate(new Date(item.created_at))}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </>) )}
                                    {
                                        exercise.length === 0 && <div className="flex items-center justify-center mt-3"><span className="text-sm text-center font-bold">No exercise plan</span></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{height: "3vh"}}>
                    </div>
                </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
