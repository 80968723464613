import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/index.css";
import axios from "axios";
import LogoMissionLanding from "../../images/logos/fyxd-logo-header-mission-page.svg";
import LogoBack from "../../images/logos/fyxd-logo-header-back.svg";
import ReactLoading from "react-loading";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import { Navbar } from "../components/navbar";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import MissionBg from "../../images/icons/v4/missiondetail/mission-sip-stamp.png";
import IconCoffee from "../../images/icons/v4/missiondetail/coffee_icon.svg";
import { setCookie, getCookie, removeCookie } from "../../utils/cookies";
import Stamp from "./components/Stamp";
import Modal from "react-modal";
import ClosedCircle from "../../images/icons/v4/redeem/icon-close.png";
import IconCongrat from "../../images/icons/v4/redeem/icon-redeem-conglat.svg";
import { twMerge } from "tailwind-merge";
import IconMissionCompleted from "../../images/icons/v4/missionstatus/icon-missioncomplete.svg";
import IconRedeemNow from "../../images/icons/v4/missionstatus/icon-redeemnow.svg";
import { getAnalytics, logEvent } from "firebase/analytics";
import IconRedeemed from "../../images/icons/v4/missionstatus/icon-redeemed.svg";
import { set } from "date-fns";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    minHeight: "30vh",
    borderRadius: "20px",
    minWidth: "90%",
    zIndex: "10000",
  },
  overlay: {
    background: `
      radial-gradient(circle at center, rgba(249, 238, 217, 0.5) 10%, transparent 20%),
      radial-gradient(circle at center, rgba(253, 224, 172, 0.5) 40%, transparent 40%),
      radial-gradient(circle at center, rgba(250, 230, 186, 0.5) 60%, transparent 60%),
      radial-gradient(circle at center, rgba(255, 238, 170, 0.5) 80%, transparent 80%),
      radial-gradient(circle at center, rgba(249, 238, 217, 0.5) 100%, transparent 100%)
    `,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

export default function MissionDetailPage() {
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;
  const analytics = getAnalytics();
  const [accessToken, setAccessToken] = useState("");
  const [tasksCompleted, setTasksCompleted] = useState(10);
  const [isOpen3, setIsOpen3] = useState(false);
  const [status, setStatus] = useState("active");
  const [missions, setMissions] = useState([]);
  const [count, setCount] = useState(0);
  const [load, setLoad] = useState(true);
  let Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    // userName = name;
    Email = email;
  }
  const redeemMission = async () => {
    console.log("redeemMission");
    try {
      const res = await axios.post(
        `${baseURI}/famzmember3/v1/fyxd/user/redeemmissionreward`,
        {
          mission_id: 1,
        },
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      console.log(res.data);
      getMission();
    } catch (error) {
      console.error(error);
      requestToken();
    }
  };
  const openModal3 = () => {
    logEvent(analytics, "Redeem reward button", {
      userId: name,
      visitation_count: "mission1_redeemreward_count",
    });
    setIsOpen3(true);
  };

  const closeModal3 = () => {
    setIsOpen3(false);
  };

  const navigateRedeem = async () => {
    redeemMission();
    await getMission();
    navigate("/missionlanding/1");
  };
  const requestToken = async () => {
    await requestAccessToken();
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getMission = async () => {
    setLoad(true);
    console.log("getMission");
    try {
      const res = await axios.get(
        `${baseURI}/famzmember3/v1/fyxd/user/missionreward`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      setMissions(res.data.data.mission);
      {
        missions.map((mission, idx) => {
          setCount(mission.progress);
          setTasksCompleted(Number(count));
          setStatus(mission.status);
        });
      }
      console.log(res.data);
      setLoad(false);
    } catch (error) {
      console.error(error);
      requestToken();
    }
  };

  useEffect(() => {
    requestToken();
    getMission();
    // const count = getCookie("count");
    // setTasksCompleted(Number(count));
    // const status = getCookie("status");
    // if (status === "redeemed") {
    //   setStatus("redeemed");
    // }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if (Email.length > 0 && accessToken.length > 0) getTransaction();
    if (accessToken.length === 0) requestToken();
    getMission();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div className="w-full" style={{ width: "100vw", height: "130vh" }}>
          <div className="static w-full bg-gray-100 p-3   text-center text-white">
            <div className="mt-1 flex text-center ">
              <a href="#/missionlanding/0">
                <img
                  src={LogoBack}
                  alt="Logo"
                  style={{ display: "inline-block" }}
                  className="mx-auto w-[10px] h-[30px]"
                />
              </a>
              <img
                src={LogoMissionLanding}
                alt="Logo"
                style={{ marginLeft: "0.5rem", display: "inline-block" }}
                className="mx-auto w-[298px] h-[38px]"
              />
            </div>
          </div>
          <div>
            <img className="w-full" src={MissionBg} alt="" />
          </div>
          <div className="px-4 text-center">
            <h1 className="mt-3 font-bold mb-2 text-xl">Sip and Stamp</h1>
            <span className="text-gray-500 text-sm">
              Spend over 120 FYX-D for every purchase at the Dilly Dally Kaffe
              in order to receive 1 stamp. Collect 10 stamps to receive a
              voucher for a cup of free coffee! <br />
            </span>
            <span className="text-gray-500 text-sm">
              For more information visit the terms and conditions
            </span>
          </div>
          <div className="px-4 mt-4">
            <div className="relative mt-3">
              <div
                className={twMerge(
                  "border-2 border-yellow-500 rounded-3xl p-2 text-center",
                  status === "redeemed" ? "filter grayscale" : ""
                )}
              >
                <img
                  src={IconCoffee}
                  className={twMerge(
                    "absolute -top-3 left-0 w-[62px]",
                    status === "redeemed" ? "filter grayscale" : ""
                  )}
                  alt=""
                />
                <span className="pl-12 mx-auto">
                  1 Free coffee at Dilly Dally Kaffe
                </span>
              </div>
            </div>
          </div>
          {load ? (
            <ReactLoading className="mx-auto" type="spokes" color="#000" />
          ) : (
            <div className="mt-4">
              {missions.map((mission, idx) => (
                <>
                  <div
                    className="relative px-4 py-2 grid grid-cols-4 gap-3"
                    key={idx}
                  >
                    <Stamp
                      totalTasks={mission.target}
                      tasksCompleted={mission.progress}
                      status={status}
                    />
                    {mission.progress === 10 && (
                      <>
                        <img
                          src={IconMissionCompleted}
                          className="w-[40vh] h-[40vh] absolute left-1/5 top-1/5 -mt-10 ml-5"
                          alt="Mission Completed"
                        />
                        <img
                          src={IconRedeemNow}
                          className="w-[12vh] h-[12vh] absolute right-0 top-1/2 mr-10"
                          alt="Redeem Now"
                        />
                      </>
                    )}
                    {status === "redeemed" && (
                      <>
                        <img
                          src={IconRedeemed}
                          className="w-[40vh] h-[40vh]  absolute m-auto left-0 right-0 bottom-4 top-1"
                          alt="Redeemed"
                        />
                      </>
                    )}
                  </div>
                  <div className="mt-2 px-4 mb-2">
                    <button
                      className={twMerge(
                        "p-4 w-full rounded-2xl",
                        mission.progress === 10
                          ? "cursor-pointer bg-yellow-400"
                          : "bg-gray-400"
                      )}
                      disabled={mission.progress !== 10}
                      onClick={openModal3}
                    >
                      Redeem Reward
                    </button>
                  </div>
                  <a href="#/missionlanding/1">
                    <div className="text-center mt-2 mb-6">
                      <span className="text-yellow-500">
                        View all rewards {" >"}
                      </span>
                    </div>
                  </a>
                  <div className="px-4 mb-2">
                    <span className="text-gray-500">Terms and Conditions</span>
                    <br />
                    <span className="text-gray-500 text-sm">
                      1. FAMZ จะได้รับ 1 แต้มจาก ยอดชำระผ่าน FYX-D Dilly Dally
                      Kaffe อย่างน้อย 120 FYX-D (หลังหักส่วนลด 10% แล้ว) ต่อ 1
                      ใบเสร็จ
                      <br />
                      2. เมื่อสะสมครบ 10 แต้ม FAMZ
                      ได้รับคูปองสิทธิ์แลกซื้อเครื่องดื่มได้ในราคา ไม่เกิน 90
                      บาท จำนวน 1 แก้ว
                      <br />
                      3. ระยะเวลากิจกรรม: 4 กันยายน 2567 - 30 พฤศจิกายน 2567
                      <br />
                      4. อายุของคูปอง: คูปองทุกใบจะหมดอายุในวันที่ 31 ธันวาคม
                      2567
                      <br />
                      5. สำหรับข้อสงสัยเพิ่มเติ่ม สามารถติดต่อได้ที่อีเมล
                      fyx-d_support@dtgo.com
                    </span>
                  </div>
                </>
              ))}

              {/* <Stamp
                totalTasks={10}
                tasksCompleted={tasksCompleted}
                status={status}
              /> */}
              {/* {tasksCompleted === 10 && status === "active" && (
              <>
                <img
                  src={IconMissionCompleted}
                  className="w-[40vh] h-[40vh] absolute left-1/5 top-1/5 -mt-10 ml-5"
                  alt="Mission Completed"
                />
                <img
                  src={IconRedeemNow}
                  className="w-[12vh] h-[12vh] absolute right-0 top-1/2 mr-10"
                  alt="Redeem Now"
                />
              </>
            )} */}
              {/* {status === "redeemed" && (
              <>
                <img
                  src={IconRedeemed}
                  className="w-[40vh] h-[40vh]  absolute m-auto left-0 right-0 bottom-4 top-1"
                  alt="Redeemed"
                />
              </>
            )} */}
            </div>
          )}

          <div className="h-24" />

          {/* <div className="mb-4 ">
            <Tab />
          </div> */}
          {/* Navbar */}
          <Modal
            isOpen={isOpen3}
            onRequestClose={closeModal3}
            style={customStyles}
            shouldCloseOnOverlayClick={false} // ห้ามปิดเมื่อคลิกด้านนอก
            shouldCloseOnEsc={false}
          >
            <div className="text-center">
              <div className="flex justify-end"></div>
              <div className="flex mt-4 flex-col ">
                <img
                  className="mx-auto"
                  src={IconCongrat}
                  alt=""
                  style={{ width: "30vh" }}
                />
                <span
                  className="mt-5 text-yellow-500 "
                  style={{ fontSize: "1.2rem" }}
                >
                  CONGRATULATIONS
                </span>
                <hr className="mt-3 mb-3 congrat-line " />
                <span className="text-xs text-ggrey-400 mb-2">
                  You have received
                </span>
                <span className="text-sm  font-bold text-yellow-900 mb-2">
                  1 Free coffee at Dilly Dally Kaffe{" "}
                </span>
                <button
                  className={twMerge(
                    "p-3 w-[50%] rounded-3xl mx-auto mb-3 mt-3",
                    tasksCompleted === 10
                      ? "cursor-pointer bg-yellow-400"
                      : "bg-gray-400"
                  )}
                  disabled={tasksCompleted !== 10}
                  onClick={navigateRedeem}
                >
                  Redeem Now!
                </button>
              </div>
            </div>
          </Modal>
          <Navbar path="/missionlanding/0" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
