import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import axios from "axios";

import LogoFyxdHistory from "../images/logos/fyxd-logo-header-mission-page.svg";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { Navbar } from "./components/navbar";
import { useNavigate } from "react-router-dom";

export default function starcafePage() {
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;

  const [accessToken, setAccessToken] = useState("");
  const [transactionList, setTransactionList] = useState([]);

  let Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    // userName = name;
    Email = email;
  }

  const requestToken = async () => {
    await requestAccessToken();
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    requestToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div style={{ width: "100vw", height: "100vh" }}>
          <Container style={{ marginTop: "-1vh" }}>
            <div className="mt-5 mb-4 text-center">
              <img
                className="mx-auto w-[298px] h-[38px]"
                src={LogoFyxdHistory}
                style={{ marginLeft: "0.5rem", display: "inline-block" }}
              />
            </div>
          </Container>
          {/* Navbar */}
          <Navbar path="/starcafe" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
