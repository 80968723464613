import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import PlanList from '../components/PlanList';
import { useNavigate } from 'react-router-dom';
import IconFyxDBlue from '../images/icons/v3/fyxd-logo-blue.svg';

export default function PlanListPage() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [planList, setPlanList] = useState([]);

    let Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
      // userName = name;
      Email = email;
    }

    const requestToken = async () => {
        await requestAccessToken();
    };
    
    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const getPlan = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/subscribe/list`, { "plan_id" : "3" }, {
                headers: {
                  Authorization: 'Bearer ' + accessToken
                }
              });
            
              setPlanList(res.data.data);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const handlePlanReceipt = (id) => {
        navigate(`/planreceipt/${id}`);
    }

    useEffect(() => {
        requestToken();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0)
            getPlan();
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container>
                    <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            <span onClick={goBack} className="float-left font-semibold text-xl cursor-pointer">{'<'}</span>
                            <span className="text-center text-black font-semibold text-xl">Dai Perm</span>
                        </div>
                      </nav>
                    <div className="mb-5">
                        <div className="mt-3">
                            <span className="text-md font-black">Cancellation instruction:</span><br/>
                            <span className="text-sm">Please select order transaction and tap <b>cancel order</b> at the bottom of the receipt.</span>
                        </div>
                        <div className="mt-3">
                            <hr className="mt-3" />
                        </div>
                        {
                            planList.map((item) => (
                                <div className="mx-auto mt-3" key={item.id} onClick={() => handlePlanReceipt(item.transaction_id)}>
                                    <div className="mx-auto history__detail">
                                        <PlanList item={item} />
                                    </div>
                                </div>
                            ))
                        }
                        {/* For Only Web App */}
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">
                            </div>
                        </div>
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">  
                            </div>
                        </div>
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">
                            </div>
                        </div>
                        {/* For Only Web App */}
                    </div>
                    {
                        planList.length === 0 &&
                        <div style={{ margin: "0",
                            position: "absolute",
                            top: "45%",
                            left: "35%",
                            transform: "translateY(-50%)"}}>
                            <span className="history__timestamp">No Subscribed Plan</span>
                        </div>
                    }
            </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}