import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ImageIconCafe from "../../images/icons/v4/missiondetail/coffee_icon.svg";
import ImageIconReading from "../../images/icons/v4/icon-reward-reading-society.svg";
import "../../styles/index.css";
import axios from "axios";
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import Pusher from "pusher-js";
import LogoDallyKaffe from "../../images/icons/v4/missiondetail/trophy_icon.svg";
import LogoMissionLanding from "../../images/logos/fyxd-logo-header-mission-page.svg";
import Modal from "react-modal";
import IconFail from "../../images/icons/v4/redeem/icon-redeem_error.svg";
import IconSuccess from "../../images/icons/v4/redeem/icon-redeem_success.svg";
import IconCongrat from "../../images/icons/v4/redeem/icon-redeem-conglat.svg";
import ClosedCircle from "../../images/icons/v4/redeem/icon-close.png";
import LogoBack from "../../images/logos/fyxd-logo-header-back.svg";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import { Navbar } from "../components/navbar";
import Tab from "../../components/MissionTab";
import { useNavigate } from "react-router-dom";
import { setCookie, getCookie, removeCookie } from "../../utils/cookies";
const customStyles = {
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    minHeight: "30vh",
    borderRadius: "20px",
    minWidth: "40vh",
  },
};

export default function RewardRedingSocietyPage() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;

  const [accessToken, setAccessToken] = useState("");
  const [transactionList, setTransactionList] = useState([]);

  let Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    // userName = name;
    Email = email;
  }

  const requestToken = async () => {
    await requestAccessToken();
  };

  const subscribePusher = () => {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;

    var pusher = new Pusher("1126eef8adf98aaa83d9", {
      cluster: "ap1",
    });

    var channel = pusher.subscribe(email);
    channel.bind("redeemed-notify", function (data) {
      if (data == "success") {
        toggleModal();
        setCookie("starcafe", "redeemed");
        pusher.unsubscribe("testmission");
      }
      if (data == "failed") {
        toggleModal2();
        pusher.unsubscribe("testmission");
      }

      // (JSON.stringify(data));

      // alert.log(`toggle ope modal`);
    });
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModal2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleModal3 = () => {
    setIsOpen3(!isOpen3);
  };
  const closeModal = () => {
    setIsOpen(false);
    navigate("/missionlanding/1");
  };
  const closeModal2 = () => {
    setIsOpen2(false);
  };
  const closeModal3 = () => {
    setIsOpen3(false);
    // navigate("/missionlanding");
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    requestToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (accessToken.length === 0) requestToken();
    // subscribePusher();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div className="w-full" style={{ width: "100vw", height: "110vh" }}>
          <div className="static w-full p-3  text-center text-white">
            <div className="mt-1 flex text-center ">
              <a href="#/missionlanding/0">
                <img
                  src={LogoBack}
                  alt="Logo"
                  style={{ display: "inline-block" }}
                  className="mx-auto w-[10px] h-[38px]"
                />
              </a>
              <img
                src={LogoMissionLanding}
                alt="Logo"
                style={{ marginLeft: "0.5rem", display: "inline-block" }}
                className="mx-auto w-[298px] h-[38px]"
              />
            </div>
          </div>
          <Container className="border-t-2 p-4">
            <div className="bg-[#FFF0D7]  rounded-2xl text-center relative mb-4">
              <div className="mx-2">
                <div className="flex flex-col space-y-4">
                  <div className="mt-4">
                    <img
                      src={LogoDallyKaffe}
                      alt="Logo"
                      style={{ marginLeft: "0.5rem", display: "inline-block" }}
                      className="mx-auto w-[7%]"
                    />
                  </div>
                  <span className="inline-block text-sm text-wrap">
                    1 Free book from the DTGO library
                  </span>
                  <div className="bg-white  mx-auto rounded-2xl qr-card">
                    <QRCode className="redeem-qr mx-auto w-[80%]" value={123} />{" "}
                  </div>

                  <div className="relative flex justify-center w-full">
                    <hr className="redeem__line absolute inset-x-0 top-[35%] transform -translate-y-1/2 border-t-2 border-dashed border-yellow-500 z-0" />
                    <img
                      src={ImageIconReading}
                      alt="Placeholder"
                      className="mx-auto w-[15%] relative"
                    />
                  </div>

                  <span className="inline-block  text-sm font-bold text-yellow-900 mb-2">
                    Voucher Number <br />
                    01834865830934
                  </span>
                  <span className="inline-block mx-3 text-start text-sm text-gray-400 mb-4">
                    Enjoy a free book from the DTGO library worth 100 FYX-D
                  </span>
                </div>
              </div>
            </div>
          </Container>
          <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <div className="text-center">
              <div className="flex justify-end">
                <img
                  src={ClosedCircle}
                  className="cross-icon w-[10%] float-right"
                  onClick={closeModal}
                ></img>
              </div>
              <div className="flex flex-col ">
                <img
                  className="mx-auto"
                  src={IconSuccess}
                  alt=""
                  style={{ width: "15vh" }}
                />
                <span
                  className="mt-5 text-yellow-500 mb-4 "
                  style={{ fontSize: "1.2rem" }}
                >
                  Redemption Complete
                </span>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isOpen2}
            onRequestClose={closeModal2}
            style={customStyles}
          >
            <div className="text-center">
              <div className="flex justify-end">
                <img
                  src={ClosedCircle}
                  className="cross-icon w-[10%] float-right"
                  onClick={closeModal2}
                ></img>
              </div>
              <div className="flex flex-col ">
                <img
                  className="mx-auto"
                  src={IconFail}
                  alt=""
                  style={{ width: "15vh" }}
                />
                <span
                  className="mt-5 text-yellow-500 "
                  style={{ fontSize: "1.2rem" }}
                >
                  Error
                </span>
                <span className="text-xs text-grey-400 mb-4">
                  Please contact FYX-D FAMz Support
                </span>
              </div>
            </div>
          </Modal>
          <Modal
            isOpen={isOpen3}
            onRequestClose={closeModal3}
            style={customStyles}
          >
            <div className="text-center">
              <div className="flex justify-end">
                <img
                  src={ClosedCircle}
                  className="cross-icon w-[10%] float-right"
                  onClick={closeModal3}
                ></img>
              </div>
              <div className="flex flex-col">
                <img
                  className="mx-auto"
                  src={IconCongrat}
                  alt=""
                  style={{ width: "30vh" }}
                />
                <span
                  className="mt-5 text-yellow-500 "
                  style={{ fontSize: "1.2rem" }}
                >
                  CONGRATULATIONS
                </span>
                <hr className="mt-3 mb-3 congrat-line " />
                <span className="text-xs text-grey-400 mb-2">You got</span>
                <span className="text-m text-yellow-900 mb-2">
                  Free 1 coffee at Dilly Dally Kaffe{" "}
                </span>
                <span className="text-xs  mb-4 text-ggrey-400 ">
                  From Sip and Stamp{" "}
                </span>
              </div>
            </div>
          </Modal>
          {/* Navbar */}
          <div className="text-yyellow-500 text-center">
            <a className="text-yyellow-500" href="#/missionlanding/0">
              <FontAwesomeIcon icon={faLessThan} className="w-2 mr-2 " />
              {`View all vouchers`}
            </a>
          </div>
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
