import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import IconDaidee from '../images/icons/v2/daideeplan.svg';
import IconFYXD from '../images/icons/v2/fyxdfactsheet.svg';
import IconTerm from '../images/icons/v2/tc.svg';
import SuspendLogo from '../images/suspend/suspend.svg';

import {Navbar} from "./components/navbar";

export default function SuspendPage() {

  return (
    <>
        <div style={{"width": "100vw", "height": "100vh"}}>
            <Container className="text-center mt-3">
                <a href="#/">
                    <h1 className="float-left">{`<`}</h1>
                </a>
                <h2 className="heading--main" style={{"fontWeight" : "300"}}>FYX-D</h2>
                <img className="" alt="" src={SuspendLogo} style={{"display": "inline-block"}}/> 
                
           </Container>
           {/* <Navbar path="/info" /> */}
        </div>
    </>
  );
}