import React from "react";

const IconTextBlock = ({ src, alt, text ,href}) => {
  return (
    <div className="p-2 text-center">
      <a href={href}> 
      <img src={src} alt={alt} className="w-16 mb-2" />
      <span className="text-[#000000]">{text}</span>
      </a>
    </div>
  );
};

export default IconTextBlock;
