import { useState, useEffect } from 'react';

const useQR = () => {
  const [QR, setQR] = useState("");
  const [payAmount, setPayAmount] = useState("");

  const saveQR = (newReturns) => {
    localStorage.setItem('QR', newReturns);
  };

  const savePayAmount = (newReturns) => {
    localStorage.setItem('payAmount', newReturns);
  };

  useEffect(() => {
    const storedQR = localStorage.getItem('QR');
    const storePayAmount = localStorage.getItem('payAmount');

    if (storedQR) {
        setQR(storedQR);
    }
    if (storePayAmount) {
        setPayAmount(storePayAmount);
    }
  }, []);

  return {
    QR,
    saveQR,
    payAmount,
    savePayAmount
  };
}

export default useQR;