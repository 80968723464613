import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import Modal from 'react-modal';
import LogoDColor from '../images/logos/d-logo-color-flat.svg';
import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import useQR from '../hooks/useQR';
import IconFyxDLoading from '../images/logos/fyxd-loading-logo.svg';

export default function ReviewScanPayPage() {
    const navigate = useNavigate();
    const { payAmount, QR } = useQR();

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [availablebalance, setAvailablebalance] = useState(payAmount);
    const [merchantName, setMerchantName] = useState("");
    const [merchantId, setMerchantId] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          minHeight: '20vh',
          minWidth: '40vh'
        },
    };

    const goBack = () => {
        navigate('/scanpay');
    };

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const getMerchantData = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/merchantqr`, 
            { "qr": QR }, { headers: { Authorization: 'Bearer ' + accessToken } });
            // setMerchant(res.data.data.merchant);
            setMerchantName(res.data.data.merchant.name);
            setMerchantId(res.data.data.merchant.merchant_id);
            // setBranch(res.data.data.branch);
            // setAvailablebalance(res.data.data.member.fyxd + res.data.data.member.bonus_fyxd + res.data.data.member.pending_bonus_fyxd);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const confirm = async () => {
        setIsLoading(true);
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/pay/qr`, 
            { "qr": QR, "total_amount": payAmount }, { headers: { Authorization: 'Bearer ' + accessToken } });

            navigate(`/successscanpay/${res.data.data.transaction_id}`);
        } catch (error) {
            console.log(error);
            showErrorModal();
        } finally {
            setIsLoading(false);
        }
    };

    const showErrorModal = () => {
        setIsOpen(true);
    };

    const retry = () => {
        navigate('/scanpay');
    }

    useEffect(() => {
        if(QR.length > 0) {
            getMerchantData();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, QR, accessToken, isAuthenticated]);

    useEffect(() => {
        setAvailablebalance(payAmount);
    }, [payAmount]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "70vh"}}>
                    <nav className="w-full">
                    <div className="text-center py-3 border-b border-ggrey-200">
                        <span onClick={goBack} className="float-left font-semibold text-xl cursor-pointer">{'<'}</span>
                        <span className="text-center text-black font-semibold text-xl">Review your transaction</span>
                    </div>
                    </nav>
                    <div className="mt-3">
                        <span className="text-black text-xl font-semibold">Total Payment Amount</span>
                    </div>
                    <div>
                      <div className="mt-3">
                        <div className="">
                            <img className="my-auto inline" alt="" src={LogoDColor} style={{width: "5.5vh", transform: "translateY(-30%)"}} />
                            <span className="mywallet-card-text">
                              <span className="ml-3 text-5xl font-bold">{parseFloat(availablebalance).toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</span>
                            </span>
                        </div>
                      </div>
                      <div>
                        <hr className="mt-3 mb-3" />
                        <span className="font-bold">Pay To</span>
                        <div className="mt-2 p-4 bg-gray-100 w-full rounded-2xl py-4" >
                            <span className="font-bold">FYX-D Merchant</span><br/>
                            <span>{merchantName}</span>
                        </div>
                      </div>
                      <div className="mt-3">
                        <span className="font-bold">Merchant ID</span><br/>
                        <span>{merchantId}</span>
                        <hr className="mt-3 mb-3" />
                      </div>
                      <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-ggrey-200">
                        <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer bg-yyellow-500  text-black')} onClick={confirm}>Confirm</button> 
                      </div>
                    </div>
                    <div>
                    {
                        isLoading && 
                        <div className="fixed z-50 inset-0 bg-black overflow-y-auto flex items-center justify-center opacity-80">
                            <img className="animation-blink" src={IconFyxDLoading} alt="loading" />
                        </div>
                    }
                    </div>
                </Container>
                <Modal
                  isOpen={isOpen}
                  style={customStyles}
                >
                  <div style={{textAlign : "center"}}>
                      <b><span className="mt-5" style={{fontSize : "1.2rem"}}>Cannot make Payment.<br/>Please try again.</span></b>
                      <button className="mt-3" style={{background: "#FFC557", borderRadius: "12px", flex: "none", width: "100%", border: "0px", color: "white", padding: "1rem"}} onClick={retry}>Retry</button>
                  </div>
                </Modal>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}