import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import IconCongrat from '../images/icons/v3/congrat-icon.svg';
import LogoFyxd from '../images/logos/fyxd-logo-color.svg';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import usePlan from '../hooks/usePlan';
// Sample app imports
import Error from './error-page';
import { useNavigate } from 'react-router-dom';

export default function PlanTransactionFailPage() {
    const navigate = useNavigate();
    const { FyxD, Returns, saveFyxD, saveReturns } = usePlan();

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [investAmount, setinvestAmount] = useState(0);
    const [returnsAmount, setreturnsAmount] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          minHeight: '20vh',
          minWidth: '40vh',
          borderRadius: '20px',
        },
    };

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const handleBackToHome = () => {
        navigate('/', { replace: true });
    };

    const getUserProfile = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember/v1/fyxd/member/dashboard`, 
            { "upn": Email }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
            // console.log(res.data);
           // setFullName(res.data.data.member.first_name + ' ' + res.data.data.member.last_name);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0) {
            getUserProfile();
            // getPlanReceipt();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw"}} className="bg-brown-50 h-full">
                <nav className="w-full bg-brown-50">
                    {/* <div className="text-center py-3 border-b border-ggrey-500">
                        <a href="#/investment">
                            <span className="float-left font-semibold text-xl ml-3">{'<'}</span>
                        </a>
                        <span className="text-center text-black font-semibold text-xl">Confirm Your Order</span>
                    </div> */}
                </nav>
                <Container className="h-full">
                      <div>
                        <div className="text-center py-3">
                            <span className="text-3xl text-yyellow-900">
                                Transaction Failed!
                            </span><br/><br/>
                            <span className="text-base text-black">
                                Dai Perm has reached maximum program limit
                            </span>
                        </div>
                        <div className="bg-white p-4 rounded-xl">
                            <div>
                                <span>Bonus Plan</span>
                                <img src={LogoFyxd} width={60} className="inline-block float-right" />
                                <br/>
                                <span className="text-2xl font-black text-yyellow-900">Dai Perm</span>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <span className="xs:text-xs sm:text-xs text-xs font-bold">
                                        Buy Amount
                                    </span>
                                </div>
                                <div>
                                    <span className="xs:text-xs sm:text-xs text-xs font-bold">
                                        Guaranteed Bonus*
                                    </span><br/>
                                    {/* <span className="xs:text-xs sm:text-xs text-xs text-ggrey-700">
                                        (After 1 Jan 2025)
                                    </span> */}
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div className="mt-2">
                                    <div className="inline-block float-left">
                                        <span className="font-black text-2xl text-yyellow-500">{FyxD.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                        <span className="text-sm">FYX-D & Bonus</span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div className="inline-block float-left">
                                        <span className="font-black text-2xl text-yyellow-500">{Returns.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                        <span className="text-sm">Dai Perm</span>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className="mt-3 grid grid-cols-2 gap-2">
                                <div>
                                    <span className="font-semibold text-xs">Purchase status</span>
                                </div>
                                <div>
                                    <span className="text-xs float-right text-red-700 font-bold">Failed</span>
                                </div>
                            </div>

                            <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-t border-ggrey-200">
                                <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl bg-yyellow-500  text-black')} onClick={handleBackToHome}>Back to home</button> 
                            </div>
                        </div>
                            <div style={{height: "12vh"}}>
                            </div>
                      </div>
                </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
