import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { loginRequest } from '../authConfig';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import LogoFyxdLogin from '../images/logos/fyxd-login-color.svg';

export default function LoginPage() {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    useEffect(() => {
        if(isAuthenticated) {
            // the navigate(0) below is necessary to force the re-render of the home screen
            // once we redirect back to it, otherwise the wallet balance may not be updated
            // reference: https://stackoverflow.com/questions/74525254/usenavigate-hook-does-not-re-render-components-can-i-force-it
            navigate("/");
            navigate(0);
        } else {
            callLoginPopup();
        }
    }, [isAuthenticated]);

    const callLoginPopup = () => {
        instance.loginPopup(loginRequest).then(function (loginResponse) {
            // console.log(loginResponse);
          })
          .catch(function (error) {
            //login failure
            console.log(error);
          });
    }

    const handleLogin = () => {
        callLoginPopup();
    }

    return (
        <div style={{"width": "100vw", "height": "100vh"}}>
            <Container className='' style={{"height": "70vh"}}>
                <div className="">
                    <div className="text-center mt-5 mb-5">
                        <div className="mt-3 mb-5">
                            <img src={LogoFyxdLogin} alt="" style={{"display": "inline-block"}} className="mr-3" />
                        </div>
                    </div>
                    <div className="mx-auto mt-5" style={{"borderRadius": "0.5rem", "border": "1px solid var(--neutral-neu-300, #EBEBEB)", "background": "var(--neutral-neu-50-white, #FFF)", "width": "50vw", "padding": "2vw"}}>
                        <div className="">
                            <button style={{ "backgroundColor": "#E8B34F", "borderRadius": "0.75rem", "padding": "1rem", "border": "1px solid var(--yellow-yellow-600, #E8B34F)", "width": "100%" }} 
                                onClick={() => handleLogin()}>
                                Login by Outlook
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
    </div>
    );
  }