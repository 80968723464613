import { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import axios from "axios";
import IconPortfolioNav from "../images/icons/v3/portfolio-nav-icon.svg";
import IconTaxNav from "../images/icons/v3/tax-nav-icon.svg";
import IconInfo from "../images/icons/v3/info-icon.svg";
import LogoDaideeBlack from "../images/icons/v3/daidee-black-logo.svg";
import IconPortfolio from "../images/icons/v3/portfolio-black-icon.svg";
import IconGraph from "../images/icons/v3/graph-icon.svg";
import IconTotalFyxDUn from "../images/icons/v3/total_fyxd_unrealized.svg";
import IconDaideeList from "../images/icons/v3/daidee-plan-icon.svg";
import { Tooltip } from "react-tooltip";
import IconDaidee from "../images/icons/v2/daideeplan.svg";
import IconEye from '../images/icons/v3/eye-icon.svg';
import IconDownload from '../images/icons/v3/download-icon.svg';

import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

import PdfViewer from './pdfviewer';

// Sample app imports
import Error from "./error-page";
import { Navbar } from "./components/navbar";

import { useNavigate } from 'react-router-dom';
import LogoDaidee2 from '../images/logos/daidee-2.svg';
import toast from 'react-hot-toast';

export default function PortfolioPage() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;

  const [accessToken, setAccessToken] = useState('');
  const [realizedBalance, setrealizedBalance] = useState(0);
  const [unrealizedBalance, setunrealizedBalance] = useState(0);
  const [totalSubscribePlan, settotalSubscribePlan] = useState(0);
  const [plans, setplans] = useState([]);
  const [taxDocuments, settaxDocuments] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');

  let userName = "",
    Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    userName = name;
    Email = email;
  }

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const requestToken = async () => {
    await requestAccessToken();
  };

  const handleOpenInvest = () => {
    navigate("/investment");
  };

  const handleOpenSubscribePlan = (plan_subscribe_id) => {
    navigate(`/plandetail/${plan_subscribe_id}`);
  };

  const getUserPotfolio = async () => {
    console.log("getUserPotfolio");

    try {
      const res = await axios.post(
        `${baseURI}/famzmember3/v1/fyxd/portfolios`,
        {},
        { headers: { Authorization: "Bearer " + accessToken } }
      );

      let sum = 0;
      for (const plan of res.data.data.plan_subscribe) {
        sum += parseFloat(plan.total_amount);
      }

      const total =
        res.data.data.member.fyxd +
        res.data.data.member.bonus_fyxd +
        res.data.data.member.airdrop_fyxd;
      setrealizedBalance(total);
      settotalSubscribePlan(sum);
      setunrealizedBalance(total + sum);
      setplans(res.data.data.plan_subscribe);
    } catch (error) {
      console.error(error);
      requestToken();
    }
  };

  const getDocuments = async () => {
    // /famzmember3/v1/fyxd/user/documents
    console.log("getDocuments");

    try {
      const res = await axios.get(
        `${baseURI}/famzmember3/v1/fyxd/user/documents`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );

      console.log(res.data);
      console.log(res.data.data.documents);
      settaxDocuments(res.data.data.documents);
    } catch (error) {
      console.error(error);
      requestToken();
    }
  };

  useEffect(() => {
    if (Email.length > 0 && accessToken.length > 0) {
      // getUserProfile();
      getUserPotfolio();
      getDocuments();
    }
    if (accessToken.length === 0) requestToken();
    }, [Email, accessToken, isAuthenticated]);

    const sendMail = async (document_id) => {
        const t = toast.loading('Sending email...');
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/email/sendtaxdocument`,{ email, document_id }, {headers: {Authorization: 'Bearer ' + accessToken}});
            toast.success("Please check your email for the download link.");
        } catch (error) {
            console.error(error);
            requestToken();
        } finally {
            toast.dismiss(t);
        }
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0) {
            // getUserProfile();
            getUserPotfolio();
            getDocuments();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  const [activeTab, setActiveTab] = useState("portfolio");

  const tabs = [
    { id: "portfolio", title: "My Portfolio" },
    { id: "plans", title: "Get More Plans" },
    { id: "tax", title: "Tax Documents" },
  ];

  const currentDate = new Date();

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div style={{ width: "100vw", height: "100vh" }}>
        {pdfUrl === "" && (
          <Container style={{ height: "70vh" }} className="px-6">
            <nav className="w-full">
              <div className="text-center px-2 py-1 mt-2">
                <img src={IconPortfolioNav} alt="" />
              </div>
            </nav>
            <div>
              <div className="flex mt-3">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    className={`py-1 px-2 text-xs font-black text-center border border-gray-200 rounded-xl
                                                ${
                                                  activeTab === tab.id
                                                    ? "border-yellow-400 yellow-gradient text-brown-600"
                                                    : "border-transparent"
                                                } 
                                                hover:bg-gray-100 focus:outline-none`}
                    onClick={() => setActiveTab(tab.id)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              <div>
                {activeTab === "portfolio" && (
                  <div>
                    {/* <div className="mt-3">
                                                    <span className="text-xl font-black mywallet-card__large-text">Total Balance summary</span>
                                                </div> */}
                    <div>
                      {/* <div className="mt-2">
                                                        <div className="inline-block w-2/4">
                                                            <span className="text-black xs:text-xxs text-xs font-semibold inline-block">
                                                                Total balance (Realized)
                                                            </span>
                                                                <a data-tooltip-id="totalbalance2-tooltip" data-tooltip-html="It is the total FYX-D balance that you currently hold.">   
                                                                    <img src={IconInfo} alt="" className="inline-block ml-1" />
                                                                </a>
                                                                <Tooltip id="totalbalance2-tooltip" style={{ fontSize: "0.5rem"}}/>
                                                            <br/>
                                                            <div>
                                                                <svg className="inline-block" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.4" d="M14.8773 7.37038C15.3838 7.87689 15.8903 8.38339 15.8903 8.38339C16.5725 7.70114 16.576 7.02235 15.9007 6.34701C15.3941 5.8405 15.0565 5.50283 15.0582 5.16343C14.2054 6.01625 14.2019 6.69504 14.8773 7.37038Z" fill="#6B5A36"/>
                                                                    <path opacity="0.65" d="M15.0253 11.6122C15.027 11.2728 15.3681 10.9317 15.8798 10.42C16.5621 9.73772 16.5655 9.05892 15.8902 8.38358C15.8902 8.38358 15.3785 8.89527 14.8668 9.40696C14.1845 10.0892 14.1811 10.768 15.0253 11.6122Z" fill="#6B5A36"/>
                                                                    <path d="M11.3438 1.44922C11.0044 1.45095 10.6667 1.11328 10.1602 0.606772C9.48484 -0.0685687 8.80604 -0.0651126 8.12379 0.61714C8.12379 0.61714 8.63029 1.12364 9.1368 1.63015C9.81214 2.30549 10.4909 2.30203 11.3438 1.44922Z" fill="#6B5A36"/>
                                                                    <path opacity="0.65" d="M7.1004 1.64057C7.61209 1.12888 8.12378 0.617188 8.12378 0.617188C7.44844 -0.0581526 6.76964 -0.0546965 6.08739 0.627556C5.5757 1.13925 5.23457 1.48037 4.89518 1.4821C5.73935 2.32627 6.41815 2.32282 7.1004 1.64057Z" fill="#6B5A36"/>
                                                                    <path opacity="0.65" d="M15.0583 5.16367C15.9111 4.31085 15.9145 3.63206 14.9015 2.61905L13.8885 1.60604C12.8755 0.593025 12.1967 0.596481 11.3439 1.4493C11.6833 1.44757 12.1972 1.94668 12.8651 2.62941L13.8781 3.64242C14.5463 4.32497 15.06 4.82427 15.0583 5.16367Z" fill="#6B5A36"/>
                                                                    <path d="M4.89526 1.48193C4.05109 0.637758 3.37229 0.641215 2.34891 1.66459L1.32553 2.68797C0.302156 3.71135 0.2987 4.39015 1.14287 5.23432C1.1446 4.89492 1.64898 4.37592 2.33854 3.70098L3.36192 2.6776C4.0513 2.00248 4.55587 1.48366 4.89526 1.48193Z" fill="#6B5A36"/>
                                                                    <path d="M11.273 15.3645C12.1172 16.2087 12.796 16.2052 13.8194 15.1818L14.8428 14.1585C15.8661 13.1351 15.8696 12.4563 15.0254 11.6121C15.0237 11.9515 14.5193 12.4705 13.8298 13.1454L12.8064 14.1688C12.117 14.8439 11.6124 15.3628 11.273 15.3645Z" fill="#6B5A36"/>
                                                                    <path d="M4.80005 4.8014H7.59285C8.42695 4.8014 9.15472 4.96194 9.77618 5.28143C10.3976 5.60251 10.8798 6.06188 11.2212 6.66112C11.5627 7.26037 11.7334 7.95975 11.7334 8.76087C11.7334 10.0881 11.3635 11.0943 10.6223 11.7762C9.8825 12.4596 8.86421 12.7998 7.57039 12.7998H4.80005V4.7998V4.8014ZM7.73211 10.8097C8.28618 10.8097 8.71147 10.6269 9.00947 10.2598C9.30746 9.89419 9.45722 9.39349 9.45722 8.76087C9.45722 8.19659 9.30597 7.72768 9.00497 7.35256C8.70398 6.97743 8.28918 6.79146 7.76506 6.79146H7.03129V10.8082H7.73211V10.8097Z" fill="#6B5A36"/>
                                                                </svg>
                                                                <span className="ml-1 font-black">{realizedBalance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                                            </div>
                                                            <span className="text-sm">
                                                                (Unit price: 1 = 1 FYX-D)
                                                            </span>
                                                        </div>
                                                        <div className="inline-block w-2/4 align-top">
                                                            <span className="text-black xs:text-xxs text-xs font-semibold inline-block">
                                                                Total balance (Unrealize)
                                                            </span>
                                                                <a data-tooltip-id="totalbalance-tooltip" data-tooltip-html="This your predicted total FYX-D balance that you stand to receive upon maturity of the FYX-D plans.">   
                                                                    <img src={IconInfo} alt="" className="inline-block ml-1" />
                                                                </a>
                                                                <Tooltip id="totalbalance-tooltip" style={{ fontSize: "0.5rem"}}/>
                                                            <br/>
                                                            <div>
                                                                <img className="inline-block" src={IconTotalFyxDUn} alt="" />
                                                                <span className="ml-1 font-black">{unrealizedBalance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                            </div>
                                                        </div>
                                                    </div> */}
                      <div className="mt-4">
                        <span className="text-xl font-black mywallet-card__large-text">
                          My Plans
                        </span>
                        <br />
                        <div className="mt-2">
                          <div className="inline-block w-3/5">
                            <span className="text-black xs:text-xxs text-xs inline-block font-black">
                              Total plans value (Unrealized)
                            </span>
                            <a
                              data-tooltip-id="totalbalance3-tooltip"
                              data-tooltip-html="This is the total amount of FYX-D locked up in all of the plans you are participating in."
                            >
                              <img
                                src={IconInfo}
                                alt=""
                                className="inline-block ml-1"
                              />
                            </a>
                            <Tooltip
                              id="totalbalance3-tooltip"
                              style={{ fontSize: "0.5rem" }}
                            />
                          </div>
                          <div className="inline-block w-2/5 align-bottom">
                            <div className="float-right">
                              <img
                                src={LogoDaideeBlack}
                                className="inline-block"
                              />
                              <span className="text-black xs:text-sm text-md font-black inline-block ml-1">
                                {totalSubscribePlan.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr className="mt-3 border-brown-500 border-2" />
                        <div className="mt-3 grid grid-cols-2 gap-2">
                          {/* <div className="grid grid-cols-2 gap-2" onClick={handleOpenPlan}>
                                                                <div>
                                                                    <span className="font-bold">DAI-DEE</span><br/>
                                                                    <img src={IconPortfolio} alt="" className="inline-block" /> 
                                                                    <span className="inline-block text-xs ml-1">74%</span><br/>
                                                                    <div>
                                                                        <img src={IconGraph} className="inline-block" />
                                                                        <span className="text-xs font-black text-green-500 inline-block ml-1">0% of 50%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-end">
                                                                    <div>
                                                                        <span className="text-sm font-bold">35,000.00</span><br/>
                                                                        <span className="text-xs font-black text-green-500">(+0.00)</span>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                          {plans.map((plan) => (
                            <div
                              key={plan.id}
                              className="grid grid-cols-2 gap-2 cursor-pointer mb-3"
                              onClick={() => handleOpenSubscribePlan(plan.id)}
                            >
                              <div>
                                <span className="font-bold text-sm">
                                  {plan.title}
                                </span>
                                <br />
                                <img
                                  src={IconPortfolio}
                                  alt=""
                                  className="inline-block"
                                />
                                <span className="inline-block text-xs ml-1">
                                  {(
                                    (parseFloat(plan.total_amount) /
                                      totalSubscribePlan) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </span>
                                <br />
                                <div>
                                  {/* <img src={IconGraph} className="inline-block" /> */}
                                  {/* <span className="text-xxs font-black text-green-500 inline-block ml-1">{plan.accum_percent}% of {plan.accum_total_percent}%</span> */}
                                </div>
                              </div>
                              <div className="flex items-end">
                                <div>
                                  <span className="text-sm font-bold">
                                    {parseFloat(
                                      plan.total_amount
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </span>
                                  <br />
                                  {/* <span className="text-xs font-black text-green-500">(+{((plan.total_amount)*plan.accum_percent/100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })})</span> */}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {plans.length === 0 && (
                          <>
                            <div className="flex items-center justify-center">
                              <span className="font-bold text-sm text-gray-400">
                                No Subscribed Plan
                              </span>
                            </div>
                          </>
                        )}
                        <hr className="mt-1 border-brown-500 mb-3 border-2" />
                        <div>
                          <span className="mt-1 text-xs text-gold-900 font-bold">
                            Remark: Gain are unrealized value, estimation are
                            rounded up value.
                          </span>
                        </div>

                        <div style={{ height: "15vh" }}></div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "plans" && (
                  <div>
                    <div className="mt-4 px-2">
                      <span className="text-2xl text-brown-700 font-bold">
                        Hi FAMZ!
                      </span>
                      <br />
                    </div>
                    <hr className="mt-3 mb-3" />
                  </div>
                )}
                {activeTab === "mission" && (
                  <div>
                    <h3 className="mt-3 text-lg font-semibold">Mission</h3>
                    <p>Comming Soon..</p>
                  </div>
                )}
                {activeTab === "tax" && (
                  <>
                    <h3 className="mt-3 text-xl font-black mywallet-card__large-text">
                      Withholding Tax Documents
                    </h3>
                    {taxDocuments.map((document) => (
                      <div key={document.id} className="mt-3">
                        <div className="mb-3 info__detail bg-yyellow-200">
                          <img className="info__logo" alt="" src={IconDaidee} />
                          <div className="info__detail-content">
                            <span className="child">
                              <b>{document.name}</b>
                            </span>
                            <br />
                            <span className="child text-gray-500">
                              Withholding Tax Document
                            </span>
                            <br />
                          </div>
                          <div>
                            {/* <a href={document.document_uri} target="_blank" rel="noreferrer"> */}
                            <button
                              onClick={() => setPdfUrl(document.document_uri)}
                              className="inline-block"
                            >
                              <img src={IconEye} alt="" />
                            </button>
                            <button
                              onClick={() => sendMail(document.id)}
                              className="inline-block"
                            >
                              <img src={IconDownload} alt="" />
                            </button>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </Container>
        )}
        {pdfUrl !== "" && (
          <>
            <div className="w-full bg-yyellow-700 p-2">
              <button
                onClick={() => setPdfUrl("")}
                className="text-white px-2 py-1"
              >
                Back
              </button>
            </div>
            <PdfViewer pdfUrl={pdfUrl} />
          </>
        )}
        {/* Navbar */}
        <Navbar path="/portfolio" />
      </div>
      </MsalAuthenticationTemplate>
    </>
   );
}