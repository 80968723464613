import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import { Buffer } from 'buffer';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

// Sample app imports
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import usePlan from '../hooks/usePlan';

export default function PlanOTPPage() {
    const navigate = useNavigate();
    const { FyxD } = usePlan();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [refCode, setrefCode] = useState('');
    const [receiveOTP, setreceiveOTP] = useState('');
    const [OTP, setOTP] = useState('');
    const [load, setLoad] = useState(false);
    const [isRequestOTP, setisRequestOTP] = useState(false);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const generateRandomAlphaNumericString = (mode, length) => {
        let characters;
        if(mode === 'all')
            characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        else if(mode === 'alphabet')
            characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        else
            characters = '0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters[randomIndex];
        }
        return result;
    };

    // Define your credentials - usually, these should be stored securely
const username = 'ACad762c394d1cd4155a9fc73e92f71437';
const password = '5fb6ec10da12a74c98dd6540bb6236bd';

// Create a base64 encoded string for the Basic Authentication header
const token = Buffer.from(`${username}:${password}`).toString('base64');

    const requestOTP = async () => {
        if(!isRequestOTP) {
            try {
                const response = await axios.post(`https://t3cp46k190.execute-api.ap-southeast-1.amazonaws.com/uat/v1/fyxd/requestOtp`, { "email": Email } );
                    
                // console.log(response.data);
                setisRequestOTP(true);
                setrefCode(response.data.refCode);
                setreceiveOTP(response.data.otp);
            } catch (error) {
                console.error(error);
                // requestToken();
            }
        } else {
            console.log('exceed requestotp');
        }
    };

    const requestOTPbak = async () => {
        setLoad(true);
        try {
            const _refCode = generateRandomAlphaNumericString('all', 6);
            // const _OTP = generateRandomAlphaNumericString('number', 5);
            setrefCode(_refCode);

           //  console.log('OTP: ', _OTP);
    
            const params = new URLSearchParams();
            params.append('To', Email);
            params.append('Channel', 'email');
            params.append('Subject', 'Confirm Dai Perm Order');
            // params.append('customCode', _OTP);

            const res = await axios.post('https://verify.twilio.com/v2/Services/VA76c6373223bf4009ccb5fa6193c0840b/Verifications', params, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })

            console.log(res.data);
        } catch (error) {
            console.error(error);
            requestToken();
        } finally {
            setLoad(false);
        }
    };

    const verifyOTP = async (otp) => {
        setLoad(true);
        try {
            const res = await axios.post(`https://t3cp46k190.execute-api.ap-southeast-1.amazonaws.com/uat/v1/fyxd/verifyOtp`, { 
            "refCode": refCode,
            "inputOtp": otp })

            return true;
        } catch (error) {
            console.error(error);
            toast.error('Incorrect OTP. Please try again.');
        } finally {
            setLoad(false);
        }
    }

    const verifyOTP2 = async (otp) => {
        setLoad(true);
        try {
            const params = new URLSearchParams();
            params.append('To', Email);
            params.append('Code', otp);

            const res = await axios.post('https://verify.twilio.com/v2/Services/VA76c6373223bf4009ccb5fa6193c0840b/VerificationCheck', params, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })

            console.log(res.data);

            return res.data;
        } catch (error) {
            console.error(error);
            toast.error('Incorrect OTP. Please try again.');

            return error;
        } finally {
            setLoad(false);
        }
    };

    useEffect(() => {
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    useEffect(() => {
        if(Email.length > 0 && !isRequestOTP) {
            requestOTP();
        }
    // eslint-disable-next-line
    }, [Email]);

    const authRequest = {
        ...loginRequest
    };

    const [inputs, setInputs] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);

  const handleInputChange = async (event, index) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);

    if (event.target.value.length === 1) {
      if (index < inputs.length - 1) {
        inputRefs.current[index + 1].focus();
      } else {
        const otp = '' + inputs[0] + inputs[1] + inputs[2] + inputs[3] + inputs[4] + event.target.value;
        setOTP(otp);

        const isVerified = await verifyOTP(otp);
      
        if(isVerified) {
            setLoad(true);

            try {
                const res1 = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/limit/total`, 
                { "plan_id": 3, "total_amount": FyxD }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
                try {
                    if(Email !== 'adriel_fo@dtgo.com') {
                        const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/subscribe`, { "plan_id": 3,
                        "total_amount": FyxD }, { headers: { Authorization: 'Bearer ' + accessToken } });
                            console.log(res);
                        navigate(`/plancongrat/${res.data.data.transaction_id}`);
                    }

                    if(Email === 'adriel_fo@dtgo.com') {
                        const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/subscribe`, { "plan_id": 6,
                        "total_amount": FyxD }, { headers: { Authorization: 'Bearer ' + accessToken } });
                            console.log(res);
                        navigate(`/plancongrat/${res.data.data.transaction_id}`);
                    }
                } catch (error) {
                    console.error(error);
                    requestToken();
                } finally {
                    setLoad(false);
                }
            } catch (error) {
                navigate('/plantransactionfail');
            }
        }

      }
    }
  };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            {load  
                && <ReactLoading className="m-auto left-0 right-0 top-1/3 absolute z-50" type="spokes" color="#000" />
            }
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "70vh"}}>
                      <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            <span className="float-left font-semibold text-xl cursor-pointer" onClick={goBack}>{'<'}</span>
                            <span className="text-center text-black font-semibold text-xl">Confirm Your Order</span>
                        </div>
                      </nav>
                      <div>
                        <div className="mt-3">
                            <span className="text-2xl mb-3 font-black text-yyellow-700">Enter OTP</span><br/><br/>
                 
                            <span className="mt-3 mb-2 font-semibold">
                                Dear FAMZ, please check your email to confirm your purchase. 
                            </span><br/><br/>
                      
                            <p className="text-gray-500">
                                OTP sent to your DTGO email <span className="text-yellow-700 font-black">{Email}</span> (Ref: {refCode}).
                            </p>

                            <div className="mt-4 mx-auto flex justify-center items-center">
                                {inputs.map((input, index) => (
                                    <input  
                                        key={index}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        type="number"
                                        maxLength={1}
                                        value={input}
                                        placeholder='•'
                                        onChange={(event) => handleInputChange(event, index)}
                                        style={{width: '14.28%'}}
                                        className="border border-gray-200 p-3 ml-2 text-center text-yyellow-700 font-bold text-3xl"
                                    />
                                ))}
                            </div>
                    
                            {/* <div className="mt-4">
                                <input maxLength={1} className="border border-gray-200 p-3 w-1/6 ml-2 text-center"/>
                                <input maxLength={1} className="border border-gray-200 p-3 w-1/6 ml-2 text-center"/>
                                <input maxLength={1} className="border border-gray-200 p-3 w-1/6 ml-2 text-center"/>
                                <input maxLength={1} className="border border-gray-200 p-3 w-1/6 ml-2 text-center"/>
                                <input maxLength={1} className="border border-gray-200 p-3 w-1/6 ml-2 text-center"/>
                            </div> */}

                            <div className="text-center mt-5">
                                <span>Didn’t receive OTP?</span><br/>
                                <span className="font-semibold text-yellow-700" onClick={() => {setisRequestOTP(false); requestOTP();}}>Resend OTP</span>
                            </div>
                        </div>
                      </div>
                </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
