import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import IconCongrat from '../images/icons/v3/congrat-icon.svg';
import LogoFyxd from '../images/logos/fyxd-logo-color.svg';
import IconCancelOrder from '../images/icons/v3/cancelorder-icon.svg';
import Modal from 'react-modal';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

// Sample app imports
import Error from './error-page';
import { useNavigate, useParams } from 'react-router-dom';

export default function PlanCongratPage() {
    let { id } = useParams();
    const navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [investAmount, setinvestAmount] = useState(0);
    const [returnsAmount, setreturnsAmount] = useState(0);
    const [transactionId, settransactionId] = useState('');
    const [createdAt, setcreatedAt] = useState(new Date());
    const [fullName, setFullName] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          minHeight: '20vh',
          minWidth: '40vh',
          borderRadius: '20px',
        },
    };

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const handleBackToHome = () => {
        navigate('/', { replace: true });
    };

    const getUserProfile = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember/v1/fyxd/member/dashboard`, 
            { "upn": Email }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
            // console.log(res.data);
            setFullName(res.data.data.member.first_name + ' ' + res.data.data.member.last_name);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const getPlanReceipt = async() => {
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/receipt`, { "transaction_id": id }, { headers: { Authorization: 'Bearer ' + accessToken }});
            // console.log(res.data);
            setinvestAmount(res.data.data.total_amount);
            setreturnsAmount(res.data.data.total_amount * 1.05);
            settransactionId(res.data.data.transaction_id);
            setcreatedAt(res.data.data.created_at);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const handleCancelPlan = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/unsubscribe`, { "transaction_id": id }, { headers: { Authorization: 'Bearer ' + accessToken }});

            navigate(`/investment`);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const handleCancel = async () => {
        setIsOpen(false);
    };

    const handleOpenModal = async () => {
        setIsOpen(true);
    }

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0 && id.length > 0) {
            getUserProfile();
            getPlanReceipt();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated, id]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw"}} className="bg-brown-50 h-full">
                <nav className="w-full bg-brown-50">
                    {/* <div className="text-center py-3 border-b border-ggrey-500">
                        <a href="#/investment">
                            <span className="float-left font-semibold text-xl ml-3">{'<'}</span>
                        </a>
                        <span className="text-center text-black font-semibold text-xl">Confirm Your Order</span>
                    </div> */}
                </nav>
                <Container className="h-full">
                      <div>
                        <div className="text-center py-5">
                            <span className="text-3xl text-yyellow-900">
                                Congratulations!
                            </span>
                        </div>
                        <div className="text-center">
                            <img src={IconCongrat} alt="" className="mx-auto" /><br/>
                            <span className="text-ggrey-500">Your order has been successfully placed.</span>
                        </div>
                        <div className="mt-3 bg-white p-4 rounded-xl">
                            <div>
                                <span>Bonus Plan</span>
                                <img src={LogoFyxd} width={60} className="inline-block float-right" />
                                <br/>
                                <span className="text-2xl font-black text-yyellow-900">Dai Perm</span>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <span className="xs:text-xs sm:text-xs text-xs font-bold">
                                        Buy Amount
                                    </span>
                                </div>
                                <div>
                                    <span className="xs:text-xs sm:text-xs text-xs font-bold">
                                        Guaranteed Bonus*
                                    </span><br/>
                                    {/* <span className="xs:text-xs sm:text-xs text-xs text-ggrey-700">
                                        (After 1 Jan 2025)
                                    </span> */}
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2">
                                <div className="mt-2">
                                    <div className="inline-block float-left">
                                        <span className="font-black text-2xl text-yyellow-500">{investAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                        <span className="text-sm">FYX-D & Bonus</span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div className="inline-block float-left">
                                        <span className="font-black text-2xl text-yyellow-500">{returnsAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span><br/>
                                        <span className="text-sm">FYX-D</span>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className="mt-3 grid grid-cols-2 gap-2">
                                <div>
                                    <span className="font-semibold text-xs">Purchase status</span>
                                </div>
                                <div>
                                    <span className="text-xs float-right text-green-700 font-bold">Pre-ordered</span>
                                </div>
                                <div>
                                    <span className="font-semibold text-xs">Transaction ID</span>
                                </div>
                                <div>
                                    <span className="text-xs float-right">{transactionId}</span>
                                </div>
                            </div>

                            <hr className="mt-3 border-brown-500 border-2"/>
                            <div className="mt-3 grid grid-cols-2 gap-2">
                                <div>
                                    <span className="font-semibold text-xs">Order Placement Date</span><br/>
                                    <span className="font-semibold text-xs">Order Placement Time</span><br/>
                                    <span className="font-semibold text-xs">Trade Date</span><br/>
                                    <span className="font-semibold text-xs">Exercise Date</span><br/>
                                    <span className="font-semibold text-xs">Amount Release Date</span>
                                </div>
                                <div className="">
                                    <span className="text-xs float-right">{new Date(createdAt).toLocaleDateString('en-US', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric'
                                        })}</span><br/>
                                    <span className="text-xs float-right">{new Date(createdAt).toLocaleTimeString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric'
                                        })}</span><br/>
                                    <div className="mt-1">
                                        <span className="text-xs float-right"><small>20 Jan 2024 00:01:00</small></span><br/>
                                        <span className="text-xs float-right"><small>31 Dec 2024 23:59:00</small></span><br/>
                                        <span className="text-xs float-right"><small>31 Jan 2025 00:01:00</small></span>
                                    </div>
                                </div>
                            </div>
                           
                            <hr className="mt-3 border-brown-500 border-2"/>
                            <div className="mt-3 grid grid-cols-2 gap-2">
                                <div>
                                    <span className="font-semibold text-xs">Order Type</span>
                                </div>
                                <div>
                                    <span className="text-xs float-right">FYX-D Platform Order</span>
                                </div>
                            </div>
                            <hr className="mt-3 border-brown-500 border-2"/>
                            <div className="mt-3 grid grid-cols-2 gap-2">
                                <div>
                                    <span className="font-semibold text-xs">Payment account</span>
                                </div>
                                <div className="">
                                    <span className="text-xs float-right">FYX-D Account</span><br/><br/>
                                    <span className="text-xs float-right">K. {fullName}</span><br/>
                                    <span className="text-xs float-right">{Email}</span>
                                </div>
                            </div>

                            <hr className="mt-3 border-brown-500 border-2"/>
                            <div className="mt-3 grid grid-cols-2 gap-2">
                                <div>
                                    <span className="font-semibold text-xs">Purchase Amount</span><br/>
                                    <span className="font-semibold text-xs">Fixed interest</span><br/>
                                    <span className="font-semibold text-xs">Exercise Amount</span><br/>
                                    <span className="text-xxs">(Amount after interest paid)</span>
                                </div>
                                <div className="">
                                    <span className="text-xs float-right">{investAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} FYX-D</span><br/>
                                    <span className="text-xs float-right">5%</span><br/>
                                    <span className="text-xs float-right">{returnsAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} FYX-D</span>
                                </div>
                            </div>

                            <hr className="mt-3 border-brown-500 border-2"/>
                            {/* <div className="mt-3 grid grid-cols-2 gap-2">
                                <div>
                                    <span className="font-semibold text-sm">Commission Fee</span>
                                </div>
                                <div className="">
                                    <span className="text-sm float-right">No Fee</span>
                                </div>
                            </div> */}
                            
                            <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-t border-ggrey-200">
                                <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl bg-yyellow-500  text-black')} onClick={handleBackToHome}>Back to home</button> 
                            </div>
                        </div>
                        <img onClick={handleOpenModal} className="mx-auto mt-3" src={IconCancelOrder} alt="" />
                            <div style={{height: "12vh"}}>
                            </div>
                      </div>
                </Container>
                <Modal
                  isOpen={isOpen}
                  style={customStyles}
                >
                  <div style={{textAlign : "center"}}>
                      <b><span style={{fontSize : "1.2rem"}}>Cancel Order Confirmation</span></b>
                      <p style={{marginTop: "10px"}}>Are you sure you want to cancel your order? This action cannot be undone. Your transaction will be reverted.</p>
                      <div style={{marginTop: "16px"}}>
                        <button style={{width: "46%", display: "inline-block", background: "white", borderRadius: "12px", border: "1px solid black", flex: "none", color: "black", padding: "1rem", fontSize: "1.2rem"}} onClick={handleCancel}>Cancel</button>
                        <div style={{"width": "0.8rem", display: "inline-block"}}>
                        </div>
                        <button style={{width: "46%", display: "inline-block", background: "#FFC557", borderRadius: "12px", flex: "none", border: "0px", color: "black", padding: "1rem", fontSize: "1.2rem"}} onClick={handleCancelPlan}>Confirm</button>
                      </div>
                  </div>
                </Modal>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
