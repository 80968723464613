import IconWalletWhite from "../../images/icons/nav-wallet-icon-white.svg";
import IconWalletBlack from "../../images/icons/nav-wallet-icon-black.svg";
import IconPayWhite from "../../images/icons/nav-pay-icon-white.svg";
import IconPayBlack from "../../images/icons/nav-pay-icon-black.svg";
import IconHistoryWhite from "../../images/icons/nav-history-icon-white.svg";
import IconHistoryBlack from "../../images/icons/nav-history-icon-black.svg";
import IconMissionWhite from "../../images/icons/nav-mission-icon-white.svg";
import IconMissionBlack from "../../images/icons/nav-mission-icon-black.svg";
import IconDTGO from "../../images/icons/nav-dtgo-icon-color.svg";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
export const Navbar = (props) => {
  const analytics = getAnalytics();
  const { instance, accounts } = useMsal();

  let userName = "",
    Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    userName = name;
    Email = email;
  }
  const handleTabNavMissionClick = () => {
    logEvent(analytics, "Homepage: Missions button", {
      userId: name,
      visitation_count: "mission_mainpage_enter",
    });
  };

  return (
    <div className="navbar">
      <a href={"#/"}>
        <img
          className="inline w-6"
          alt=""
          src={props.path === "/" ? IconWalletBlack : IconWalletWhite}
        />
        <br />
        Wallet
      </a>
      <a href={"#/pay"}>
        <img
          className="inline w-6"
          alt=""
          src={props.path === "/pay" ? IconPayBlack : IconPayWhite}
        />
        <br />
        Pay
      </a>

      <a href={"#/history"}>
        <img
          className="inline w-6"
          alt=""
          src={props.path === "/history" ? IconHistoryBlack : IconHistoryWhite}
        />
        <br />
        History
      </a>
      <Link to={"/missionlanding/0"} onClick={handleTabNavMissionClick}>
        <img
          className="inline w-6"
          alt=""
          src={
            props.path === "/missionlanding/0"
              ? IconMissionBlack
              : IconMissionWhite
          }
        />
        <br />
        Mission
      </Link>
      {/* <a href={"#/info"} className={props.path === "/info" ? "active": ""}>
                    <img className="inline" alt="" src={IconDTGO}/><br/>
                    Info
                </a> */}
    </div>
  );
};
