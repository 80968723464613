import React from 'react'
import IconTransactionSuccess from '../images/icons/history-transaction-success.svg';
import IconTransactionFail from '../images/icons/history-transaction-fail.svg';
import IconTopup from '../images/icons/v2/topup.svg';
import IconKbankTopupFail from '../images/icons/v2/kbank-topup-fail.svg';

const BonusList = (props) => {
  const { item } = props;
  const { type, status, transaction_title } = item;

  const renderIcon = (type, status, transaction_title) => {
    switch(type) {
      case 1:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTransactionSuccess} /></>;
          default:
            return <><img className="" alt="" src={IconTransactionFail} /></>;
      }
      case 2:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTopup} /></>;
          default:
            return transaction_title === 'Topup, Kbank' ? <><img className="" alt="" src={IconKbankTopupFail} /></> : <><img className="" alt="" src={IconTransactionFail} /></>;
        }
      case 6:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTopup} /></>;
          default:
            return <><img className="" alt="" src={IconTransactionFail} /></>;
        }
      case 7:
        switch(status) {
          case 1:
            return <><img className="" alt="" src={IconTopup} /></>;
          default:
            return <><img className="" alt="" src={IconTransactionFail} /></>;
        }
      default:
        return <><img className="" alt="" src={IconTransactionFail} /></>;
    }
  }

  const renderStatus = (type, status, transaction_title) => {
    switch(type) {
      case 1:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Payment/Redeem</span></>;
          default:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
      }
      case 2:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Top-up</span></>;
          default:
            return transaction_title === 'Topup, Kbank' ? <><span className="ml-3" style={{"marginLeft": "12px"}}>Top-up: cancel</span></> : <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
        }
      case 6:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Distribute</span></>;
          default:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
        }
      case 7:
        switch(status) {
          case 1:
            return <><span className="ml-3" style={{"marginLeft": "12px"}}>Airdrop</span></>;
          default:
              return <><span className="ml-3" style={{"marginLeft": "12px"}}>Void</span></>;
        }
      default:
        return <><img className="" alt="" src={IconTransactionFail} /></>;
    }
  }

  return (
    <>
        {
          renderIcon(type, status, transaction_title)
        }
        <div>
        {
          renderStatus(type, status, transaction_title)
        }
            <div>
                <span className="history__amount ml-3" style={{"marginLeft": "12px"}}>
                    { (status !== 1 && status !== 4) && <strike>{item.total_amount.toLocaleString()}</strike> } 
                    { (status === 1 || status === 4) && <>{item.total_amount.toLocaleString()}</> }
                    &nbsp;FYXD
                </span>
                <span className="ml-3">
                    <small className="history__timestamp" style={{"marginLeft": "12px"}}>{item.created_at.replace("T", " ").replace(".000Z", "")}</small>
                </span>
            </div>
        </div>
    </>
  )
}

export default BonusList