import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import BonusList from '../components/BonusList';
import { Navbar } from './components/navbar';
import { useNavigate } from 'react-router-dom';
import IconFyxDBlue from '../images/icons/v3/fyxd-logo-blue.svg';

export default function UpcommingBonusPage() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [transactionList, setTransactionList] = useState([]);

    let Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
      // userName = name;
      Email = email;
    }

    const requestToken = async () => {
        await requestAccessToken();
    };
    
    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const getTransaction = () => {
        axios.post(`${baseURI}/famzmember/v1/fyxd/member/transaction`, {
            upn: Email
        }, {
            headers: {
              Authorization: 'Bearer ' + accessToken
            }
          })
          .then(function (response) {
            setTransactionList(response.data.data.transactions);
    
        })
          .catch(function (error) {
            console.log(error);
            requestToken();
        });
    };

    useEffect(() => {
        requestToken();
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0)
            getTransaction();
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container>
                    <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            <span onClick={goBack} className="float-left font-semibold text-xl cursor-pointer">{'<'}</span>
                            <span className="text-center text-black font-semibold text-xl">Upcoming FYX-D Bonus</span>
                        </div>
                      </nav>
                    <div className="mb-5">
                        <div className="mt-3 py-3 px-4 border">
                            <span className="text-md font-black">Pending FYX-D Bonus Information</span><br/><br/>
                            <span className="text-sm"><b>Remark:</b> FYX-D Bonus value will realize only if the <b>exercise date</b>(Unlock date) is reached. In the mean time you are unable to access Pending DAI-DEE.</span>
                        </div>
                        <div className="mt-3">
                            <span className="text-sm font-black inline-block">Total upcomming bonus</span>
                            <svg className="inline-block ml-2" width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 0.875C8.5293 0.875 11 3.3457 11 6.375C11 9.42578 8.5293 11.875 5.5 11.875C2.44922 11.875 0 9.42578 0 6.375C0 3.3457 2.44922 0.875 5.5 0.875ZM5.5 10.8438C7.94922 10.8438 9.96875 8.8457 9.96875 6.375C9.96875 3.92578 7.94922 1.90625 5.5 1.90625C3.0293 1.90625 1.03125 3.92578 1.03125 6.375C1.03125 8.8457 3.0293 10.8438 5.5 10.8438ZM6.35938 8.09375C6.63867 8.09375 6.875 8.33008 6.875 8.60938C6.875 8.91016 6.63867 9.125 6.35938 9.125H4.64062C4.33984 9.125 4.125 8.91016 4.125 8.60938C4.125 8.33008 4.33984 8.09375 4.64062 8.09375H4.98438V6.71875H4.8125C4.51172 6.71875 4.29688 6.50391 4.29688 6.20312C4.29688 5.92383 4.51172 5.6875 4.8125 5.6875H5.5C5.7793 5.6875 6.01562 5.92383 6.01562 6.20312V8.09375H6.35938ZM5.5 5C5.11328 5 4.8125 4.69922 4.8125 4.3125C4.8125 3.94727 5.11328 3.625 5.5 3.625C5.86523 3.625 6.1875 3.94727 6.1875 4.3125C6.1875 4.69922 5.86523 5 5.5 5Z" fill="#E8B34F"/>
                            </svg>
                            <div className="mt-2">
                                <img src={IconFyxDBlue} className="inline-block align-top" />
                                <span className="text-3xl inline-block font-black ml-2">
                                    75,500.00
                                </span>
                            </div>
                            <hr className="mt-3" />
                        </div>
                        {
                            transactionList.map((item) => (
                                <div className="mx-auto mt-3" key={item.id}>
                                    <div className="mx-auto history__detail">
                                        <BonusList item={item} />
                                    </div>
                                </div>
                            ))
                        }
                        {/* For Only Web App */}
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">
                            </div>
                        </div>
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">  
                            </div>
                        </div>
                        <div className="mx-auto mt-3">
                            <div className="mx-auto history__detail">
                            </div>
                        </div>
                        {/* For Only Web App */}
                    </div>
                    {
                        transactionList.length === 0 &&
                        <div style={{ margin: "0",
                            position: "absolute",
                            top: "45%",
                            left: "35%",
                            transform: "translateY(-50%)"}}>
                            <span className="history__timestamp">No Transaction</span>
                        </div>
                    }
            </Container>
                {/* Navbar */}
                <Navbar path="/history" />
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}