import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import ImageHighlight from "../images/highlight/coffeehighlight.png";
const HighlightCard = () => (
  <>
    <div className="mt-4 bg-white p-2 rounded-2xl overflow-hidden">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-2xl font-semibold text-black">Highlights</h2>
        <a href="#/highlight" className="text-brown-700 flex items-center ">
          View all
          <FontAwesomeIcon icon={faGreaterThan} className="ml-2 w-2" />
        </a>
      </div>
      <a href="#/mission/1">
        <div className="relative rounded-2xl overflow-hidden">
          <img
            src={ImageHighlight} // Replace with your image path
            alt="Coffee"
            className="w-full h-auto rounded-2xl "
          />
          <div className="absolute bottom-0    left-0 w-full bg-[#FFEED3] p-3.5 flex justify-between items-center rounded-b-2xl">
            <span className="text-brown-700">Join the mission now!</span>
            <button className="bg-[#FFC139] w-[32px] rounded-xl">
              <FontAwesomeIcon
                icon={faGreaterThan}
                style={{
                  transform: "translateY(5%)",
                }}
                className="py-2 w-2 justify-center"
              />
            </button>
          </div>
        </div>
      </a>
    </div>
  </>
);

export default HighlightCard;
