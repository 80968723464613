import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const CustomDateInput = ({ value, onClick, placeholder }) => (
  <div
    className="flex items-center  bg-white text-gray-700 font-semibold py-2 px-3 border border-gray-300 rounded-md shadow-sm cursor-pointer focus-within:ring-2 focus-within:ring-blue-500 cursor-pointer w-[150px] "
    onClick={onClick}
  >
    <input
      type="text"
      value={value || ""}
      readOnly
      placeholder={placeholder}
      className="flex-grow bg-transparent text-xs outline-none cursor-pointer"
    />
    <FontAwesomeIcon
      icon={faCalendarAlt}
      className=" absolute text-yellow-500 ml-24"
    />
  </div>
);
export default CustomDateInput;
