import { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import axios from "axios";
import ReactLoading from "react-loading";
import QRCode from "react-qr-code";
import Pusher from "pusher-js";
import Modal from "react-modal";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import Webcam from "react-webcam";
import { PNG } from "pngjs/browser";
import jsQR from "jsqr";
import { Buffer } from "buffer";
import IconMissionBlack from "../images/icons/nav-wallet-icon-black.svg";
import LogoDColor from "../images/logos/d-logo-color.svg";
import IconRefresh from "../images/icons/v2/refresh.svg";
import IconCongrat from "../images/icons/v4/redeem/icon-redeem-conglat.svg";
import IconSuccess from "../images/icons/v2/success.svg";
import LogoFyxdPay from "../images/logos/fyxd-pay-color.svg";
import LogoFyxdMyWallet from "../images/logos/fyxd-logo-header.png";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import IconFail from "../images/icons/v2/fail-redeem.svg";

import { showDigit } from "../utils/helpers";

import { useNavigate } from "react-router-dom";
import { Navbar } from "./components/navbar";
import useQR from "../hooks/useQR";
import { getCookie, setCookie } from "../utils/cookies";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    minHeight: "20vh",
    minWidth: "40vh",
  },
};

const redeemStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    minHeight: "30vh",
    borderRadius: "20px",
    minWidth: "90%",
    zIndex: "10000",
  },
  overlay: {
    background: `
      radial-gradient(circle at center, rgba(249, 238, 217, 0.5) 10%, transparent 20%),
      radial-gradient(circle at center, rgba(253, 224, 172, 0.5) 40%, transparent 40%),
      radial-gradient(circle at center, rgba(250, 230, 186, 0.5) 60%, transparent 60%),
      radial-gradient(circle at center, rgba(255, 238, 170, 0.5) 80%, transparent 80%),
      radial-gradient(circle at center, rgba(249, 238, 217, 0.5) 100%, transparent 100%)
    `,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

const videoConstraints = {
  facingMode: "environment",
};

export default function PayPage() {
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const baseURI = process.env.REACT_APP_BASE_URI;
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [accessToken, setAccessToken] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);
  const [load, setLoad] = useState(true);
  const [barcodeInfo, setBarcodeInfo] = useState("");
  const [availablebalance, setAvailablebalance] = useState(0.0);
  const [QRData, setQRData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [missions, setMissions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [CamStatus2, setCamStatus2] = useState("");

  const { QR, saveQR } = useQR();

  const webcamRef = useRef(null);

  let Email = "";
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    Email = email;
  }

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const requestToken = async () => {
    await requestAccessToken();
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getMission = async () => {
    setLoad(true);
    console.log("getMission");
    try {
      const res = await axios.get(
        `${baseURI}/famzmember3/v1/fyxd/user/missionreward`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      setMissions(res.data.data.mission);
      console.log(res.data.data.mission[0].progress);
      if (res.data.data.mission[0].progress === 10) {
        openModal5();
      }
      setLoad(false);
    } catch (error) {
      console.error(error);
    }
  };
  const redeemMission = async () => {
    console.log("redeemMission");
    try {
      const res = await axios.post(
        `${baseURI}/famzmember3/v1/fyxd/user/redeemmissionreward`,
        {
          mission_id: 1,
        },
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      );
      console.log(res.data);
      getMission();
    } catch (error) {
      console.error(error);
    }
  };
  const capture = () => {
    // setCamStatus2('start capture...');
    // console.log('webcamRef: ', webcamRef);
    if (webcamRef.current !== null) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const imageBuffer = Buffer.from(
          imageSrc.slice("data:image/png;base64,".length),
          "base64"
        );
        const png = PNG.sync.read(imageBuffer);
        const imageData = jsQR(
          Uint8ClampedArray.from(png.data),
          png.width,
          png.height
        );
        if (imageData) {
          setQRData(imageData.data);
        }
      }
    }
  };

  const getBarcodeInfo = () => {
    setLoad(true);
    axios
      .post(
        `${baseURI}/famzmember/v1/fyxd/member/barcodeinfo`,
        {
          upn: Email,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(function (response) {
        // console.log(response);

        setAvailablebalance(response.data.data.fyxd);
        setBarcodeInfo(response.data.data.barcode);
        setLoad(false);
      })
      .catch(function (error) {
        console.log(error);
        requestToken();
      });
  };

  const toggleModal2 = () => {
    setIsOpen2(!isOpen2);
  };

  const validateQRData = (data) => {
    const decodedData = atob(data);
    const decodedDataJson = JSON.parse(decodedData);

    if (
      decodedDataJson.endpoint === undefined ||
      decodedDataJson.endpoint.length === 0 ||
      decodedDataJson.qrdata === undefined ||
      decodedDataJson.qrdata.length === 0
    ) {
      return false;
    }

    return true;
  };

  const callFyxDGiveaway = async (decodedDataJson) => {
    try {
      const res = await axios.post(
        decodedDataJson.endpoint,
        {
          qr_data: QRData,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      navigate("/");
    } catch (error) {
      console.error(error);
      setErrorMessage(error.response.data.message);
      // alert(error)
      setIsOpen3(true);
    }
  };

  const callPay = async (decodedDataJson) => {
    try {
      navigate("/scanpay");
    } catch (error) {
      console.error(error);
    }
  };

  const confirmModal2 = async () => {
    closeModal2();
    // TODO : add loading
    const decodedData = atob(QRData);
    const decodedDataJson = JSON.parse(decodedData);

    try {
      const res = await axios.post(
        decodedDataJson.endpoint,
        {
          qr_data: QRData,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      navigate("/");
    } catch (error) {
      console.error(error);
      setErrorMessage(error.response.data.message);
      // alert(error)
      setIsOpen3(true);
    }
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  const closeModal3 = () => {
    setIsOpen3(false);
    navigate("/");
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleModal4 = () => {
    setIsOpen4(!isOpen4);
  };
  const closeModal4 = async () => {
    navigate("/mission/1");
  };
  const openModal5 = () => {
    logEvent(analytics, "Redeem reward button", {
      userId: Email,
      visitation_count: "mission1_redeemreward_count",
    });
    setIsOpen5(true);
  };

  const closeModal5 = () => {
    setIsOpen5(false);
  };

  const navigateRedeem = async () => {
    redeemMission();
    await getMission();
    navigate("/missionlanding/1");
  };
  const closeModal = () => {
    setIsOpen(false);
    navigate("/");
  };

  const subscribePusher = () => {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;

    var pusher = new Pusher("1126eef8adf98aaa83d9", {
      cluster: "ap1",
    });

    var channel = pusher.subscribe(Email);
    channel.bind("transaction-notify", function (data) {
      if (data.status == "stamp") {
        toggleModal4();
      } else {
        toggleModal();
      }
    });
  };
  const stampMission = async () => {
    requestToken();
    console.log("stampMission");
    try {
      const res = await axios.post(
        `${baseURI}/famzmember/v1/fyxd/user/createmockmissiontransaction`,
        null,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
    } catch (error) {
      console.error(error);
    }
  };
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (webcamRef === null || webcamRef.current === null) {
      // window.location.reload(false);
    }
  }, [webcamRef]);

  useEffect(() => {
    sleep(2000).then(() => {
      if (webcamRef !== null && webcamRef.current !== null) {
        const intervalId = setInterval(capture, 100); // Check for QR code every 100ms
        return () => clearInterval(intervalId);
      }
    });
  }, []);

  useEffect(() => {
    console.log(QRData);
    if (QRData !== "" && validateQRData(QRData)) {
      const decodedData = atob(QRData);
      const decodedDataJson = JSON.parse(decodedData);
      saveQR(decodedDataJson.qrdata);

      if (
        decodedDataJson.endpoint ===
        "https://xa7auw07u3.execute-api.ap-southeast-1.amazonaws.com"
      ) {
        navigate("/scanpay");
      } else {
        setIsOpen2(true);
      }
    } else {
      setIsOpen2(false);
    }
    // eslint-disable-next-line
  }, [QRData]);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      if (Email.length > 0 && accessToken.length > 0) {
        getBarcodeInfo();
      }
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    if (!runTimer) {
      setRunTimer(!runTimer);
    }

    return () => clearInterval(timerId);
    // eslint-disable-next-line
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      // console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  useEffect(() => {
    if (Email.length > 0 && accessToken.length > 0) {
      // getBalance();
      getBarcodeInfo();
      subscribePusher();
      getMission();
    }
    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div style={{ width: "100vw", height: "105vh" }}>
          <div className="mt-3 mb-3 text-center">
            <h1 className="text-xl">Scan & Pay</h1>
          </div>
          <Tabs style={{ width: "100%" }}>
            <TabList style={{ width: "100%" }}>
              <Tab>MY QR</Tab>
              <Tab>SCAN QR</Tab>
            </TabList>
            <TabPanel>
              <div className="mt-3 mb-3 text-center">
                <img
                  src={LogoFyxdMyWallet}
                  alt="Logo"
                  style={{ width: "12.5vh" }}
                  className="ml-2 max-w-[40%] inline-block"
                />
              </div>

              <Container className="mt-4 mb-4">
                <div className="text-center">
                  {load ? (
                    <ReactLoading
                      className="mx-auto"
                      type="spokes"
                      color="#000"
                    />
                  ) : (
                    <div className="bg-white mx-auto w-[70%] rounded-xl qr-card">
                      <QRCode className="mx-auto w-[60%]" value={barcodeInfo} />
                    </div>
                  )}
                </div>
                <div
                  className="text-center mb-4"
                  style={{ marginTop: "0.5rem" }}
                >
                  <span>
                    {minutes}:{seconds}
                  </span>
                  <br />
                  <button
                    className="refresh-button "
                    style={{ marginLeft: "0.5rem" }}
                    onClick={() => {
                      getBarcodeInfo();
                      setCountDown(60 * 5);
                    }}
                  >
                    <img className="inline-block" alt="" src={IconRefresh} />

                    <span
                      className="inline-block"
                      style={{ marginLeft: "0.2rem" }}
                    >
                      Refresh QR
                    </span>
                  </button>
                </div>
                <div className="mt-3 py-2.5  rounded-2xl shadow-card mx-2 ">
                  <div className="flex flex-row justify-between items-center ">
                    <div className="flex flex-row">
                      <img
                        className="ml-2 inline w-6"
                        alt=""
                        src={IconMissionBlack}
                      />
                      <div className="ml-2">FYX-D Wallet</div>
                    </div>
                    <div className="flex flex-row">
                      <div className="float-right mr-2">
                        {" "}
                        <span className="text-brown-800 text-xl mr-2">
                          {parseFloat(
                            availablebalance.toString()
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                        <img
                          className="my-auto inline mr-2"
                          alt=""
                          src={LogoDColor}
                          style={{
                            width: "4.5vh",
                            transform: "translateY(-10%)",
                            color: "#4E3721",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-3">
                  <div className="text-center">
                    <b>
                      <span className="mywallet-card__normal-text text-black">
                        FYX-D Wallet Balance
                      </span>
                    </b>
                    <br />
                  </div>
                  <div className="text-center">
                    <img
                      className="inline-block"
                      alt=""
                      src={LogoDColor}
                      style={{ width: "6vh", transform: "translateY(-30%)" }}
                    />
                    <b>
                      <span
                        style={{
                          color: "#B58C3E",
                          marginLeft: "0.5rem",
                          fontSize: "2.5rem",
                        }}
                      >
                        {parseInt(
                          availablebalance.toString().split(".")[0]
                        ).toLocaleString()}
                        .
                      </span>
                      <span
                        className="mywallet-card__name-text"
                        style={{ color: "#B58C3E" }}
                      >
                        {availablebalance === undefined
                          ? "0"
                          : showDigit(availablebalance)}
                      </span>
                    </b>
                  </div>
                </div> */}
              </Container>
              <Modal
                isOpen={isOpen5}
                onRequestClose={closeModal5}
                shouldCloseOnOverlayClick={false} // ห้ามปิดเมื่อคลิกด้านนอก
                shouldCloseOnEsc={false}
                style={redeemStyle}
              >
                <div className="text-center">
                  <div className="flex justify-end">
                    {/* <img
                src={ClosedCircle}
                className="cross-icon w-[10%] float-right"
                onClick={closeModal3}
              ></img> */}
                  </div>
                  <div className="mt-4 flex flex-col ">
                    <img
                      className="mx-auto"
                      src={IconCongrat}
                      alt=""
                      style={{ width: "30vh" }}
                    />
                    <span
                      className="mt-5 text-yellow-500 "
                      style={{ fontSize: "1.2rem" }}
                    >
                      CONGRATULATIONS
                    </span>
                    <hr className="mt-3 mb-3 congrat-line " />
                    <span className="text-xs text-ggrey-400 mb-2">
                      You have received
                    </span>
                    <span className="text-sm  font-bold text-yellow-900 mb-2">
                      1 Free coffee at Dilly Dally Kaffe{" "}
                    </span>
                    <button
                      className="p-3 w-[50%] rounded-3xl mx-auto mb-3 mt-3 cursor-pointer bg-yellow-400"
                      onClick={navigateRedeem}
                    >
                      Redeem Now!
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                style={customStyles}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    className="mx-auto"
                    src={IconSuccess}
                    alt=""
                    style={{ width: "8vh" }}
                  />
                  <br />
                  <b>
                    <span className="mt-5" style={{ fontSize: "1.2rem" }}>
                      FYX-D Point Redemption
                      <br />
                      successful
                    </span>
                  </b>
                  <button
                    className="mt-3"
                    style={{
                      background: "#FFC557",
                      borderRadius: "12px",
                      flex: "none",
                      width: "100%",
                      border: "0px",
                      color: "white",
                      padding: "1rem",
                    }}
                    onClick={closeModal}
                  >
                    OK
                  </button>
                </div>
              </Modal>
              <Modal
                isOpen={isOpen2}
                onRequestClose={toggleModal2}
                style={customStyles}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    className="mx-auto"
                    src={IconSuccess}
                    alt=""
                    style={{ width: "8vh" }}
                  />
                  <br />
                  <b>
                    <span className="mt-5" style={{ fontSize: "1.2rem" }}>
                      FYX-D Point Redemption
                      <br />
                      successful
                    </span>
                  </b>
                  <button
                    className="mt-3"
                    style={{
                      background: "#FFC557",
                      borderRadius: "12px",
                      flex: "none",
                      width: "100%",
                      border: "0px",
                      color: "white",
                      padding: "1rem",
                    }}
                    onClick={closeModal2}
                  >
                    OK
                  </button>
                </div>
              </Modal>
              <Modal
                isOpen={isOpen4}
                onRequestClose={toggleModal4}
                style={customStyles}
              >
                <div className="text-center">
                  <div className="flex flex-col ">
                    <img
                      className="mx-auto"
                      src={IconSuccess}
                      alt=""
                      style={{ width: "8vh" }}
                    />
                    <span
                      className="mt-3 text-yellow-500 "
                      style={{ fontSize: "1.2rem" }}
                    >
                      Payment Successful
                    </span>
                    <span
                      className="mt-3 font-bold"
                      style={{ fontSize: "1.2rem" }}
                    >
                      Congratulations!
                      <br />
                      You received 1 E-Stamp
                      <br />
                    </span>
                    <button
                      className="mt-3"
                      style={{
                        background: "#FFC557",
                        borderRadius: "12px",
                        flex: "none",
                        width: "100%",
                        border: "0px",
                        color: "white",
                        padding: "1rem",
                      }}
                      onClick={closeModal4}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </Modal>
            </TabPanel>
            <TabPanel>
              <span>{CamStatus2}</span>
              <Webcam
                audio={false}
                ref={webcamRef}
                style={{ width: "100%" }}
                screenshotFormat="image/png"
                screenshotQuality={0.85}
                videoConstraints={videoConstraints}
              ></Webcam>
              <Modal
                isOpen={isOpen2}
                onRequestClose={toggleModal2}
                style={customStyles}
              >
                <div style={{ textAlign: "center" }}>
                  {/* <img src={IconSuccess} alt="" style={{width : "8vh"}}/><br/><br/> */}
                  <b>
                    <span className="mt-5" style={{ fontSize: "1.2rem" }}>
                      Join The Campaign
                    </span>
                  </b>
                  <button
                    className="mt-3"
                    style={{
                      background: "#FFC557",
                      borderRadius: "12px",
                      flex: "none",
                      width: "100%",
                      border: "0px",
                      color: "white",
                      padding: "1rem",
                    }}
                    onClick={confirmModal2}
                  >
                    Confirm
                  </button>
                  <button
                    className="mt-3"
                    style={{
                      background: "transperent",
                      borderRadius: "12px",
                      borderColor: "black",
                      flex: "none",
                      width: "100%",
                      border: "2px",
                      color: "black",
                      padding: "1rem",
                    }}
                    onClick={closeModal2}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
              <Modal
                isOpen={isOpen3}
                onRequestClose={closeModal3}
                style={customStyles}
              >
                <div style={{ textAlign: "center" }}>
                  <img
                    className="mx-auto"
                    src={IconFail}
                    alt=""
                    style={{ width: "8vh" }}
                  />
                  <br />
                  <br />
                  <b>
                    <span
                      className="mt-5"
                      style={{ fontSize: "1.2rem", color: "#A04747" }}
                    >
                      Fail
                    </span>
                  </b>
                  <br />
                  <b>
                    <span style={{ color: "#A04747" }}>{errorMessage}</span>
                  </b>
                  <button
                    className="mt-3"
                    style={{
                      background: "#FFC557",
                      borderRadius: "12px",
                      flex: "none",
                      width: "100%",
                      border: "0px",
                      color: "white",
                      padding: "1rem",
                    }}
                    onClick={closeModal3}
                  >
                    OK
                  </button>
                </div>
              </Modal>
            </TabPanel>
          </Tabs>
          {/* Navbar */}
          <Navbar path="/pay" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
