import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import axios from "axios";
import IconMoney from "../images/icons/money-icon.svg";
import IconPending from "../images/icons/pending-icon.svg";
import LogoDTGOColor from "../images/logos/dtgo-logo-color.svg";
import IconBonus from "../images/icons/v3/bonus-icon.svg";
import LogoDColor from "../images/icons/v4/home/icon-38.svg";
import LogoHiddenOn from "../images/icons/v4/home/icon-39.svg";
import LogoHiddenOff from "../images/icons/v4/home/icon-40.png";
import LogoKey from "../images/icons/v4/home/icon-37.svg";
// import LogoFyxdMain from '../images/logos/fyxd-main-color.svg';
import LogoFyxdMyWallet from "../images/logos/fyxd-logo-header.png";
import IconLanguageThai from "../images/logos/thai.svg";
import IconTopUp from "../images/icons/v4/icon-topup.svg";
import IconMission from "../images/icons/v4/icon-mission.svg";
import IconTransfer from "../images/icons/v4/icon-transfer.svg";
import IconPay from "../images/icons/v4/icon-pay.svg";
import IconPortfolio from "../images/icons/v4/icon-portfolio.svg";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import IconTextBlock from "../components/IconTextBlock";
import HighlightCard from "../components/HighlightCard";
import PromotionCard from "../components/PromotionCard";
import { loginRequest } from "../authConfig";
import LogoDaidee2 from "../images/logos/daidee-2.svg";
import IconInfo from "../images/icons/v3/info-icon.svg";
import { Navbar } from "./components/navbar";
import Error from "./error-page";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import IconSetting from "../images/icons/v3/setting-icon.svg";
import { getAnalytics, logEvent, set } from "firebase/analytics";
export default function NewDesignPage() {
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;

  const [accessToken, setAccessToken] = useState("");

  const [availablebalance, setAvailablebalance] = useState(0.0);
  const [bonusbalance, setBonusbalance] = useState(0.0);
  const [topupbalance, setTopupbalance] = useState(0.0);
  const [activeStatus, setActiveStatus] = useState("");
  const [hiddenTotalbalance, setHiddenTotal] = useState(false);
  const currentDate = new Date();

  let userName = "",
    Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    userName = name;
    Email = email;
  }

  const onHiddenOn = () => {
    setHiddenTotal(true);
  };
  const onHiddenOff = () => {
    setHiddenTotal(false);
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const requestToken = async () => {
    await requestAccessToken();
  };

  const getUserProfile = () => {
    console.log(accessToken);
    axios
      .post(
        `${baseURI}/famzmember/v1/fyxd/member/dashboard`,
        {
          upn: Email,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(function (response) {
        const { member } = response.data.data;
        let fyxd = 0;
        if (member !== undefined && member.fyxd !== undefined)
          fyxd = member.fyxd;
        let bonus = member.bonus_fyxd; // already add airdrop
        let airdrop = member.airdrop_fyxd;

        setAvailablebalance(fyxd + bonus);
        setTopupbalance(fyxd);
        setBonusbalance(bonus);
      })
      .catch(function (error) {
        console.log(error);
        requestToken();
      });
  };

  const getUserActive = async () => {
    // 0=inactive
    // 1=active
    // 2=suspended
    // 3=terminated
    try {
      const res = await axios.get(
        `${baseURI}/famzmember3/v1/fyxd/user/status`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      const { data } = res.data;
      console.log("Parsed data:", data);

      console.log("Active status:", data.active);
      switch (data.status) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          navigate("/suspends");
          break;
        case 3:
          break;
        default:
          // Handle unknown status
          break;
      }
    } catch (error) {
      console.error(error);
      requestToken();
    }
  };

  const handleOpenInvest = () => {
    navigate("/investment");
  };

  const handleOpenUpcomming = () => {
    navigate("/upcommingbonus");
  };

  useEffect(() => {
    if (Email.length > 0 && accessToken.length > 0) getUserProfile();
    getUserActive();

    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div style={{ width: "100vw", height: "100vh" }}>
          <Container style={{ height: "70vh", paddingTop: "14px" }}>
            <div className="mb-8 flex items-center justify-between">
              <img
                src={LogoFyxdMyWallet}
                alt="Logo"
                style={{ width: "8.5vh" }}
                className="ml-2 max-w-[20%] inline-block"
              />
              {/* <a
                className="py-1 flex items-center bg-[#E9E9E9] rounded-2xl hover:bg-gray-300"
                href="#/info"
              >
                <img
                  src={IconLanguageThai}
                  alt="Language Icon"
                  className="w-5 h-auto pl-1"
                />
                <span className="mx-1.5 text-xs text-[#000000]">TH</span>
              </a> */}

              <a href="#/info">
                <img src={IconSetting} alt="" className="float-right" />
              </a>
              {/* <h2 className="heading--main inline-block"> | DTGO Rewards</h2> */}
            </div>
            <div className="relative py-1 mb-4 mt-4  rounded-2xl shadow-card ">
              <div className="flex  mt-2 mx-2.5">
                <div
                  style={{
                    fontSize: "12px",
                  }}
                  className="flex-none w-auto mx-1 text-sm sm:text-base lg:text-lg font-bold text-brown-800"
                >
                  FYX-D Wallet
                </div>
                <div className="w-full">
                  <div className="flex flex-row justify-end space-x-1 ">
                    <button className="button-topup  text-center ">
                      <a
                        href="#"
                        className="flex items-center "
                        style={{ textDecoration: "none" }}
                      >
                        <div className="flex flex-col ">
                          <div className="flex flex-row justify-center">
                            <img
                              src={LogoKey}
                              alt="Key Icon"
                              className="mr-0.5"
                              style={{
                                width: "1rem",
                                height: "1rem",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "10px",
                              }}
                              className="text-brown-700 font-semibold text-xs sm:text-sm md:text-base lg:text-lg"
                            >
                              Bonus
                            </span>
                          </div>
                          <button
                            style={{ borderRadius: "8px" }}
                            className="bg-[#FFFFFF] pl-3 pr-3 mt-0.5"
                          >
                            <span className="text-brown-800 font-semibold ml-1 text-xs sm:text-sm md:text-base lg:text-lg mr-1">
                              {bonusbalance.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </span>
                          </button>
                        </div>
                      </a>
                    </button>
                    <button className="button-topup text-center ">
                      <a
                        href="#/topup"
                        className="flex items-center"
                        style={{ textDecoration: "none" }}
                      >
                        <div className="flex flex-col justify-center ">
                          <span
                            style={{
                              transform: "translateY(-20%)",
                              fontSize: "9px",
                            }}
                            className="mt-1 text-brown-700  w-full font-semibold text-xs"
                          >
                            Top-Up
                          </span>
                          <button
                            style={{ borderRadius: "8px" }}
                            className="bg-[#FFFFFF] pl-3 pr-3 "
                          >
                            <span className="text-brown-800 font-semibold ml-1 text-xs sm:text-sm md:text-base lg:text-lg">
                              {topupbalance.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </span>
                          </button>
                        </div>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
              <hr className="card__line" />
              <div className="mx-2.5 ">
                <div className="ml-0.5 mb-3 font-bold text-brown-800 text-sm">
                  Total Balance
                </div>
                <div className="mx-3" style={{ display: "inline-block" }}>
                  <img
                    className="my-auto inline "
                    alt=""
                    src={LogoDColor}
                    style={{
                      width: "4.5vh",
                      transform: "translateY(-30%)",
                      color: "#FF3333",
                    }}
                  />
                </div>
                <span style={{ display: "inline-block" }}>
                  {hiddenTotalbalance ? (
                    <>
                      <span className="text-seq text-2xl md:text-5xl font-bold wallet-card_color text-brown-800">
                        {parseFloat(availablebalance.toString()).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-2xl sm:text-3xl md:text-5xl font-bold wallet-card_color text-brown-800">
                        {parseFloat(availablebalance.toString()).toLocaleString(
                          undefined,
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </span>
                    </>
                  )}
                  {/* <span className="mywallet-card__name-text">{availablebalance === undefined ? '0' : showDigit(availablebalance)}</span> */}
                </span>
                <button
                  onClick={hiddenTotalbalance ? onHiddenOff : onHiddenOn}
                  className="my-auto inline float-right mr-2"
                >
                  <img
                    className="my-auto inline   mr-auto"
                    alt=""
                    src={hiddenTotalbalance ? LogoHiddenOff : LogoHiddenOn}
                    style={{
                      width: "2.5vh",
                      transform: "translateY(-30%)",
                      color: "#FF3333",
                    }}
                  />
                </button>
                {/* <img
                  className="my-auto inline float-right mr-2"
                  alt=""
                  src={LogoHiddenOn}
                  style={{
                    width: "2.5vh",
                    transform: "translateY(-30%)",
                    color: "#FF3333",
                  }}
                /> */}
              </div>
            </div>

            <div className="flex justify-around mt-4 ">
              <IconTextBlock
                href={"#/topup"}
                src={IconTopUp}
                alt="Top Up"
                text="Top Up"
              />
              <IconTextBlock
                href="#/missionlanding/0"
                src={IconMission}
                alt="Top Up"
                text="Mission"
              />
              <IconTextBlock
                href={"#/pay"}
                src={IconPay}
                alt="Top Up"
                text="Pay"
              />
              <IconTextBlock
                href={"#/portfolio"}
                src={IconPortfolio}
                alt="Top Up"
                text="Portfolio"
              />
            </div>
            <HighlightCard />

            <div className="mywallet-card">
              <div className="px-1"></div>
            </div>
            {/* 
                        <div className="mt-3 p-2 bg-yyelow-200 border-yyellow-700 border-1 rounded-md">
                            <span className="text-md mywallet-card__large-text font-black inline-block">
                                DaiDee 2023 Bonus
                            </span>
                            <img src={IconBonus} alt="" className="float-right inline-block"/>
                            <br/>
                            <span className="text-xs mt-2">
                                <span>Dear FAMz, you will receive DaiDee 2023 bonus if...</span> 
                                <br/><br/>
                                <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&#183;You receive 35,000 DAI-DEE(equal to 35,000 FYX-D) on date/year<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&#183;DAI-DEE will be lock for 1 year, whic you will gain interest of total 17,500 DAI-DEE<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&#183;In total, you will gain 52,500 DAI-DEE in total after date/year<br/>
                                </p>  
                            </span>
                        </div>
                        */}

            <div style={{ height: "11vh" }}></div>
          </Container>

          {/* Navbar */}
          <Navbar path="/" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
