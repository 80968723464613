import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import LogoDColor from '../images/logos/d-logo-color-flat.svg';
import ClosedCircle from "../images/icons/v2/closed-circle.svg";

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

// Sample app imports
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import { animateScroll as scroll, scrollSpy } from 'react-scroll';
import useQR from '../hooks/useQR';

export default function ScanPayPage() {
    const navigate = useNavigate();
    const { QR, savePayAmount } = useQR();

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [availablebalance, setAvailablebalance] = useState("0.00");
    const [payAmount, setPayAmount] = useState("");
    const [merchantName, setMerchantName] = useState("");
    const [merchantId, setMerchantId] = useState("");
    const [reviewBtnDisabled, setReviewBtnDisabled] = useState(true);
    const [isInputError, setIsInputError] = useState(false);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const goBack = () => {
        navigate('/');
    };

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const getUserProfile = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember/v1/fyxd/member/dashboard`, 
            { "upn": Email }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
            setAvailablebalance(res.data.data.member.fyxd + res.data.data.member.bonus_fyxd + res.data.data.member.pending_bonus_fyxd);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const getMerchantData = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/merchantqr`, 
            { "qr": QR }, { headers: { Authorization: 'Bearer ' + accessToken } });
            console.log(res.data.data)
            //setMerchant(res.data.data.merchant);
            setMerchantName(res.data.data.merchant.name);
            setMerchantId(res.data.data.merchant.merchant_id);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const review = async () => {
        navigate('/reviewscanpay');
    };

    const resetValue = async () => {
        setPayAmount("");
        savePayAmount("")
    };

    const handlePayAmountChange = async (data) => {
        let inputValue = data.replace(/[^\d.]/g, '');
        console.log(data);
        console.log(inputValue);

        setIsInputError(false);
        if(inputValue > availablebalance || inputValue < 1 || inputValue === 0)
            setReviewBtnDisabled(true);
        else
            setReviewBtnDisabled(false);
        if(inputValue > availablebalance)
            setIsInputError(true);

        // Determine if the user is typing in the decimal part
        const isTypingDecimal = inputValue.includes('.') && inputValue.split('.')[1].length < 3;

        // Convert the string to a number
        let numberValue = inputValue ? parseFloat(inputValue) : 0;

        // Format the value, conditionally applying decimal places
        let formattedValue;
        if (isTypingDecimal) {
            // Avoid formatting to keep the decimals as typed
            formattedValue = inputValue;
        } else {
            // Apply formatting with two decimal places
            formattedValue = new Intl.NumberFormat('en-US', {
                style: 'decimal',
                // minimumFractionDigits: 2,
                // maximumFractionDigits: 2
            }).format(numberValue);
        }

        setPayAmount(formattedValue);
        savePayAmount(inputValue);
    };

    const scrollToBottom = () => {
        scroll.scrollToBottom();
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0) {
            getUserProfile();
            getMerchantData();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);


    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "100%"}}>
                      <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            <span onClick={goBack} className="float-left font-semibold text-xl cursor-pointer">{'<'}</span>
                            <span className="text-center text-black font-semibold text-xl">Scan & Pay</span>
                        </div>
                      </nav>
                      <div className="mt-3">
                        <span className="text-black text-xl font-semibold">FYX-D Total Balance</span>
                      </div>
                      <div className="h-full">
                        <div className="h-full">
                        <div className="mt-3">
                            <div className="">
                                <img className="my-auto inline" alt="" src={LogoDColor} style={{width: "5.5vh", transform: "translateY(-30%)"}} />
                                <span className="mywallet-card-text">
                                <span className="ml-3 text-5xl font-bold">{availablebalance.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits: 2})}</span>
                                </span>
                            </div>
                        </div>

                        <div>
                            <div>
                                <hr className="mt-3 mb-3" />
                                <span className="font-bold">Pay To</span>
                                <div className="mt-2 p-4 bg-gray-100 w-full rounded-2xl py-4" >
                                    <span className="font-bold">FYX-D Merchant</span><br/>
                                    <span>{merchantName}</span>
                                </div>
                            </div>
                            <div className="mt-3">
                                <span className="font-bold">Merchant ID</span><br/>
                                <span>{merchantId}</span>
                                <hr className="mt-3 mb-3" />
                            </div>
                        </div>
                        
                      <div className="mt-3">
                        <span className="font-bold">Payment amount</span>
                        {/* <input className="mt-3 w-full border border-gray-200 h-3 px-2 py-4 rounded-sm" placeholder="100.00"></input> */}
                        <div className="topup_amount-input-container py-2 px-2">
                            <input 
                                type="string" 
                                value={payAmount.toLocaleString()} 
                                onChange={(e) => handlePayAmountChange(e.target.value) }
                                onFocus={scrollToBottom} 
                                className="number-input"
                                placeholder="Enter amount here"
                            />
                            <img src={ClosedCircle} className="cross-icon" onClick={resetValue}></img>
                        </div>
                        { isInputError && <span className="text-xs text-red-500">Payment amount is exceed FYX-D total balance.</span> }
                      </div>
                      <button className={clsx('mt-4 px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer',  reviewBtnDisabled  && 'bg-ggrey-200 text-neutral-500 disabled', !reviewBtnDisabled && 'bg-yyellow-500  text-black')} disabled={reviewBtnDisabled} onClick={review}>Review</button>
                      </div>
                      {/* <div className="px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-ggrey-200">
                        <button className={clsx('mt-3 px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer',  reviewBtnDisabled  && 'bg-ggrey-200 text-neutral-500 disabled', !reviewBtnDisabled && 'bg-yyellow-500  text-black')} disabled={reviewBtnDisabled} onClick={review}>Review</button> 
                      </div> */}
                    </div>
                </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}