import React from "react";
import IconStar from "../../../images/icons/v4/missiondetail/star_icon.svg";
import IconCup from "../../../images/icons/v4/missiondetail/cup_icon.svg";
import IconTrophy from "../../../images/icons/v4/missiondetail/trophy_icon.svg";
import IconTrophyW from "../../../images/icons/v4/missiondetail/trophy_w_icon.svg";
import { twMerge } from "tailwind-merge";

const Stamp = ({ totalTasks, tasksCompleted, status }) => {
  const renderTaskImages = () => {
    const images = [];
    let className = "bg-yellow-400";

    for (let i = 0; i < totalTasks; i++) {
      let imageSrc = IconStar; // Default image
      if (i >= tasksCompleted) {
        imageSrc = IconCup; // Success transaction
        className = "border-black border-1";
      }
      if (i === totalTasks - 1) {
        imageSrc = IconTrophy; // Trophy for finishing all tasks
        className = "border-black border-1";
      }
      if (tasksCompleted === totalTasks && i === totalTasks - 1) {
        imageSrc = IconTrophyW; // Trophy for finishing all tasks
        className = "bg-yellow-400";
      }
      images.push(
        <div
          className={twMerge(
            "relative p-4 w-20 h-20 rounded-full cursor-pointer",
            className,
            status === "redeemed" ? "filter grayscale" : ""
          )}
          key={i}
        >
          <img
            src={imageSrc}
            className={twMerge(
              "absolute top-5 left-0 w-20 h-10  ",
              status === "redeemed" ? "filter grayscale" : ""
            )}
            alt={`Task ${i + 1}`}
          />
          {i !== totalTasks - 1 && (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 text-md text-white">
              {i + 1}
            </div>
          )}
        </div>
      );
    }
    return images;
  };

  return <>{renderTaskImages()}</>;
};

export default Stamp;
