import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import axios from "axios";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import LogoFyxdHistory from "../images/logos/fyxd-history-color.svg";
import DatePicker from "react-datepicker";
import LogoFyxdMyWallet from "../images/logos/fyxd-logo-header.png";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import "react-datepicker/dist/react-datepicker.css";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import TransactionList from "../components/TransactionList";
import { Navbar } from "./components/navbar";
import { useNavigate } from "react-router-dom";
import CustomDateInput from "../components/InputCalendarButton";

export default function HistoryPage() {
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [accessToken, setAccessToken] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  let Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    // userName = name;
    Email = email;
  }

  const requestToken = async () => {
    await requestAccessToken();
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getTransaction = () => {
    axios
      .post(
        `${baseURI}/famzmember/v1/fyxd/member/transaction`,
        {
          upn: Email,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      )
      .then(function (response) {
        setTransactionList(response.data.data.transactions);
        setFilteredTransactions(response.data.data.transactions);
      })
      .catch(function (error) {
        console.log(error);
        requestToken();
      });
  };

  const handlePlanReceipt = (id, type, status) => {
    if (type === 3) navigate(`/planreceipt/${id}`);
    else if (type === 1 && status === 1) navigate(`/successscanpay/${id}`);
  };

  useEffect(() => {
    requestToken();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Email.length > 0 && accessToken.length > 0) getTransaction();
    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  useEffect(() => {
    if (startDate || endDate) {
      const filtered = transactionList.filter((transaction) => {
        const transactionDate = new Date(transaction.created_at);

        // Function to set time to 00:00:00 for date comparisons
        const setToMidnight = (date) => {
          return new Date(date.getFullYear(), date.getMonth(), date.getDate());
        };

        const normalizedTransactionDate = setToMidnight(transactionDate);
        const normalizedStartDate = startDate ? setToMidnight(startDate) : null;
        const normalizedEndDate = endDate ? setToMidnight(endDate) : null;

        // Check if transaction date is within the start and end date range
        const isAfterStartDate = normalizedStartDate
          ? normalizedTransactionDate >= normalizedStartDate
          : true;
        const isBeforeEndDate = normalizedEndDate
          ? normalizedTransactionDate <= normalizedEndDate
          : true;

        return isAfterStartDate && isBeforeEndDate;
      });

      setFilteredTransactions(filtered);
    } else {
      // Show all if no date is selected
      setFilteredTransactions(transactionList);
    }
  }, [startDate, endDate, transactionList]);

  const authRequest = {
    ...loginRequest,
  };

  const groupTransactionsByDate = (transactions) => {
    return transactions.reduce((grouped, transaction) => {
      const date = formatTransactionDate(transaction.created_at); // Custom function to format the date
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(transaction);
      return grouped;
    }, {});
  };

  const formatTransactionDate = (transactionDate) => {
    const today = new Date();
    const transactionDay = new Date(transactionDate);

    if (
      transactionDay.getDate() === today.getDate() &&
      transactionDay.getMonth() === today.getMonth() &&
      transactionDay.getFullYear() === today.getFullYear()
    ) {
      return "Today";
    }

    return transactionDay.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };
  const groupedTransactions = groupTransactionsByDate(filteredTransactions);
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div style={{ width: "100vw", height: "100vh" }}>
          <Container style={{ marginTop: "-1vh" }}>
            <div className="mt-6 mb-3">
              <h1 className="text-xl text-center">Transaction History</h1>
            </div>
            <div className="flex justify-center mb-4 space-x-1 ">
              <DatePicker
                calendarClassName="react-datepicker-popper"
                selected={startDate}
                onChange={(date) => setstartDate(date)}
                customInput={<CustomDateInput />}
                dateFormat="dd/MM/yyyy"
                placeholderText="Start date"
                isClearable
              />
              <span className="mt-2 text-gray-400">-</span>
              <DatePicker
                calendarClassName="popper-right"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                customInput={<CustomDateInput />}
                placeholderText="End date"
                dateFormat="dd/MM/yyyy"
                isClearable
              />
            </div>
            <div className="mb-5 mx-2">
              {Object.entries(groupedTransactions).map(
                ([date, transactions]) => (
                  <div key={date}>
                    <div className="font-bold text-lg mb-2 mt-4">{date}</div>{" "}
                    {/* Show group date */}
                    {transactions.map((item) => (
                      <div
                        className="mx-auto mt-3"
                        key={item.id}
                        onClick={() => {
                          handlePlanReceipt(
                            item.transaction_id,
                            item.type,
                            item.status
                          );
                        }}
                      >
                        <div className="mx-auto history__detail w-full">
                          <TransactionList item={item} />
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
              {/* For Only Web App */}
              <div className="mx-auto mt-3">
                <div className="mx-auto history__detail"></div>
              </div>
              <div className="mx-auto mt-3">
                <div className="mx-auto history__detail"></div>
              </div>
              <div className="mx-auto mt-3">
                <div className="mx-auto history__detail"></div>
              </div>
              {/* For Only Web App */}
            </div>
            {transactionList.length === 0 && (
              <div
                style={{
                  margin: "0",
                  position: "absolute",
                  top: "45%",
                  left: "35%",
                  transform: "translateY(-50%)",
                }}
              >
                <span className="history__timestamp">No Transaction</span>
              </div>
            )}
          </Container>
          {/* Navbar */}
          <Navbar path="/history" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
