import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import IconTopUp from '../images/icons/v3/topup-icon.svg';
import IconCancelPreorderBtn from '../images/icons/v3/cancel-preorder-btn.svg';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

// Sample app imports
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import usePlan from '../hooks/usePlan';

export default function InvestmentPage() {
    const navigate = useNavigate();
    const { saveFyxD, saveReturns } = usePlan();

    const goBack = () => {
        navigate('/');
    };

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;
    const currentDate = new Date();

    const [accessToken, setAccessToken] = useState('');
    const [investBalance, setinvestBalance] = useState('');
    const [returnBalance, setReturnBalance] = useState('');
    const [totalFyxDBalance, setTotalFyxDBalance] = useState(0); // 49875
    const [difftoTopup, setDifftoTopup] = useState(0);
    const [isLower, setIsLower] = useState(false);
    const [isHigher, setIsHigher] = useState(false);
    const [totalSubscribePlan, setTotalSubscribePlan] = useState(0);

    const inputRef = useRef(null);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const getUserProfile = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember/v1/fyxd/member/dashboard`, 
            { "upn": Email }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
            setTotalFyxDBalance(res.data.data.member.fyxd + res.data.data.member.bonus_fyxd + res.data.data.member.pending_bonus_fyxd);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const getSubscribedPlan = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/subscribe/list`, 
            { "plan_id" : "3" }, { headers: { Authorization: 'Bearer ' + accessToken } });

            const { data } = res.data;
            const activeSubscribedPlan = data.filter((item) => {
                return item.status === 1;
            });

            let sum = 0;
            for (const plan of activeSubscribedPlan) {
                sum += parseFloat(plan.total_amount);
            }

            setTotalSubscribePlan(sum);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const reset = () => {
        setIsHigher(false);
        setIsLower(false);
    };

    const validateMinimumandMaximum = (inputValue) => {
        if(parseFloat(inputValue) > 1000000) {
            setIsHigher(true);
            return false;
        } else if(parseFloat(inputValue) < 35000) {
            setIsLower(true);
            return false;
        }
        return true;
    };

    const onChangeinvestBalance = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^\d.]/g, '');

        setDifftoTopup(0);
        reset();
        const r = validateMinimumandMaximum(inputValue);

        // Determine if the user is typing in the decimal part
        const isTypingDecimal = inputValue.includes('.') && inputValue.split('.')[1].length < 3;

        // Convert the string to a number
        let numberValue = inputValue ? parseFloat(inputValue) : 0;

        // Format the value, conditionally applying decimal places
        let formattedValue;
        if (isTypingDecimal) {
            // Avoid formatting to keep the decimals as typed
            formattedValue = inputValue;
        } else {
            // Apply formatting with two decimal places
            formattedValue = new Intl.NumberFormat('en-US', {
                style: 'decimal',
                // minimumFractionDigits: 2,
                // maximumFractionDigits: 2
            }).format(numberValue);
        }

        // Update the state
        setinvestBalance(formattedValue);

        if(parseFloat(inputValue) > 0) {
            setReturnBalance(new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format((parseFloat(inputValue) * 1.05)));
        } else {
            setReturnBalance('0.00');
        }

        if(!r) return;

        if(totalFyxDBalance < inputValue) {
            setDifftoTopup(inputValue - totalFyxDBalance);
        }
    };

    const handleBlur = (event) => {
        let inputValue = event.target.value;

        if(inputValue.length > 0) {
            inputValue = inputValue.replace(/[^\d.]/g, '');
            // console.log(inputValue)
            setinvestBalance(new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format((parseFloat(inputValue))));
        }
    };

    const handleJoinPlan = (event) => {
        event.preventDefault();

        saveFyxD(investBalance.replace(/[^\d.]/g, ''));
        saveReturns(returnBalance.replace(/[^\d.]/g, ''));

        if(!difftoTopup && !isLower && !isHigher && investBalance !== '' && investBalance !== '0' && investBalance !== '0.00' )
            navigate('/planconsenttc');
    };

    const handleCancelPreorder = (event) => {
        navigate('/planlist');
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0) {
            getUserProfile();
            getSubscribedPlan();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "70vh"}}>
                      <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            <span onClick={goBack} className="float-left font-semibold text-xl cursor-pointer">{'<'}</span>
                            <span className="text-center text-black font-semibold text-xl">Dai Perm</span>
                        </div>
                      </nav>
                      <div className="mt-3">
                        <span className="text-yyellow-900 text-xl font-semibold">Bonus Calculator (Try now!)</span>
                      </div>
                      <div>
                        <div className="mt-3">
                            <div className="inline-block w-2/5">
                                <span className="text-black xs:text-xs text-sm font-semibold">
                                    FYX-D
                                </span>
                                <input ref={inputRef} placeholder='0.00' className="border-1 border-ggrey-200 p-2 w-full" value={investBalance} onChange={onChangeinvestBalance} onBlur={handleBlur}></input>
                                {/* <span className="mt-2">({investBalance} THB)</span> */}
                            </div>
                            <div className="inline-block w-1/5">
                                <svg className="mx-auto" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.40625 0.746094L15.5938 6.65234C15.7695 6.82812 15.875 7.03906 15.875 7.28516C15.875 7.49609 15.7695 7.70703 15.5938 7.88281L9.40625 13.7891C9.08984 14.1055 8.52734 14.1055 8.21094 13.7539C7.89453 13.4375 7.89453 12.875 8.24609 12.5586L12.9219 8.12891H0.96875C0.476562 8.12891 0.125 7.74219 0.125 7.28516C0.125 6.79297 0.476562 6.44141 0.96875 6.44141H12.9219L8.24609 1.97656C7.89453 1.66016 7.89453 1.09766 8.21094 0.78125C8.52734 0.429688 9.05469 0.429688 9.40625 0.746094Z" fill="#9C9C9C"/>
                                </svg>
                            </div>
                            <div className="inline-block w-2/5">
                                <span className="text-black xs:text-xs text-sm font-semibold">
                                    Guarantee return(5%)
                                </span>
                                <input placeholder='0.00' className="border-1 border-ggrey-200 p-2 w-full" value={returnBalance} disabled></input>
                                {/* <span className="mt-2">({returnBalance} THB)</span> */}
                            </div>
                        </div>
                        <div>
                            { isLower && <span className="mt-2 text-red-500 text-xs">Minimum amount is 35,000</span> }
                            { isHigher && <span className="mt-2 text-red-500 text-xs">Maximum amount is 1,000,000</span> }
                            { difftoTopup > 0 && <span className="mt-2 text-red-500 text-xs">You need to Top-up an additional <b>{difftoTopup.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</b> FYX-D. (You have total of {totalFyxDBalance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} FYX-D including Bonus)</span> }
                        </div>
                        { difftoTopup > 0 && 
                        <div className="w-full mt-2 mb-2">
                            <a href={`#/topup/?topup_amount=${difftoTopup}`} style={{textDecoration:"none"}} className="mywallet-card__topup-button inline-block float-left">
                                <div className="flex justify-center items-center">
                                    <img src={IconTopUp} alt="" className="inline-block" style={{ "padding": "1.5px", "width": "1.5rem", "height":"1.5rem"}} />
                                    <div className="inline-block">
                                        <span className="text-yyellow-700 font-semibold ml-1 text-sm">Top up ({difftoTopup.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})})</span>
                                    </div>
                                </div>
                            </a> 
                        <br/>
                        </div>
                        }
                        <div className="mt-3">
                            <span>Your available balance:</span><br/>
                            <div className="mt-2">
                                <div>
                                    <svg className="inline-block" width="50" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="40" rx="16" fill="#FFEDCB"/>
                                        <path opacity="0.4" d="M32.0502 18.0076C32.9566 18.9141 33.8631 19.8206 33.8631 19.8206C35.0842 18.5995 35.0904 17.3847 33.8815 16.1759C32.975 15.2694 32.371 14.665 32.374 14.0576C30.8475 15.5839 30.8414 16.7988 32.0502 18.0076Z" fill="#E8B34F"/>
                                        <path opacity="0.65" d="M32.3156 25.5988C32.3186 24.9913 32.9289 24.3808 33.8449 23.465C35.066 22.2439 35.0721 21.029 33.8633 19.8203C33.8633 19.8203 32.9476 20.7361 32.0319 21.6519C30.8107 22.873 30.8045 24.0879 32.3156 25.5988Z" fill="#E8B34F"/>
                                        <path d="M25.7269 7.4097C25.1194 7.4128 24.5151 6.80844 23.6086 5.90192C22.3998 4.6932 21.1849 4.6994 19.9639 5.92047C19.9639 5.92047 20.8704 6.82698 21.7769 7.73353C22.9857 8.94222 24.2005 8.93605 25.7269 7.4097Z" fill="#E8B34F"/>
                                        <path opacity="0.65" d="M18.1324 7.75207C19.0482 6.83628 19.964 5.92047 19.964 5.92047C18.7553 4.71177 17.5404 4.71794 16.3193 5.93901C15.4035 6.85483 14.793 7.46536 14.1855 7.46847C15.6964 8.97934 16.9113 8.97317 18.1324 7.75207Z" fill="#E8B34F"/>
                                        <path opacity="0.65" d="M32.3745 14.0579C33.9007 12.5316 33.9069 11.3167 32.0939 9.50362L30.2809 7.69056C28.4678 5.8775 27.2529 5.88367 25.7266 7.41003C26.334 7.40696 27.2538 8.30024 28.4492 9.52216L30.2623 11.3353C31.4581 12.5568 32.3776 13.4505 32.3745 14.0579Z" fill="#E8B34F"/>
                                        <path opacity="0.4" d="M7.73353 21.7711C6.82698 20.8646 5.92047 19.958 5.92047 19.958C4.6994 21.1791 4.6932 22.394 5.90192 23.6027C6.80844 24.5092 7.4128 25.1136 7.4097 25.721C8.93605 24.1947 8.94222 22.9798 7.73353 21.7711Z" fill="#E8B34F"/>
                                        <path opacity="0.65" d="M7.46845 14.1797C7.46538 14.7872 6.85484 15.3977 5.93902 16.3135C4.71796 17.5346 4.71175 18.7495 5.92048 19.9582C5.92048 19.9582 6.83626 19.0423 7.75208 18.1266C8.97315 16.9055 8.97935 15.6906 7.46845 14.1797Z" fill="#E8B34F"/>
                                        <path d="M14.0576 32.369C14.665 32.3659 15.2694 32.9703 16.1759 33.8768C17.3847 35.0857 18.5995 35.0795 19.8206 33.8584C19.8206 33.8584 18.9141 32.9519 18.0076 32.0451C16.7988 30.8365 15.5839 30.8427 14.0576 32.369Z" fill="#E8B34F"/>
                                        <path opacity="0.65" d="M21.652 32.0269C20.7361 32.9426 19.8203 33.8586 19.8203 33.8586C21.029 35.0672 22.2439 35.061 23.465 33.8399C24.3808 32.9242 24.9914 32.3136 25.5988 32.3106C24.0879 30.7997 22.873 30.8058 21.652 32.0269Z" fill="#E8B34F"/>
                                        <path opacity="0.65" d="M7.41004 25.7217C5.88369 27.248 5.87748 28.4629 7.69054 30.276L9.5036 32.0889C11.3167 33.9022 12.5316 33.896 14.0579 32.3694C13.4505 32.3725 12.5307 31.4793 11.3352 30.2574L9.52218 28.4444C8.32634 27.2228 7.40694 26.3291 7.41004 25.7217Z" fill="#E8B34F"/>
                                        <path d="M14.185 7.4681C12.6741 5.95722 11.4592 5.96339 9.62761 7.79499L7.79597 9.62663C5.96437 11.4582 5.9582 12.6731 7.46907 14.184C7.47218 13.5766 8.37487 12.6477 9.60903 11.4397L11.4407 9.60805C12.6745 8.39973 13.5776 7.47116 14.185 7.4681Z" fill="#E8B34F"/>
                                        <path d="M25.5996 32.3144C27.1105 33.8253 28.3254 33.8192 30.157 31.9875L31.9887 30.156C33.8201 28.3244 33.8266 27.1095 32.3157 25.5986C32.3126 26.2061 31.4099 27.135 30.1756 28.3429L28.3439 30.1746C27.1101 31.3829 26.2071 32.3114 25.5996 32.3144Z" fill="#E8B34F"/>
                                        <path d="M14.5 14.0026H19.3337C20.7773 14.0026 22.0369 14.2635 23.1125 14.7826C24.1881 15.3044 25.0227 16.0509 25.6136 17.0246C26.2045 17.9984 26.5 19.1349 26.5 20.4367C26.5 22.5935 25.8598 24.2285 24.5769 25.3366C23.2965 26.4472 21.5341 27 19.2948 27H14.5V14V14.0026ZM19.5747 23.7661C20.5337 23.7661 21.2698 23.4691 21.7855 22.8724C22.3013 22.2784 22.5605 21.4647 22.5605 20.4367C22.5605 19.5198 22.2987 18.7578 21.7778 18.1482C21.2568 17.5386 20.5389 17.2364 19.6317 17.2364H18.3618V23.7636H19.5747V23.7661Z" fill="#B58C3E"/>
                                    </svg>
                                    <span className="inline-block ml-3 align-bottom">
                                        <span className="inline-block font-bold">FYX-D total balance</span><br/>
                                        <span className="inline-block">{totalFyxDBalance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} FYX-D</span>
                                    </span>
                                </div>
                                <div className="mt-3">
                                    <span className="mb-2">Your pre-ordered balance:</span><br/>
                                    <svg className="inline-block mt-3" width="50" height="50" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="40" height="40" rx="16" fill="#F5F2ED"/>
                                        <path opacity="0.4" d="M32.0502 18.0076C32.9566 18.9141 33.8631 19.8206 33.8631 19.8206C35.0842 18.5995 35.0904 17.3847 33.8815 16.1759C32.975 15.2694 32.371 14.665 32.374 14.0576C30.8475 15.5839 30.8414 16.7988 32.0502 18.0076Z" fill="#967F4C"/>
                                        <path opacity="0.65" d="M32.3156 25.5988C32.3186 24.9913 32.9289 24.3808 33.8449 23.465C35.066 22.2439 35.0721 21.029 33.8633 19.8203C33.8633 19.8203 32.9476 20.7361 32.0319 21.6519C30.8107 22.873 30.8045 24.0879 32.3156 25.5988Z" fill="#967F4C"/>
                                        <path d="M25.7269 7.4097C25.1194 7.4128 24.5151 6.80844 23.6086 5.90192C22.3998 4.6932 21.1849 4.6994 19.9639 5.92047C19.9639 5.92047 20.8704 6.82698 21.7769 7.73353C22.9857 8.94222 24.2005 8.93605 25.7269 7.4097Z" fill="#967F4C"/>
                                        <path opacity="0.65" d="M18.1324 7.75207C19.0482 6.83628 19.964 5.92047 19.964 5.92047C18.7553 4.71177 17.5404 4.71794 16.3193 5.93901C15.4035 6.85483 14.793 7.46536 14.1855 7.46847C15.6964 8.97934 16.9113 8.97317 18.1324 7.75207Z" fill="#967F4C"/>
                                        <path opacity="0.65" d="M32.3745 14.0579C33.9007 12.5316 33.9069 11.3167 32.0939 9.50362L30.2809 7.69056C28.4678 5.8775 27.2529 5.88367 25.7266 7.41003C26.334 7.40696 27.2538 8.30024 28.4492 9.52216L30.2623 11.3353C31.4581 12.5568 32.3776 13.4505 32.3745 14.0579Z" fill="#967F4C"/>
                                        <path opacity="0.4" d="M7.73353 21.7711C6.82698 20.8646 5.92047 19.958 5.92047 19.958C4.6994 21.1791 4.6932 22.394 5.90192 23.6027C6.80844 24.5092 7.4128 25.1136 7.4097 25.721C8.93605 24.1947 8.94222 22.9798 7.73353 21.7711Z" fill="#967F4C"/>
                                        <path opacity="0.65" d="M7.46845 14.1797C7.46538 14.7872 6.85484 15.3977 5.93902 16.3135C4.71796 17.5346 4.71175 18.7495 5.92048 19.9582C5.92048 19.9582 6.83626 19.0423 7.75208 18.1266C8.97315 16.9055 8.97935 15.6906 7.46845 14.1797Z" fill="#967F4C"/>
                                        <path d="M14.0576 32.369C14.665 32.3659 15.2694 32.9703 16.1759 33.8768C17.3847 35.0857 18.5995 35.0795 19.8206 33.8584C19.8206 33.8584 18.9141 32.9519 18.0076 32.0451C16.7988 30.8365 15.5839 30.8427 14.0576 32.369Z" fill="#967F4C"/>
                                        <path opacity="0.65" d="M21.652 32.0269C20.7361 32.9426 19.8203 33.8586 19.8203 33.8586C21.029 35.0672 22.2439 35.061 23.465 33.8399C24.3808 32.9242 24.9914 32.3136 25.5988 32.3106C24.0879 30.7997 22.873 30.8058 21.652 32.0269Z" fill="#967F4C"/>
                                        <path opacity="0.65" d="M7.41004 25.7217C5.88369 27.248 5.87748 28.4629 7.69054 30.276L9.5036 32.0889C11.3167 33.9022 12.5316 33.896 14.0579 32.3694C13.4505 32.3725 12.5307 31.4793 11.3352 30.2574L9.52218 28.4444C8.32634 27.2228 7.40694 26.3291 7.41004 25.7217Z" fill="#967F4C"/>
                                        <path d="M14.185 7.4681C12.6741 5.95722 11.4592 5.96339 9.62761 7.79499L7.79597 9.62663C5.96437 11.4582 5.9582 12.6731 7.46907 14.184C7.47218 13.5766 8.37487 12.6477 9.60903 11.4397L11.4407 9.60805C12.6745 8.39973 13.5776 7.47116 14.185 7.4681Z" fill="#967F4C"/>
                                        <path d="M25.5996 32.3144C27.1105 33.8253 28.3254 33.8192 30.157 31.9875L31.9887 30.156C33.8201 28.3244 33.8266 27.1095 32.3157 25.5986C32.3126 26.2061 31.4099 27.135 30.1756 28.3429L28.3439 30.1746C27.1101 31.3829 26.2071 32.3114 25.5996 32.3144Z" fill="#967F4C"/>
                                        <path d="M14.5 14.0026H19.3337C20.7773 14.0026 22.0369 14.2635 23.1125 14.7826C24.1881 15.3044 25.0227 16.0509 25.6136 17.0246C26.2045 17.9984 26.5 19.1349 26.5 20.4367C26.5 22.5935 25.8598 24.2285 24.5769 25.3366C23.2965 26.4472 21.5341 27 19.2948 27H14.5V14V14.0026ZM19.5747 23.7661C20.5337 23.7661 21.2698 23.4691 21.7855 22.8724C22.3013 22.2784 22.5605 21.4647 22.5605 20.4367C22.5605 19.5198 22.2987 18.7578 21.7778 18.1482C21.2568 17.5386 20.5389 17.2364 19.6317 17.2364H18.3618V23.7636H19.5747V23.7661Z" fill="#6B5A36"/>
                                    </svg>
                                    <span className="inline-block ml-3 align-bottom">
                                        <div className="grid grid-cols-2 gap-2">
                                            <div>
                                                <span className="inline-block font-bold">Dai Perm</span><br/>
                                                <span className="inline-block">{totalSubscribePlan.toLocaleString(undefined, { minimumFractionDigits: 2 })} FYX-D</span>
                                            </div>
                                            <div className="flex items-end justify-items-end right-0">
                                                { totalSubscribePlan > 0 &&
                                                    <img width={130} src={IconCancelPreorderBtn} onClick={handleCancelPreorder} />
                                                }
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <hr className="mt-3 border-brown-500 border-2"/>
                            <div className="mt-3">
                                <svg className="inline-block" width="70" height="38" viewBox="0 0 70 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M49.3705 17.5049C50.5759 18.7079 51.7814 19.9108 51.7814 19.9108C53.4052 18.2905 53.4134 16.6783 51.8061 15.0744C50.6006 13.8714 49.797 13.0695 49.8011 12.2634C47.7714 14.2888 47.7631 15.901 49.3705 17.5049Z" fill="#6B5325"/>
                                    <path d="M49.7234 27.5788C49.7275 26.7728 50.5394 25.9626 51.7572 24.7473C53.381 23.127 53.3892 21.5148 51.7819 19.9109C51.7819 19.9109 50.5641 21.1262 49.3462 22.3414C47.7225 23.9618 47.7142 25.5739 49.7234 27.5788Z" fill="#B58C3E"/>
                                    <path d="M40.9609 3.44238C40.1532 3.44649 39.3495 2.64452 38.144 1.44157C36.5367 -0.162363 34.9211 -0.154154 33.2974 1.46619C33.2974 1.46619 34.5029 2.66914 35.7083 3.87209C37.3157 5.47603 38.9312 5.46782 40.9609 3.44238Z" fill="#FFC557"/>
                                    <path d="M30.8622 3.89634C32.08 2.68108 33.2979 1.46582 33.2979 1.46582C31.6905 -0.138112 30.075 -0.129904 28.4512 1.49045C27.2334 2.70571 26.4215 3.51588 25.6137 3.51999C27.6229 5.5249 29.2384 5.51669 30.8622 3.89634Z" fill="#B58C3E"/>
                                    <path d="M49.8019 12.2633C51.8317 10.2379 51.8399 8.62577 49.4289 6.21987L47.0179 3.81397C44.6069 1.40807 42.9914 1.41628 40.9617 3.44171C41.7694 3.43761 42.9925 4.623 44.5823 6.24449L46.9932 8.65039C48.5834 10.2714 49.8061 11.4573 49.8019 12.2633Z" fill="#B58C3E"/>
                                    <path d="M25.6133 3.51953C23.6041 1.51462 21.9886 1.52282 19.5529 3.95335L17.1172 6.38387C14.6816 8.8144 14.6734 10.4265 16.6825 12.4315C16.6866 11.6254 17.887 10.3927 19.5282 8.78977L21.9639 6.35925C23.6046 4.75583 24.8055 3.52364 25.6133 3.51953Z" fill="#FFC557"/>
                                    <path d="M40.7926 36.4908C42.8017 38.4957 44.4173 38.4875 46.8529 36.057L49.2886 33.6264C51.7242 31.1959 51.7325 29.5838 49.7233 27.5789C49.7192 28.3849 48.5188 29.6176 46.8776 31.2205L44.4419 33.6511C42.8012 35.2545 41.6003 36.4867 40.7926 36.4908Z" fill="#FFC557"/>
                                    <path d="M0 14.9414H10.9682V18.4259H4.47744V20.3475H9.1502V23.5317H4.47744V28.9378H0V14.9414Z" fill="#3E3F41"/>
                                    <path d="M16.8101 23.6123L11.293 14.9414H16.0739L19.0609 19.7663L22.2402 14.9414H27.0001L21.2876 23.6123V28.9378H16.8101V23.6123Z" fill="#3E3F41"/>
                                    <path d="M32.5836 21.9104L27.1746 14.9414H32.3011L35.1348 18.6067L37.9895 14.9414H43.1161L37.7071 21.9104L43.1581 28.9378H38.0316L35.1318 25.2141L32.232 28.9378H27.1055L32.5806 21.9104H32.5836Z" fill="#3E3F41"/>
                                    <path d="M55.8516 14.5693H61.2607C62.8762 14.5693 64.2858 14.8616 65.4894 15.4434C66.6931 16.028 67.627 16.8644 68.2883 17.9556C68.9495 19.0467 69.2802 20.3201 69.2802 21.7788C69.2802 24.1955 68.5638 26.0276 67.1281 27.2692C65.6953 28.5137 63.7231 29.1331 61.2172 29.1331H55.8516V14.5664V14.5693ZM61.5304 25.5095C62.6036 25.5095 63.4273 25.1767 64.0044 24.5081C64.5816 23.8424 64.8716 22.9307 64.8716 21.7788C64.8716 20.7514 64.5787 19.8976 63.9957 19.2145C63.4128 18.5315 62.6094 18.1929 61.5942 18.1929H60.1731V25.5066H61.5304V25.5095Z" fill="#3E3F41"/>
                                </svg>
                                <span className="ml-3 text-2xl text-ggrey-400 inline-block">
                                    | Dai Perm Information
                                </span>
                            </div>
                            <div className="mt-3">
                                {/* <span className="font-semibold inline-block">Expected yield: </span><span className="inline-block ml-2 text-ggrey-300">5%</span><br/>
                                <span className="font-semibold inline-block">Contract period: </span><span className="inline-block ml-2 text-ggrey-300">1 Year</span><br/>
                                <span className="font-semibold inline-block">Sector: </span><span className="inline-block ml-2 text-ggrey-300">Real estate</span><br/>
                                <span className="font-semibold inline-block">Industry: </span><span className="inline-block ml-2 text-ggrey-300">Property development</span><br/> */}
                                <span className="font-semibold inline-block">วัตถุประสงค์: </span><span className="inline-block ml-2 text-ggrey-300">FAMZ Wealth Creation Program</span><br/>
                                <span className="font-semibold inline-block">โบนัส: </span><span className="inline-block ml-2 text-ggrey-300">5% ตลอดโครงการ</span><br/>
                                <span className="font-semibold inline-block">ระยะเวลาโครงการ: </span><span className="inline-block ml-2 text-sm text-ggrey-300">ประมาณ 1 ปี (20 มกราคม 2567 ถึง 31 ธันวาคม 2567)</span><br/>
                            </div>
                            <hr className="mt-3 mb-3"/>
                            <div>
                                <div className="font-semibold">ข้อมูลโครงการ: </div>
                                <span className="mt-3 text-sm">
                                    Dai Perm เป็นโครงการส่งเสริม สนับสนุนการออมทรัพย์ และเพิ่มพูนมูลค่าทรัพย์สินให้กับ FAMZ ซึ่งโครงการนี้ เป็นส่วนหนึ่งของ DTGO Wealth Growth Initiatives ที่ออกแบบมาให้กับ FAMZ โดยเฉพาะ โดยโครงการจะอยู่ในความดูแลของ DLC (DIGITALIFE CORPORATION COMPANY LIMITED) โครงการนี้จัดขึ้น โดยมีวัตถุประสงค์หลักจาก DTGO ที่อยากให้ FAMZ มีการเก็บสะสมเงินในรูปแบบของ FYX-D และได้รับสิทธิประโยชน์เพิ่มเติม  5% ของยอด FYX-D ตลอดโครงการ  (ทั้งนี้เป็นไปตามระยะเวลาและเงื่อนไขของโครงการที่กำหนด)
                                </span>
                            </div>
                            <div>
                                <hr className="mt-2 mb-2"/>
                                <div className="font-semibold">รายละเอียดโครงการ: </div>
                                <span className="mt-3 text-sm">
                                <b>&nbsp; &nbsp;&#8226; ระยะเวลาของโครงการ:</b> วันที่ 20 มกราคม 2567 ถึง 31 ธันวาคม 2567<br/>
<b>&nbsp; &nbsp;&#8226; จำนวนเงินขั้นต่ำของการเข้าร่วมโครงการ:</b><br/>
&nbsp; &nbsp;&nbsp; &nbsp;&#8226; จำนวน FYX-D ขั้นต่ำในการเข้าร่วมโครงการไม่น้อยกว่า 35,000 FYX-D ต่อ FAMZ 1 ท่าน<br/>
&nbsp; &nbsp;&nbsp; &nbsp;&#8226; ยอดรวมจำนวน FYX-D สูงสุดในการเข้าร่วมโครงการไม่เกิน 1,000,000 FYX-D ต่อ FAMZ 1 ท่าน<br/>
&nbsp; &nbsp;&nbsp; &nbsp;&#8226; ยอดรวมจำนวน FYX-D ของทั้งโครงการจะจำกัดอยู่ที่ 50,000,000 FYX-D (ขอสงวนสิทธิให้กับ FAMZ ที่แสดงเจตจำนงค์ร่วมโครงการก่อน)
ทั้งนี้ FAMZ ใหม่ สามารถเข้าร่วมโครงการ Dai Perm ได้โดยการสมัครและระบุ จำนวน FYX-D ที่ต้องการเข้าร่วมโครงการ แต่ต้องไม่น้อยกว่าขั้นต่ำที่ 35,000 FYX-D ตามรายละเอียดที่ระบุข้างต้น<br/>
<b>&nbsp; &nbsp;&#8226; สิทธิประโยชน์เพิ่มเติม 5%:</b> เมื่อครบระยะเวลาประมาณ 1 ปี FAMZ ที่เข้าร่วมโครงการจะได้รับสิทธิประโยชน์เพิ่มเติม  5% ตลอดโครงการโดยการคำนวนสิทธิประโยชน์เพิ่มเติมนั้น  จะคำนวนจากอัตราจำนวน FYX-D ทั้งหมด ที่ FAMZ เข้าร่วมโครงการ และสิทธิประโยชน์เพิ่มเติม  จะถูกหักภาษีไว้ตามกฎหมายกำหนด
<br/><b>&nbsp; &nbsp;&#8226; ผู้ที่สามารถเข้าร่วมโครงการ:</b> FAMZ ทุกคนที่มี FYX-D Wallet สามารถเข้าโครงการ Dai Perm ได้ (และเป็นไปตามเงื่อนไขรายละเอียดที่ได้กำหนดขึ้น)
<br/><b>&nbsp; &nbsp;&#8226; สงวนสิทธิ์การได้รับสิทธิประโยชน์เพิ่มเติม 5%:</b> เฉพาะ FAMZ ที่อยู่ครบเงื่อนไขตามระยะเวลาโครงการ (ตั้งแต่วันที่เข้าร่วมโครงการ - 31 ธันวาคม 2567)  FAMZ ท่านนั้นจะได้รับสิทธิประโยชน์เพิ่มเติม 5% ตลอดโครงการ และ DTGO จะจ่ายสิทธิประโยชน์จำนวน 2 ครั้ง ได้แก่ ครั้งที่1 (สิ้นเดือนมิถุนายน) และครั้งที่2 (สิ้นเดือนธันวาคม) ทั้งนี้ สิทธิประโยชน์เพิ่มเติมจากโครงการจะปรากฏบนบัญชีกระเป๋าเงินของ FAMZ แยกส่วนออกมาเป็นโบนัส ตามรอบการจ่ายในแต่ละครั้ง 
อย่างไรก็ตาม กรณี FAMZ อยู่ไม่ครบระยะเวลาโครงการ เนื่องจากสิ้นสภาพการเป็นพนักงาน แต่ FAMZ ท่านนั้นคงสภาพพนักงานถึงรอบการจ่ายครั้งที่1 (สิ้นเดือนมิถุนายน) FAMZ ท่านนั้นจะได้รับสิทธิประโยชน์ในครั้งที่ 1 เท่านั้น  หรือในกรณีของ FAMZ ที่อยู่ไม่ครบรอบการจ่ายครั้งที่1 FAMZ ท่านนั้นจะไม่ได้รับสิทธิประโยชน์เพิ่มเติมใดๆ
<br/><b>&nbsp; &nbsp;&#8226; การหักภาษีของค่าตอบแทน:</b> ค่าตอบแทนของโครงการที่ FAMZ ได้รับจะมีการหักกภาษี ณ ที่จ่ายตามอัตราที่กฎหมายกำหนด
<br/><b>&nbsp; &nbsp;&#8226; การแลก FYX-D ออกมาเป็นเงินบาท:</b> FAMZ สามารถแลก FYX-D ออกมาเป็นเงินบาทได้ เฉพาะ FYX-D ที่เติมเข้ามาในระบบ และเข้าร่วมโครงการ Dai Perm เท่านั้น (สงวนสิทธิ์ FAMZ ไม่สามารถแลกสิทธิประโยชน์เพิ่มเติมที่ได้รับจากโครงการออกมาเป็นเงินสดได้)
<br/><b>&nbsp; &nbsp;&#8226; ระยะเวลาลงทะเบียน:</b> ระยะเวลาลงทะเบียนสำหรับ Dai Perm จะเริ่มต้นในวันที่ 27 พฤศจิกายน ถึง 19 มกราคม 2567 เวลา 23.59 น.
<br/><br/>*** DTGO ขอเน้นย้ำและแจ้งให้ทราบว่า โครงการ &quot;Dai Perm&quot;นี้ จัดทำขึ้นเพื่อส่งเสริมและสนับสนุนเรื่องวินัยทางการเงินของพนักงานและให้ผลประโยชน์ตอบแทนแก่พนักงานของกลุ่มบริษัท DTGO เท่านั้น  DTGO มิได้ดำเนินธุรกรรมในการรับการฝากเงิน ธุรกรรมเงินกู้ หรือธุรกรรมอื่นใดเยี่ยงธนาคารพาณิชย์ หรือเพื่อแสวงหาผลประโยชน์แต่ประการใด ***
                                </span>
                            </div>
                            <div style={{height: "15vh"}}>
                            </div>
                            <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-t border-ggrey-200">
                                {/* { currentDate < new Date("2023-12-4") &&
                                    <button className="bg-ggrey-200 px-3 py-3 w-full text-md font-semibold rounded-xl text-neutral-500">Pre-order open at 6 Dec</button> 
                                }
                                { currentDate >= new Date("2023-12-4") && */}
                                    <>
                                        <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer', ( difftoTopup || isLower || isHigher || investBalance === '' || investBalance === '0' || investBalance === '0.00' ) && 'bg-ggrey-200 text-neutral-500 disabled', ( !difftoTopup && !isLower && !isHigher && investBalance !== '' && investBalance !== '0' && investBalance !== '0.00' ) && 'bg-yyellow-500  text-black')} onClick={handleJoinPlan}>Join Dai Perm</button> 
                                    </>
                                {/* } */}
                            </div>
                        </div>
                      </div>
                </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
