import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import IconDaidee from '../images/icons/v2/daideeplan.svg';
import IconFYXD from '../images/icons/v2/fyxdfactsheet.svg';
import IconTerm from '../images/icons/v2/tc.svg';
import LogoFyxdInfo from '../images/logos/fyxd-info-color.svg';

import {Navbar} from "./components/navbar";

export default function InfoPage() {

  return (
    <>
        <div style={{"width": "100vw", "height": "100vh"}}>
            <Container className="text-center mt-4">
                <a href="#/">
                    <h1 className="float-left">{`<`}</h1>
                </a>
                <h2 className="heading--main" style={{"fontWeight" : "300"}}>Information & FAQs</h2>
                <img className="info-dtgo-logo mt-3" alt="" src={LogoFyxdInfo} style={{"display": "inline-block"}}/> 
                <div className="mt-5">
                    <div className="info__detail">
                        <img className="info__logo" alt="" src={IconDaidee} />
                        <div className="info__detail-content">
                            <span className="child"><b>Dai Perm</b></span><br/>
                            <span className="child">Terms & Conditions</span><br/>
                            <span className="child">เงื่อนไขการใช้งาน Dai Perm</span><br/>
                        </div>
                        <div>
                            <a href="https://fyxd-dlc.obs.ap-southeast-2.myhuaweicloud.com/info/DaiPerm_T%26C_%2Bfor%2Bplatform%2Busage_07062024_clean%2Bversion.pdf" target="_blank" rel="noreferrer">
                                <button className="info-view-btn">View</button>
                            </a>
                        </div>
                    </div>
                    <div className="info__detail mt-3">
                        <img className="info__logo" alt="" src={IconDaidee} />
                        <div className="info__detail-content">
                            <span className="child"><b>DaiDee 2.0</b></span><br/>
                            <span className="child">Terms & Conditions</span><br/>
                            <span className="child">เงื่อนไขการใช้งาน Daidee</span><br/>
                        </div>
                        <div>
                            <a href="https://fyxd-dlc.obs.ap-southeast-2.myhuaweicloud.com/info/TermsConditions_DaiDee2_Plan_09012024_clean.pdf" target="_blank" rel="noreferrer">
                                <button className="info-view-btn">View</button>
                            </a>
                        </div>
                    </div>
                    <div className="info__detail mt-3">
                        <img className="info__logo" alt="" src={IconFYXD} />
                        <div className="info__detail-content">
                            <span className="child"><b>FYX-D Factsheet</b></span><br/>
                            <span className="child">ถามตอบเกี่ยวกับ FYX-D</span>
                        </div>
                        <a href="https://fyxd-dlc.obs.ap-southeast-2.myhuaweicloud.com/info/FAQ_TH_EN_asof_28Aug2023.pdf" target="_blank" rel="noreferrer">
                            <button className="info-view-btn">View</button>
                        </a>
                    </div>
                    <div className="info__detail mt-3">
                        <img className="info__logo" alt="" src={IconTerm} />
                        <div className="info__detail-content">
                            <span className="child"><b>Terms & Conditions</b></span><br/>
                            <span className="child">ข้อกำหนดและเงื่อนไขทั่วไป</span>
                        </div>
                        <a href="https://fyxd-dlc.obs.ap-southeast-2.myhuaweicloud.com/info/FYX-D%20TC.pdf" target="_blank" rel="noreferrer">
                            <button className="info-view-btn">View</button>
                        </a>
                    </div>
                </div>
           </Container>
           {/* <Navbar path="/info" /> */}
        </div>
    </>
  );
}