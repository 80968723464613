import React from 'react'
import IconTransactionSuccess from '../images/icons/history-transaction-success.svg';
import IconTransactionFail from '../images/icons/history-transaction-fail.svg';
import IconTopup from '../images/icons/v2/topup.svg';
import IconKbankTopupFail from '../images/icons/v2/kbank-topup-fail.svg';

const PlanList = (props) => {
  const { item } = props;
  // console.log(item);
  const { status } = item;

  const renderIcon = (status) => {
    switch(status) {
      case 1:
        return <><img className="align-top inline-block" alt="" src={IconTransactionSuccess} /></>; 
      case 2:
        return <><img className="align-top inline-block" alt="" src={IconTransactionFail} /></>;
      default:
        return <><img className="align-top inline-block" alt="" src={IconTransactionFail} /></>;
    }
  }

  return (
    <>
        <div className="py-2 border-b border-gray-300 w-full">
            {
                renderIcon(status)
            }
            <div className="inline-block">
                <div>
                    <span className="text-sm ml-3">
                        { (status !== 1 && status !== 4) && <span className="text-red-500">Cancelled <strike>PLANS {parseFloat(item.total_amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} DAI PERM</strike></span> } 
                        { (status === 1 || status === 4) && <>PLANS {parseFloat(item.total_amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} DAI PERM</> }
                        &nbsp;
                    </span>
                </div>
                <div className="w-full">
                    <span className="ml-3">
                        <span className="text-sm text-gray-700">
                            { (status !== 1 && status !== 4) && 
                                <strike>
                                    {parseFloat(item.total_amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} FYX-D
                                </strike>
                            } 
                            { (status === 1 || status === 4) &&
                                <>
                                    {parseFloat(item.total_amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} FYX-D
                                </>
                            }
                        </span>
                        <span className="float-right ml-10">
                            <small className="history__timestamp">{item.created_at.replace("T", " ").replace(".000Z", "")}</small>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </>
  )
}

export default PlanList