import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/index.css";

import LogoMissionLanding from "../../images/logos/fyxd-logo-header-mission-page.svg";
import LogoBack from "../../images/logos/fyxd-logo-header-back.svg";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import { Navbar } from "../components/navbar";
import { useNavigate } from "react-router-dom";

import MissionBg from "../../images/icons/v4/missiondetail/starcafe.png";
import IconCoffee from "../../images/icons/v4/missiondetail/coffee_icon.svg";
import { setCookie, getCookie, removeCookie } from "../../utils/cookies";
import Stamp from "./components/Stamp";
import Modal from "react-modal";
import ClosedCircle from "../../images/icons/v4/redeem/icon-close.png";
import IconCongrat from "../../images/icons/v4/redeem/icon-redeem-conglat.svg";
import { twMerge } from "tailwind-merge";
import IconMissionCompleted from "../../images/icons/v4/missionstatus/icon-fullyredeemed.svg";
import IconRedeemNow from "../../images/icons/v4/missionstatus/icon-redeemnow.svg";
import IconRedeemed from "../../images/icons/v4/missionstatus/icon-redeemed.svg";
import { set } from "date-fns";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    minHeight: "30vh",
    borderRadius: "20px",
    minWidth: "40vh",
    zIndex: "1000",
  },
  overlay: {
    background: `
      radial-gradient(circle at center, rgba(249, 238, 217, 0.5) 10%, transparent 20%),
      radial-gradient(circle at center, rgba(253, 224, 172, 0.5) 40%, transparent 40%),
      radial-gradient(circle at center, rgba(250, 230, 186, 0.5) 60%, transparent 60%),
      radial-gradient(circle at center, rgba(255, 238, 170, 0.5) 80%, transparent 80%),
      radial-gradient(circle at center, rgba(249, 238, 217, 0.5) 100%, transparent 100%)
    `,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

export default function MissionDetailPage() {
  const navigate = useNavigate();

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;

  const [accessToken, setAccessToken] = useState("");
  const [tasksCompleted, setTasksCompleted] = useState(10);
  const [isOpen3, setIsOpen3] = useState(false);
  const [status, setStatus] = useState("redeemed");

  let Email = "";

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    // userName = name;
    Email = email;
  }

  const openModal3 = () => {
    setIsOpen3(true);
  };

  const closeModal3 = () => {
    setIsOpen3(false);
  };

  const navigateRedeem = () => {
    setCookie("status", "redeemed");
    navigate("/missionlanding/1");
  };
  const requestToken = async () => {
    await requestAccessToken();
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    requestToken();
    const count = getCookie("count");
    setTasksCompleted(count);
    const status = getCookie("status");
    if (status === "redeemed") {
      setStatus("redeemed");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if (Email.length > 0 && accessToken.length > 0) getTransaction();
    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div className="w-full" style={{ width: "100vw", height: "130vh" }}>
          <div className="static w-full bg-gray-100 p-3  text-center text-white">
            <div className="mt-1 flex text-center ">
              <a href="#/missionlanding/0">
                <img
                  src={LogoBack}
                  alt="Logo"
                  style={{ display: "inline-block" }}
                  className="mx-auto w-[10px] h-[30px]"
                />
              </a>
              <img
                src={LogoMissionLanding}
                alt="Logo"
                style={{ marginLeft: "0.5rem", display: "inline-block" }}
                className="mx-auto w-[298px] h-[38px]"
              />
            </div>
          </div>
          <div>
            <img className="w-full" src={MissionBg} alt="" />
          </div>
          <div className="px-4 text-center">
            <h1 className="mt-3 font-bold mb-2 text-xl">Sip and Stamp</h1>
            <span className="text-gray-500">
              Make 10 transactions at the Dilly Dally Kaffe to receive a reward.
              Each transaction must have a value of over 100 FYX-D to receive an
              e-stamp. Each e-stamp can only be collected automatically once
              daily.
            </span>
          </div>
          <div className="px-4 mt-3">
            <div className="relative mt-3">
              <div
                className={twMerge(
                  "border-2 border-yellow-500 rounded-3xl p-2 text-center",
                  status === "redeemed" ? "filter grayscale" : ""
                )}
              >
                <img
                  src={IconCoffee}
                  className={twMerge(
                    "absolute -top-3 left-0 w-[60px]",
                    status === "redeemed" ? "filter grayscale" : ""
                  )}
                  alt=""
                />
                <span>Free 1 coffee at Dilly Dally Kaffe</span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="relative px-4 py-2 grid grid-cols-4 gap-3">
              <Stamp
                totalTasks={10}
                tasksCompleted={tasksCompleted}
                status={status}
              />
              {tasksCompleted === 10 && status === "active" && (
                <>
                  <img
                    src={IconMissionCompleted}
                    className="w-[40vh] h-[40vh] absolute left-1/5 top-1/5 -mt-10 ml-5"
                    alt="Mission Completed"
                  />
                </>
              )}
              {status === "redeemed" && (
                <>
                  <img
                    src={IconMissionCompleted}
                    className="w-[40vh] h-[40vh]  absolute m-auto left-0 right-0 bottom-4 top-1"
                    alt="Redeemed"
                  />
                </>
              )}
            </div>
          </div>
          <div className="mt-2 px-4">
            <button
              className={twMerge(
                "p-4 w-full rounded-2xl",
                tasksCompleted === 10 && status === "active"
                  ? "cursor-pointer bg-yellow-400"
                  : "bg-gray-400"
              )}
              disabled={tasksCompleted !== 10 && status !== "active"}
              onClick={openModal3}
            >
              Redeem Reward
            </button>
          </div>
          <a href="#/missionlanding/1">
            <div className="text-center mt-2 mb-2">
              <span className="text-yellow-500">View all rewards {" >"}</span>
            </div>
          </a>
          <div className="h-24" />
          {/* <div className="mb-4 ">
            <Tab />
          </div> */}
          {/* Navbar */}
          <Modal
            isOpen={isOpen3}
            onRequestClose={closeModal3}
            style={customStyles}
          >
            <div className="text-center">
              <div className="flex justify-end">
                <img
                  src={ClosedCircle}
                  className="cross-icon w-[10%] float-right"
                  onClick={closeModal3}
                ></img>
              </div>
              <div className="flex flex-col ">
                <img
                  className="mx-auto"
                  src={IconCongrat}
                  alt=""
                  style={{ width: "30vh" }}
                />
                <span
                  className="mt-5 text-yellow-500 "
                  style={{ fontSize: "1.2rem" }}
                >
                  CONGRATULATIONS
                </span>
                <hr className="mt-3 mb-3 congrat-line " />
                <span className="text-m text-ggrey-400 mb-2">
                  You have received
                </span>
                <span className="text-m  font-bold text-yellow-900 mb-2">
                  1 Free coffee at Dilly Dally Kaffe{" "}
                </span>

                <button
                  className={twMerge(
                    "p-4 w-[50%] rounded-3xl mx-auto mb-3 mt-3",
                    tasksCompleted === 10 && status === "active"
                      ? "cursor-pointer bg-yellow-400"
                      : "bg-gray-400"
                  )}
                  disabled={tasksCompleted !== 10}
                  onClick={navigateRedeem}
                >
                  Redeem Now !
                </button>
              </div>
            </div>
          </Modal>
          <Navbar path="/missionlanding/0" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
