import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';
import IconFyxdTopup from '../images/icons/v3/fyxd-topup-icon.svg';
import IconFyxdBonus from '../images/icons/v3/fyxd-bonus-icon.svg';
import Modal from 'react-modal';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

// Sample app imports
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import usePlan from '../hooks/usePlan';

export default function PlanReviewPage() {
    const navigate = useNavigate();
    const { FyxD, Returns, saveFyxD, saveReturns } = usePlan();

    const goBack = () => {
      navigate(-1); // Navigate back to the previous page
    };
    
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [FyxDTopup, setFyxDTopup] = useState(0);
    const [FyxDBonus, setFyxDBonus] = useState(0);
    const [investAmount, setinvestAmount] = useState(FyxD);
    const [returnsAmount, setreturnsAmount] = useState(Returns);
    const [difftoTopup, setDifftoTopup] = useState(0);
    const [isLower, setIsLower] = useState(false);
    const [isHigher, setIsHigher] = useState(false);
    const [totalFyxDBalance, setTotalFyxDBalance] = useState(0);
    const [errorMessage, seterrorMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('Dai Perm has reached maximum program limit');

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const handleOpenConfirm = async (event) => {
        event.preventDefault();

        saveFyxD(investAmount.toString().replace(/[^\d.]/g, ''));
        saveReturns(returnsAmount.toString().replace(/[^\d.]/g, ''));

        try {
            const res1 = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/limit/total`, 
            { "plan_id": 3, "total_amount": parseFloat(investAmount.toString().replace(/[^\d.]/g, '')) }, { headers: { Authorization: 'Bearer ' + accessToken } });

            const re2 = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/limit/user`, 
            { "plan_id": 3, "total_amount": parseFloat(investAmount.toString().replace(/[^\d.]/g, '')) }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
            if(!difftoTopup && !isLower && !isHigher)
                navigate('/planconfirm');
        } catch (error) {
            // console.error(error);
            requestToken();
            setModalTitle(error.response.data.title);
            seterrorMessage(error.response.data.message);
            openModal();
        }
    };

    const reset = () => {
        setIsHigher(false);
        setIsLower(false);
    };

    const validateMinimumandMaximum = (inputValue) => {
        if(parseFloat(inputValue) > 1000000) {
            setIsHigher(true);
            return false;
        } else if(parseFloat(inputValue) < 35000) {
            setIsLower(true);
            return false;
        }
        return true;
    };

    const getUserProfile = async () => {
        try {
            const res = await axios.post(`${baseURI}/famzmember/v1/fyxd/member/dashboard`, 
            { "upn": Email }, { headers: { Authorization: 'Bearer ' + accessToken } });
    
            setTotalFyxDBalance(res.data.data.member.fyxd + res.data.data.member.bonus_fyxd + res.data.data.member.pending_bonus_fyxd);
            setFyxDTopup(res.data.data.member.fyxd);
            setFyxDBonus(res.data.data.member.bonus_fyxd + res.data.data.member.pending_bonus_fyxd);
        } catch (error) {
            console.error(error);
            requestToken();
        }
    };

    const onChangeinvestBalance = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^\d.]/g, '');

        setDifftoTopup(0);
        reset();
        const r = validateMinimumandMaximum(inputValue);

        // Determine if the user is typing in the decimal part
        const isTypingDecimal = inputValue.includes('.') && inputValue.split('.')[1].length < 3;

        // Convert the string to a number
        let numberValue = inputValue ? parseFloat(inputValue) : 0;

        // Format the value, conditionally applying decimal places
        let formattedValue;
        if (isTypingDecimal) {
            // Avoid formatting to keep the decimals as typed
            formattedValue = inputValue;
        } else {
            // Apply formatting with two decimal places
            formattedValue = new Intl.NumberFormat('en-US', {
                style: 'decimal',
                // minimumFractionDigits: 2,
                // maximumFractionDigits: 2
            }).format(numberValue);
        }

        // Update the state
        setinvestAmount(formattedValue);

        if(parseFloat(inputValue) > 0) {
            setreturnsAmount(new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format((parseFloat(inputValue) * 1.05)));
        } else {
            setreturnsAmount('0.00');
        }

        if(!r) return;

        if(totalFyxDBalance < inputValue) {
            setDifftoTopup(inputValue - totalFyxDBalance);
        }
    };

    const handleBlur = (event) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^\d.]/g, '');
        // console.log(inputValue)
        setinvestAmount(new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format((parseFloat(inputValue))));
        // Additional blur-related logic goes here
    };

    useEffect(() => {
        if(Email.length > 0 && accessToken.length > 0) {
            getUserProfile();
        }
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    useEffect(() => {
        setinvestAmount(FyxD.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setreturnsAmount(Returns.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }, [FyxD, Returns]);

    const authRequest = {
        ...loginRequest
    };

    const openModal = async () => {
        setIsOpen(true);
    };

    const closeModal = async () => {
        setIsOpen(false);
    };

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          minHeight: '20vh',
          minWidth: '40vh'
        },
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "70vh"}}>
                      <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            <span className="float-left font-semibold text-xl cursor-pointer" onClick={goBack}>{'<'}</span>
                            <span className="text-center text-black font-semibold text-xl">Dai Perm</span>
                        </div>
                      </nav>
                      <div>
                        <div className="mt-3">
                            <span className="text-xl text-yellow-700 font-black">Specify your purchase amount</span>
                            <div className="mt-2">
                                <span className="text-sm font-black mt-4">FYX-D</span>
                            </div>
                            <input className="mb-2 w-full p-2 py-3 mt-2 border-ggrey-700 border rounded-md" type="text" value={investAmount} onChange={onChangeinvestBalance} onBlur={handleBlur}/>
                            <div>
                                { isLower && <span className="mt-1 text-red-500 text-xs">Minimum amount is 35,000</span> }
                                { isHigher && <span className="mt-1 text-red-500 text-xs">Maximum amount is 1,000,000</span> }
                                { difftoTopup > 0 && <span className="mt-1 text-red-500 text-xs">Cannot exceed your blanace {totalFyxDBalance.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} FYX-D</span> }
                            </div>
                            <span className="mt-3 text-sm">
                                <b>You have FYX-D total available balance of</b>: {totalFyxDBalance.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} FYX-D including Bonus.
                            </span>
                            <div className="grid grid-cols-2 gap-2">
                                <div className="mt-2">
                                    <img className="inline-block float-left" src={IconFyxdTopup} />
                                    <div className="inline-block float-left">
                                        <span className="font-black ml-3">FYX-D Top Up</span><br/>
                                        <span className="ml-3">{FyxDTopup.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <img className="inline-block float-left" src={IconFyxdBonus} />
                                    <div className="inline-block float-left">
                                        <span className="font-black ml-3">FYX-D Bonus</span><br/>
                                        <span className="ml-3">{FyxDBonus.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</span>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div>
                                <span className="text-sm">Remark: FYX-D Bonus will be used for purchase first.</span>
                            </div>
                            <hr className="mt-3 border-brown-500 border-2"/>
                            <div className="mt-3">
                                <span className="font-black text-sm">Guarantee return (5%)</span>
                            </div>
                            <input className="mb-2 w-full bg-ggrey-200 p-2 py-3 mt-2 border-ggrey-700 border rounded-md disabled" type="text" value={returnsAmount} disabled/>
                            <div style={{height: "25vh"}}>
                            </div>

                            <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-t border-ggrey-200">
                                <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer', ( difftoTopup || isLower || isHigher  || investAmount === '0' || investAmount === '0.00' ) && 'bg-ggrey-200 text-neutral-500 disabled', ( !difftoTopup && !isLower && !isHigher && investAmount !== '0' && investAmount !== '0.00' ) && 'bg-yyellow-500  text-black')} onClick={handleOpenConfirm}>Review</button> 
                            </div>
                        </div>
                      </div>
                </Container>
                <Modal
                  isOpen={isOpen}
                  style={customStyles}
                >
                  <div style={{textAlign : "center"}}>
                      <b><span style={{fontSize : "1.2rem"}}>{modalTitle}</span></b>
                      <p style={{marginTop: "10px"}}>{errorMessage}</p>
                      <div style={{marginTop: "16px"}}>
                        <button style={{width: "100%", display: "inline-block", background: "#FFC557", borderRadius: "12px", flex: "none", border: "0px", color: "black", padding: "0.7rem", fontSize: "1.2rem"}} onClick={closeModal}>Try Again</button>
                      </div>
                  </div>
                </Modal>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
