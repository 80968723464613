import { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import axios from 'axios';
import clsx from 'clsx';

import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

// Sample app imports
import Error from './error-page';
import { useNavigate } from 'react-router-dom';
import LogoFyxD from '../images/logos/fyxd-logo-color.svg';

export default function PlanConsentPrivacyPage() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const baseURI = process.env.REACT_APP_BASE_URI;

    const [accessToken, setAccessToken] = useState('');
    const [isAcceptConsent, setIsAcceptConsent] = useState(false);

    let userName = '', Email = '';

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    if(email !== undefined) {
        userName = name;
        Email = email;
    }

    const requestAccessToken = async () => {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.idToken);
        }).catch((e) => {
            console.log(e);
        });
    };

    const requestToken = async () => {
        await requestAccessToken();
    };

    const handleChange = (event) => {
        setIsAcceptConsent(event.target.checked);
    };
    
    const handleAccept = async() => {
        if(!isAcceptConsent)
            return;
        // navigate('/planreview');
        try {
            const res = await axios.post(`${baseURI}/famzmember3/v1/fyxd/plan/consent`, 
            { "plan_id": 3, "type": "privacy" }, { headers: { Authorization: 'Bearer ' + accessToken } });

            navigate('/planreview');
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        if(accessToken.length === 0)
            requestToken();
    // eslint-disable-next-line
    }, [Email, accessToken, isAuthenticated]);

    const authRequest = {
        ...loginRequest
    };

  return (
    <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
            <div style={{"width": "100vw", "height": "100vh"}}>
                <Container style={{"height": "70vh"}}>
                      <nav className="w-full">
                        <div className="text-center py-3 border-b border-ggrey-200">
                            <span className="float-left font-semibold text-xl cursor-pointer" onClick={goBack}>{'<'}</span>
                            <span className="text-center text-black font-semibold text-xl">Dai Perm</span>
                        </div>
                      </nav>
                      <div>
                      <div className="mt-3">
                            <img src={LogoFyxD} alt="" /><br/>
                            <span className="text-2xl text-gray-500 font-black mb-3 mt-5">Privacy Policy</span><br/><br/>
                 
                            <span className="mt-3 mb-3">
                                Last updated: 29th Nov 2023
                            </span><br/><br/>
                      
                            <div>
                            <p ><b><span>นโยบายรักษาความเป็นส่วนตัว</span></b></p>

<p ><b><span>บริษัท ดีทีจีโอ คอร์ปอเรชั่น จำกัด</span></b></p>

<p><span>&nbsp;</span></p>

<p><span>บริษัท
ดีทีจีโอ คอร์ปอเรชั่น จำกัด (ดีทีจีโอ) และบริษัทในเครือ คือองค์กรที่มีเป้าหมายและปณิธารในการดำเนินธุรกิจควบคู่กับการตอบแทนสังคม
(“บริษัท”</span><span>, “</span><span>เรา”) ตระหนักถึงความสำคัญของการปกป้องข้อมูลส่วนบุคคล
โดยนโยบายรักษาความเป็นส่วนตัวนี้กำหนดวิธีการรวบรวม ใช้ เปิดเผย
และจัดการข้อมูลส่วนบุคคลของท่าน (รวมกันเรียกว่า “การประมวลผลข้อมูล”)
ไม่ว่าจะเป็นการรวบรวมข้อมูลผ่านเว็บไซต์ของบริษัท แอปพลิเคชัน แพลตฟอร์มออนไลน์อื่น
ๆ หรือ เก็บข้อมูลจากเจ้าของข้อมูลส่วนบุคคลโดยตรงไม่ว่ากรณีใดๆ</span><span>&nbsp;</span></p>

<p><span>นโยบายรักษาความเป็นส่วนตัว
นี้ใช้กับผู้ที่เข้าใช้บริการเว็บไซต์/แอพพลิเคชั่น
ผู้ที่ซื้อสินค้าหรือบริการจากบริษัท รวมถึงผู้ที่เข้ามาในสำนักงานของบริษัท
ผู้ที่เข้าร่วมกิจกรรมใดๆ ของบริษัท ซึ่งในที่นี้รวมเรียกว่า (“ท่าน”</span><span>, “</span><span>เจ้าของข้อมูล”)</span></p>

<p><span>ข้อมูลส่วนบุคคล
หมายถึง ข้อมูลใดๆที่ระบุไปถึงเจ้าของข้อมูลได้ ไม่ว่าทางตรงหรือทางอ้อม ได้แก่
ชื่อ ที่อยู่ รูปภาพโปรไฟล์ อีเมล หมายเลขโทรศัพท์ ข้อมูลอุปกรณ์หรือเครื่องมือ (</span><span>IP Address) </span><span>พฤติกรรมการซื้อของ
การเลือกของ และข้อมูลเกี่ยวกับการดำเนินชีวิต เป็นต้น</span></p>

<p><span>ในกรณีที่ท่านเป็นคู่ค้า
พันธมิตรทางธุรกิจ หรือผู้สมัครงาน
โปรดอ่านนโยบายรักษาความเป็นส่วนตัวในส่วนที่เกี่ยวข้องกับตน</span></p>

<p>ภาพรวมนโยบายรักษาความเป็นส่วนตัว<b><span>&nbsp;</span></b></p>

<p><span>นโยบายรักษาความเป็นส่วนตัวฉบับนี้
ประกอบด้วยหัวข้อดังต่อไปนี้ :</span></p>

<ul >
 <li><span>บริษัทเก็บข้อมูลส่วนบุคคลประเภทใดบ้าง</span></li>
 <li><span>วัตถุประสงค์ที่บริษัทเก็บข้อมูลส่วนบุคคลของท่าน</span></li>
 <li><span>บริษัทรวบรวมข้อมูลส่วนบุคคลของท่านอย่างไร</span></li>
 <li><span>บริษัทประมวลผลข้อมูลส่วนบุคคลของท่านอย่างไร</span></li>
 <li><span>บริษัทแบ่งปันและเปิดเผยข้อมูลส่วนบุคคลของท่านกับบุคคลประเภทใดบ้าง</span></li>
 <li><span>บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาเท่าใด</span></li>
 <li><span>สิทธิและวิธีการใช้สิทธิในฐานะเจ้าของข้อมูลส่วนบุคคล</span></li>
 <li><span>การถอนความยินยอมและผลกระทบที่เป็นไปได้จากการถอนความยินยอม </span><span>&nbsp;&nbsp;</span></li>
 <li><span>บริษัทการคุ้มครองข้อมูลส่วนบุคคลของท่านอย่างไรการเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</span></li>
 <li><span>ท่านจะสามารถติดต่อบริษัทได้อย่างไร</span></li>
</ul>

<p>บริษัทเก็บข้อมูลส่วนบุคคลประเภทใดบ้าง</p>

<p><span>ในการดำเนินการต่างๆ
ของบริษัท บริษัทอาจเก็บรวบรวม ใช้ ข้อมูลส่วนบุคคลหลายหลายประเภทตามรูปแบบความสัมพันธ์ของท่านกับบริษัท
ซึ่งอาจรวมถึงข้อมูลตามประเภทดังต่อไปนี้</span></p>

<p><span>-&nbsp; &nbsp;&nbsp;</span><u><span>ข้อมูลการติดต่อ</span></u><u><span>&nbsp;</span></u><span>รวมถึง ชื่อ รหัสไปรษณีย์ อีเมล
และเบอร์โทรศัพท์</span></p>

<p><span>-&nbsp; &nbsp;&nbsp;</span><u><span>ข้อมูลส่วนตัว</span></u><span>&nbsp;</span><span>รวมถึง เพศ อายุ ข้อมูลบนบัตรที่ออกโดยหน่วยงานของรัฐ
ภาพจากกล้องวงจรปิด &nbsp;</span></p>

<p><span>-&nbsp;&nbsp; &nbsp;</span><u><span>ข้อมูลบัญชีผู้ใช้</span></u><u><span>&nbsp;</span></u><span>รวมถึง ข้อมูลการเข้าสู่ระบบ
อีเมลและข้อมูลอื่น ๆ ที่ท่านได้ให้ข้อมูลผ่านบัญชีผู้ใช้นั้น</span></p>

<p><span>-&nbsp;&nbsp; &nbsp;</span><u><span>ข้อมูลทางการเงิน</span></u><span>&nbsp;</span><span>รวมถึง เงินเดือน เลขบัญชีธนาคารข้อมูลบัตรเครดิต</span></p>

<p><span>-&nbsp; &nbsp;&nbsp;</span><u><span>ข้อมูลการทำธุรกรรม</span></u><span>&nbsp;</span><span>รวมถึง สถานะการชำระเงินประวัติการทำธุรกรรม ใบเสร็จ ใบแจ้งหนี้</span><span>&nbsp;</span></p>

<p><span>-&nbsp; &nbsp;&nbsp;</span><u><span>ข้อมูลการสมัครรับข่าวสาร
หรือกิจกรรมทางการตลาด</span></u><span>&nbsp;</span><span>รวมถึง ข้อมูลความยินยอมในการสมัครรับข่าวสารจากบริษัท บริษัทในเครือ
หรือพันธมิตรทางธุรกิจ</span><span>&nbsp;</span></p>

<p><span>-&nbsp;&nbsp; <u>&nbsp;</u></span><u><span>ข้อมูลพฤติกรรมการใช้บริการ</span></u><span>&nbsp;</span><span>รวมถึง เช่น
ข้อมูลเกี่ยวกับการใช้งานของลูกค้าบนเว็บไซต์หรือแพลตฟอร์มใดๆ
การใช้ผลิตภัณฑ์และบริการ ตำแหน่งของท่านในการเข้าเยี่ยมชมโครงการ
ข้อมูลเกี่ยวกับความชอบ หรือ ความคิดเห็นของท่าน</span><span>&nbsp;&nbsp;
&nbsp;&nbsp;</span></p>

<p><span>-&nbsp;&nbsp; &nbsp;</span><u><span>ข้อมูลที่รวบรวมโดยระบบอิเล็กทรอนิกส์และเทคโนโลยีอื่นๆ
ที่คล้ายคลึงกัน</span></u><span>&nbsp;</span><span>รวมถึง
ล็อคไฟล์ </span><span>IP Address </span><span>ซึ่งข้อมูลประเภทนี้จะถูกรวบรวมในขณะที่ใช้แพลตฟอร์มออนไลน์</span><span>&nbsp;</span></p>

<p><span>ในบางกรณีบริษัทอาจเก็บข้อมูลอ่อนไหว
รวมถึง ศาสนา เชื้อชาติ ชาติพันธุ์ และประวัติอาชญากรรม
ซึ่งบริษัทจะขอความยินยอมจากท่านโดยชัดแจ้งเท่านั้น ทั้งนี้
เว้นแต่กฎหมายจะอนุญาตให้เก็บรวบรวมโดยไม่ต้องขอความยินยอม</span></p>

<p><span>หากท่านได้ให้ข้อมูลส่วนบุคคลของบุคคลที่สามแก่บริษัท
บริษัทจะถือว่าท่านมีอำนาจที่จะให้ข้อมูลส่วนบุคคลของบุคคลที่สาม
และบุคคลที่สามได้อนุญาตให้บริษัทใช้ข้อมูลส่วนบุคคลตามวัตถุประสงค์ที่ระบุไว้ในนโยบายฉบับนี้</span><span>&nbsp;</span></p>

<p>วัตถุประสงค์ที่บริษัทเก็บ ใช้ เปิดเผย ข้อมูลส่วนบุคคลของท่าน</p>

<ul >
 <li><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญาหรือเพื่อปฏิบัติตามสัญญาซึ่งท่านเป็นคู่สัญญากับเรา
     เช่น เมื่อท่านเข้าร่วมกิจกรรมของเรา หรือ
     เมื่อท่านเข้ามาติดต่อเราเพื่อให้ดำเนินการใด ๆ
     รวมถึงกรณีท่านลงทะเบียนขอรับข้อมูลและโปรโมชัน ซึ่งบริษัทจะจัดเก็บและใช้เฉพาะข้อมูลพื้นฐาน
     เช่น ชื่อ-นามสกุล ที่อยู่ไปรษณีย์อิเล็กทรอนิกส์ (</span><span>Email address) </span><span>หมายเลขโทรศัพท์เพื่อใช้สำหรับติดต่อกลับ</span><span>&nbsp;</span></li>
 <li><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อการแก้ไข
     ปรับปรุงประสิทธิภาพการทำงานของเว็บไซต์และแอปพลิเคชั่น การจัดทำแผนการตลาด
     วิเคราะห์ข้อมูลการใช้ ประเมินการบริการ รวมถึงการปรับปรุง
     พัฒนาผลิตภัณฑ์และการบริการของบริษัท</span></li>
 <li><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อติดต่อสื่อสาร
     แจ้งข้อมูลข่าวสารต่างๆ ที่เกี่ยวข้องกับผลิตภัณฑ์และบริการของบริษัท
     รวมถึงสิทธิประโยชน์ โปรโมชันที่เกี่ยวข้องกับผลิตภัณฑ์ และบริการดังกล่าว
     หรือการเปลี่ยนแปลงใดๆ ที่เกี่ยวกับนโยบายการดูแลรักษาข้อมูลส่วนบุคคลในอนาคต</span></li>
 <li><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อดำเนินการตามสัญญาที่ท่านเป็นคู่สัญญา
     รวมถึงกับคู่สัญญาที่บริษัทใช้บริการ เช่น
     แบ่งปันข้อมูลตำแหน่งพื้นที่ของท่าน
     กรณีบริษัทใช้บริการเพื่อการระบุตำแหน่งพื้นที่ของบุคคลอื่น</span></li>
 <li><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อดำเนินการตามกฎหมาย
     เช่น เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
     การศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสม
     ทั้งนี้เพื่อคุ้มครองสิทธิและเสรีภาพของท่าน</span></li>
 <li><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อวัตถุประสงค์อื่นที่ได้แจ้งขณะเก็บข้อมูลส่วนบุคคลของท่าน
     หรือวัตถุประสงค์อื่นที่เกี่ยวข้องกับข้อหนึ่งข้อใดข้างต้น</span></li>
</ul>

<p><span>บริษัทจะไม่ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
เว้นแต่ เป็นการใช้หรือเปิดเผยเท่าที่จำเป็นตามวัตถุประสงค์ที่ระบุไว้ข้างต้น
หรือเป็นการเปิดเผยต่อบุคคลในบริษัท หรือบุคคลที่เกี่ยวข้องตามสัญญา
หรือดำเนินการตามกฎหมาย หน่วยงานภาครัฐ หน่วยงานกำกับดูแล หรือได้รับความยินยอมจากท่าน</span></p>

<p>บริษัทรวบรวมข้อมูลส่วนบุคคลของท่านอย่างไร</p>

<p><span>บริษัทรวบรวมข้อมูลส่วนบุคคลของท่านโดยตรงหรือโดยอ้อมจากแหล่งข้อมูลอื่น
เช่น</span><span>&nbsp;</span></p>

<ul >
 <li><span>&nbsp; &nbsp;&nbsp;</span><u><span>แพลตฟอร์มออนไลน์</span></u><u><span>&nbsp;</span></u><span>บริษัทอาจรวบรวมข้อมูลส่วนบุคคลของท่าน
     เมื่อท่านลงทะเบียนบัญชีในระบบอินเทอร์เน็ต (</span><span>User Account) </span><span>การทำแบบสำรวจ
     การประกวด หรือการกรอกข้อมูลเพื่อรับข้อเสนอโปรโมชั่น
     การกดติดตามเพื่อรับข้อมูลการส่งเสริมการขาย หรือเพื่อเชื่อมต่อกับบริษัท</span><span>&nbsp;</span></li>
 <li><span>&nbsp; &nbsp;&nbsp;</span><u><span>การโต้ตอบสื่อสารออฟไลน์</span></u><span>&nbsp;</span><span>บริษัทอาจรวบรวมข้อมูลส่วนบุคคลของท่านจากทางออฟไลน์
     เช่น เมื่อท่านเข้ามาในสำนักงานของบริษัท เมื่อมีการติดต่อกันผ่านทางโทรศัพท์
     หรือเมื่อท่านเข้าร่วมกิจกรรมของบริษัท หรือการให้นามบัตร</span></li>
 <li><span>&nbsp; &nbsp;&nbsp;</span><u><span>บริษัทในเครือหรือพันธมิตรทางธุรกิจ</span></u><span>&nbsp; </span><span>ในกรณีนี้บริษัทพิจารณาและจะจัดให้มีมาตรการที่เหมาะสมเพื่อให้มั่นใจว่าบริษัทสามารถดำเนินการดังกล่าวได้อย่างไม่ขัดต่อกฎหมาย</span></li>
 <li><span>&nbsp; &nbsp;&nbsp;</span><u><span>แหล่งข้อมูลที่เปิดเผยแบบสาธารณะ</span></u><span>&nbsp;</span><span>เช่น เว็บไซต์สาธารณะ</span><span>&nbsp;</span></li>
</ul>

<p>บริษัทประมวลผลข้อมูลส่วนบุคคลของท่านอย่างไร</p>

<p><span>โดยทั่วไปแล้วบริษัทจะประมวลผลข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นและมีฐานทางกฎหมายในการประมวลผลข้อมูลส่วนบุคคลเท่านั้น
ซึ่งฐานทางกฎหมายดังกล่าว สามารถจำแนกได้ดังต่อไปนี้</span></p>

<p><b><span>1)&nbsp; &nbsp;&nbsp;</span></b><b><span>ฐานความยินยอม</span></b></p>

<ul >
 <li><span>&nbsp;</span><u><span>การสื่อสารกิจกรรมทางการตลาด</span></u></li>
</ul>

<p><span>&nbsp; &nbsp;&nbsp;</span><span>บริษัทจะขอความยินยอมจากท่านกรณีมีการเก็บรวบรวมข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ในการทำการตลาด
และติดต่อสื่อสารทางการตลาด เช่น การเสนอข้อเสนอพิเศษเกี่ยวกับผลิตภัณฑ์
และบริการของบริษัท บริษัทในเครือ หรือพันธมิตรทางธุรกิจ
ซึ่งบริษัทไม่สามารถอาศัยฐานทางกฎหมายอื่นได้</span><span>&nbsp;</span></p>

<ul >
 <li><span>&nbsp;</span><u><span>การส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ</span></u></li>
</ul>

<p><span>&nbsp; &nbsp;&nbsp;</span><span>บริษัทจะขอความยินยอมจากท่านกรณีที่บริษัทจะส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังประเทศที่มีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลต่ำกว่ามาตรฐานในประเทศไทย</span></p>

<ul >
 <li><u><span>&nbsp;</span></u><u><span>การเก็บข้อมูลอ่อนไหว</span></u><u><span>&nbsp;</span></u></li>
</ul>

<p><span>&nbsp; &nbsp;&nbsp;</span><span>เมื่อท่านได้ให้ข้อมูลอ่อนไหวแก่บริษัท
หมายความว่า
ท่านได้ให้ความยินยอมแก่บริษัทเพื่อใช้ข้อมูลอ่อนไหวสำหรับวัตถุประสงค์
ดังต่อไปนี้</span><span>&nbsp;</span></p>

<p><span>(</span><span>ก)</span><span>&nbsp; &nbsp;&nbsp;</span><span>ข้อมูลสุขภาพ เช่น โรคประจำตัว หมู่โลหิต ประวัติการแพ้อาหาร
เป็นต้น: เพื่อประโยชน์ในการจัดเตรียมอาหารสำหรับงานอีเว้นท์ต่างๆ</span><span>&nbsp;</span></p>

<p><span>(</span><span>ข)</span><span>&nbsp; &nbsp;&nbsp;</span><span>ศาสนา: เพื่อประโยชน์ในการจัดหาสิ่งอำนวยความสะดวกให้เหมาะสมกับท่าน</span></p>

<p><span>(</span><span>ค)</span><span>&nbsp; &nbsp;&nbsp;</span><span>เชื้อชาติ หรือสัญชาติ: เพื่อประโยชน์ในการทำรายงานทางสถิติ</span><span>&nbsp;</span></p>

<p><span>(</span><span>ง)</span><span>&nbsp; &nbsp;&nbsp;</span><span>ประวัติอาชญากรรม: เพื่อป้องกันการทุจริตและรักษาความปลอดภัย</span></p>

<p><span>&nbsp; &nbsp;&nbsp;</span><span>โปรดทราบว่าข้อมูลอ่อนไหวของท่านอาจถูกใช้เพื่อวัตถุประสงค์ในเชิงสถิติเพื่อพัฒนาสินค้าหรือบริการให้เหมาะสมและดียิ่งขึ้น
แต่การใช้ข้อมูลอ่อนไหวจะถูกใช้ในลักษณะของข้อมูลที่ถูกทำให้ไม่สามารถระบุตัวตนได้</span><span>&nbsp;</span></p>

<p><b><span>2)&nbsp;&nbsp; &nbsp;&nbsp;</span></b><b><span>ฐานทางสัญญาระหว่างท่านและบริษัท</span></b></p>

<p><span>บริษัทอาจประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อดำเนินการตามคำขอเข้าทำสัญญาของท่าน
หรือปฏิบัติตามสัญญาระหว่างกันทั้งแบบเป็นลายลักษณ์อักษรและไม่เป็นลายลักษณ์อักษร
ซึ่งรวมถึการดำเนินการดังต่อไปนี้</span></p>

<p><span>(</span><span>ก)</span><span>&nbsp; &nbsp;&nbsp;</span><span>พิจารณาความคุณสมบัติและความเหมาะสมของท่านก่อนเข้าทำสัญญา
หรือให้บริการแก่ท่าน</span><span>&nbsp;</span></p>

<p><span>(</span><span>ข)</span><span>&nbsp; &nbsp;&nbsp;</span><span>ดำเนินการใดๆ ที่เกี่ยวกับหน้าที่ตามสัญญาของท่าน เช่น
ติดต่อท่านเพื่อให้ปฎิบัติตามสัญญา ส่งเอกสารที่เกี่ยวข้องกับสัญญา</span></p>

<p><span>(</span><span>ค)</span><span>&nbsp; &nbsp;&nbsp;</span><span>ยืนยันตัวตนของท่านเมื่อท่านต้องการใช้สิทธิของท่านตามสัญญาที่มีไว้กับบริษัท</span></p>

<p><b><span>3)&nbsp; &nbsp;&nbsp;</span></b><b><span>ฐานประโยชน์โดยชอบด้วยกฎหมาย</span></b></p>

<p><span>บริษัทอาจประมวลผลข้อมูลส่วนบุคคลของท่านโดยพิจารณาประโยชน์โดยชอบด้วยกฎหมายของบริษัท
หรือบุคคลภายนอก ทั้งนี้ บริษัทจะพิจารณาถึงความจำเป็น
ความสมดุลของประโยชน์ดังกล่าวกับสิทธิขั้นพื้นฐานของเจ้าของข้อมูลส่วนบุคคล
ซึ่งรวมถึงวัตถุประสงค์ต่อไปนี้</span></p>

<p><span>(</span><span>ก)</span><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อรักษาความปลอดภัย เช่น การเก็บข้อมูล/แลกบัตรประชาชน
ผู้เข้าเยี่ยมบริษัทหรือโครงการของบริษัท การเก็บภาพวงจรปิด (</span><span>CCTV)</span></p>

<p><span>(</span><span>ข)</span><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อการบริหารจัดการภายในของบริษัท และกลุ่มบริษัทดีทีจีโอ
ซึ่งรวมถึง ในกรณีที่บริษัทหรือบริษัทในกลุ่มดีทีจีโอเข้าควบรวม
หรือปรับโครงสร้างองค์กร หรือเหตุการณ์อื่นใดที่คล้ายคลึงกัน
บริษัทอาจส่งข้อมูลของท่านไปยังบุคคลภายนอก ที่มีส่วนเกี่ยวข้องกับการทำธุรกรรมนั้นภายใต้หลักความจำเป็น</span></p>

<p><span>(</span><span>ค)</span><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อการปรับปรุงและพัฒนาสินค้าและการบริการต่างๆ
เพื่อประโยชน์ของลูกค้าของบริษัทและลูกค้าทั้งหมดของกลุ่มบริษัทดีทีจีโอ</span><span>&nbsp;</span></p>

<p><span>(</span><span>ง)</span><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อสื่อสารและทำการตลาด
ในกรณีที่บริษัทเห็นว่าการสื่อสารทำการตลาดนั้นไม่กระทบต่อสิทธิขั้นพื้นฐานของเจ้าของข้อมูลส่วนบุคคลอย่างมีนัยสำคัญ
และเจ้าของข้อมูลส่วนบุคคลมีสิทธิขอยกเลิกการรับข่าวสารหรือการทำการตลาดได้ทุกเมื่อ</span></p>

<p><span>(</span><span>จ)</span><span>&nbsp; &nbsp;&nbsp;</span><span>การบันทึกภาพถ่าย ภาพเคลื่อนไหว และ/หรือ เสียง
เมื่อบริษัทจัดงานอีเว้นท์ สัมมนา หรือกิจกรรมส่งเสริมการขายอื่นๆ
โดยภาพถ่ายดังกล่าวมีลักษณะเป็นการเก็บภาพบรรยากาศของงาน</span></p>

<p><span>(</span><span>ฉ)</span><span>&nbsp; &nbsp;&nbsp;</span><span>กรณีผู้ที่มีความสัมพันธ์กับบริษัทเป็นนิติบุคคล
บริษัทมีความจำเป็นต้องเก็บข้อมูลส่วนบุคคลของบุคคลที่เกี่ยวข้อง รวมถึง
สำเนาบัตรประชาชนหรือข้อมูลติดต่อของผู้มีอำนาจกระทำการแทนนิติบุคคลนั้นๆ</span><span>&nbsp;</span></p>

<p><span>(</span><span>ง)</span><span>&nbsp; &nbsp;&nbsp;</span><span>เราอาจเก็บข้อมูลที่เกี่ยวข้องกับการขาย รวมถึง
ประวัติการโต้ตอบสื่อสารกับลูกค้า จำนวนการขาย บันทึกการนัดหมายลูกค้า
หรือการโทรศัพท์ติดต่อกับลูกค้า เป็นต้ เพื่อประโยชน์ในการวิเคราะห์ประสิทธิภาพ
และปรับปรุงการดำเนินงาน</span><span>&nbsp;</span></p>

<p><b><span>4)&nbsp; &nbsp;&nbsp;</span></b><b><span>ฐานทางกฎหมายอื่นๆ
ที่เราใช้เพื่อการประมวลผลข้อมูลส่วนบุคคลของท่าน</span></b></p>

<p><span>นอกเหนือจากฐานทางกฎหมายตามข้อ
</span><span>1. – 3. </span><span>บริษัทอาจประมวลผลข้อมูลส่วนบุคคลของท่านโดยอาศัยฐานทางกฎหมายอื่นๆ
คือ ฐานหน้าที่ตามกฎหมายของบริษัท
ฐานความจำเป็นเพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
และ &nbsp;ฐานจำเป็นเพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจเพื่อประโยชน์สาธารณะของบริษัท
หรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้แก่บริษัท
ซึ่งการประมวลผลด้วยฐานทางกฎหมายอื่นๆ นั้น รวมถึงกรณีดังต่อไปนี้</span></p>

<p><span>(</span><span>ก)</span><span>&nbsp; &nbsp;&nbsp;</span><span>เพื่อปฏิบัติตามข้อกำหนดเรื่องการรายงานงบการเงินของบริษัทที่กำหนดโดยผู้สอบบัญชี
และ/หรือหน่วยงานของรัฐ และเพื่อให้ความร่วมมือกับ เจ้าพนักงานที่บังคับใช้กฎหมาย
หน่วยงานราชการ หน่วยงานกำกับดูแล และ/หรือ
คำสั่งศาลที่เกี่ยวข้องกับกระบวนการพิจารณาคดีหรือการสืบสวนในคดี</span></p>

<p><span>(</span><span>ข)</span><span>&nbsp; &nbsp;&nbsp;</span><span>การจัดเก็บข้อมูลการจราจรทางคอมพิวเตอร์ (</span><span>Log
file) </span><span>ตามกฎหมายว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์</span></p>

<p><span>อย่างไรก็ตาม
บริษัทอาจใช้ข้อมูลส่วนบุคคลที่ผ่านกระบวนการทำให้เป็นข้อมูลนิรนาม (</span><span>Anonymization) </span><span>เพื่อวัตถุประสงค์อื่นใด
โดยไม่ขอความยินยอมจากท่าน</span></p>

<p>บริษัทแบ่งปันและเปิดเผยข้อมูลส่วนบุคคลของท่านกับบุคคลประเภทใดบ้าง</p>

<p><span>บริษัทอาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลของท่านไปยังผู้รับตามประเภทผู้รับที่ระบุข้างล่างนี้เพื่อให้บรรลุวัตถุประสงค์ที่ระบุไว้ในนโยบายฉบับนี้
โดยบุคคลที่สามผู้รับข้อมูลนั้นอาจอยู่หรือมีเซิร์ฟเวอร์ตั้งอยู่ในประเทศไทยและต่างประเทศ
&nbsp;ซึ่งประเภทผู้รับข้อมูลส่วนบุคคลมีดังต่อไปนี้ &nbsp;(ในแต่ละประเภท
ให้รวมไปถึงตัวแทน พนักงานและกรรมการของบริษัท</span></p>

<p><span>(</span><span>ก.)</span><span>&nbsp; &nbsp;&nbsp;</span><span>กลุ่มบริษัทดีทีจีโอ</span></p>
<p><span>เนื่องจากบริษัท
ดีทีจีโอ คอร์ปอเรชั่น จำกัด
เป็นบริษัทหนึ่งในกลุ่มบริษัทดีทีจีโอซึ่งโดยลักษณะการบริหารงานภายในกลุ่มบริษัท
เราอาจต้องส่งหรือเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บริษัทอื่นๆ
ในกลุ่มบริษัทดีทีจีโอเพื่อให้บรรลุวัตถุประสงค์ภายใต้นโยบายรักษาความเป็นส่วนตัว
ฉบับนี้โดยบริษัทจะทำให้มั่นใจว่าข้อมูลส่วนบุคคลของท่านจะถูกโอนไปอย่างปลอดภัย
และมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เหมาะสม ทั้งนี้
โดยลักษณะการแบ่งปันข้อมูลระหว่างกันจะเป็นไปตามความจำเป็นในแต่ละประเภทธุรกิจดัง</span><span><a href="https://www.dtgo.com/company-list"
target="_blank" rel="noreferrer"><span>ตารางที่แนบมาด้วย</span></a></span><span>&nbsp;</span></p>

<p><span>(</span><span>ข.)</span><span>&nbsp; &nbsp;&nbsp;</span><span>ผู้ให้บริการภายนอก</span></p>

<p><span>บริษัทอาจแบ่งปันข้อมูลของท่านไปยังผู้ให้บริการภายนอกในกรณีที่บริษัทว่าจ้างหรือแต่งตั้งบุคคลภายนอกให้ดำเนินการประมวลผลแทน/ในนามของบริษัทเพื่อให้เป็นไปตามวัตถุประสงค์ที่ระบุไว้ในนโยบายฉบับนี้
เช่น เพื่อการบริหารงานเว็บไซต์ การส่งสินค้าหรือบริการ การทำการตลาด
การให้บริการพื้นที่จัดเก็บข้อมูล ผู้ให้บริการด้านเทคโนโลยี
การให้บริการระบบชำระเงิน เป็นต้น</span><span>&nbsp;</span></p>

<p><span>(</span><span>ค.)</span><span>&nbsp; &nbsp;&nbsp;</span><span>พันธมิตรทางธุรกิจ</span></p>

<p><span>บริษัทอาจต้องแบ่งปันข้อมูลส่วนบุคคลของท่านให้แก่พันธมิตรทางธุรกิจ
ในกรณีที่มีการจัดกิจกรรมสนับสนุนการขายร่วมกัน
หรือกรณีที่บริษัทเสนอสินค้าหรือบริการของพันธมิตรให้แก่ท่าน</span></p>

<p><span>(</span><span>ง.)</span><span>&nbsp; &nbsp;&nbsp;</span><span>บุคคลภายนอกที่มีกฎหมายบังคับให้เปิดเผย</span></p>

<p><span>บริษัทอาจจำเป็นที่จะต้องเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านเพื่อปฏิบัติตามกฎหมาย
หรือระเบียบบังคับอื่นๆ ซึ่งรวมถึง การเปิดเผยให้แก่หน่วยงานที่มีอำนาจตามกฎหมาย
ศาล หรือบุคคลอื่นใด ที่มีกฎหมายกำหนดหน้าที่อย่างชัดเจน
หรือเป็นกรณีที่มีเหตุน่าเชื่อว่าการเปิดเผยข้อมูลดังกล่าวเป็นกรณีที่จำเป็นเพื่อปกป้องสิทธิของบริษัท
หรือบุคคลภายนอก</span><span>&nbsp;</span></p>

<p><span>ในกรณีที่บริษัทเปิดเผยหรือโอนข้อมูลส่วนบุคคลไปยังบุคคลใดๆ
นอกเหนือจากในกลุ่มบริษัทดีทีจีโอ บริษัทจะทำข้อตกลงกับบุคคลภายนอก
เพื่อกำหนดวัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล
และกำหนดหน้าที่ในการปกป้องข้อมูลจากการใช้ข้อมูลโดยไม่ได้รับอนุญาต
หรือโดยไม่เจตนา เพื่อกำหนดความสามารถในการเข้าถึงข้อมูล การเปิดเผยข้อมูล
ความเสียหาย สูญหาย หรือถูกทำลายของข้อมูล</span></p>

<p><span>หากมีการโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอก
หรือเซิร์ฟเวอร์ที่ตั้งอยู่ในต่างประเทศ บริษัทจะดำเนินการใดๆ
ที่จำเป็นเพื่อให้มั่นใจว่าข้อมูลส่วนบุคคลจะถูกโอนไปอย่างปลอดภัยและมีมาตรการคุ้มครองข้อมูลส่วนบุคคลที่เหมาะสม</span></p>

<p>บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านเป็นระยะเวลาเท่าใด</p>

<p><span>บริษัทจะเก็บข้อมูลส่วนบุคคลใดๆ
เท่าที่จำเป็นเพื่อทำตามวัตถุประสงค์ตามที่ได้แจ้งไว้ ณ ขณะเก็บข้อมูล
หรือตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. </span><span>2562
</span><span>หรือกฎหมายอื่นๆ กำหนด</span></p>

<p><span>ทั้งนี้
บริษัทได้เตรียมตารางเวลาที่ระบุระยะเวลาการเก็บรักษาที่เหมาะสม
เพื่อให้ทำให้ท่านมั่นใจได้ว่า
เมื่อข้อมูลส่วนบุคคลของท่านไม่มีความจำเป็นสำหรับการดำเนินการตามวัตถุประสงค์
บริษัทจะดำเนินการลบข้อมูลของท่านออกจากระบบและออกจากการบันทึกของบริษัทและ/หรือทำตามกระบวนการที่ทำให้ข้อมูลนั้นไม่สามารถระบุตัวบุคคลได้</span></p>

<p><span>&nbsp;</span></p>

<p><b><span>ข้อมูลที่เกี่ยวข้องกับผู้เข้าร่วมกิจกรรม</span></b></p>

<table className="border-collapse border border-slate-500">
 <tr>
  <td className="border border-slate-600">
    <p><b><span>ลำดับที่</span></b></p>
  </td>
  <td className="border border-slate-600">
    <p><b><span>ประเภทของข้อมูล</span></b></p>
  </td>
  <td className="border border-slate-600">
    <p><b><span>ระยะเวลาที่ทำการเก็บ</span></b></p>
  </td>
 </tr>
 <tr>
  <td className="border border-slate-600">
    <p><span>1</span></p>
  </td>
  <td className="border border-slate-600">
    <p><span>ข้อมูลการติดต่อ รวมถึง ชื่อ นามสกุล รหัสไปรษณีย์ อีเมล
    และเบอร์โทรศัพท์</span></p>
  </td>
  <td >
    <p><span>บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นระยะเวลานานเท่าที่จำเป็นตามวัตถุประสงค์ในการประมวลผลของบริษัท
    ข้อมูลส่วนบุคคลของท่านนับแต่วันที่ข้อมูลนั้นเข้าสู่ระบบคอมพิวเตอร์ของบริษัท
    เว้นแต่บริษัทจะได้รับคำสั่งจากพนักงานเจ้าหน้าที่ตามกฎหมายให้เก็บต่อไป แต่ทั้งนี้ไม่เกินกว่าระยะเวลา
    </span><span>3 </span><span>ปี</span></p>
  </td>
 </tr>
 <tr>
  <td className="border border-slate-600">
  <p><span>2</span></p>
  </td>
  <td className="border border-slate-600">
  <p>
    <span>
        ข้อมูลบัญชีผู้ใช้
  รวมถึง ข้อมูลการเข้าสู่ระบบ อีเมลและข้อมูลอื่น ๆ
  ที่ท่านได้ให้ข้อมูลผ่านบัญชีผู้ใช้นั้น
    </span>
 </p>
  </td>
 </tr>
 <tr>
  <td className="border border-slate-600">
  <p><span>3</span></p>
  </td>
  <td className="border border-slate-600">
  <p><span>ข้อมูลการสมัครรับข่าวสาร หรือกิจกรรมทางการตลาด รวมถึง ข้อมูลความยินยอมในการสมัครรับข่าวสารจากบริษัท
  &nbsp;บริษัทในเครือ หรือพันธมิตรทางธุรกิจ</span></p>
  </td>
 </tr>
 <tr>
  <td className="border border-slate-600">
  <p><span>4</span></p>
  </td>
  <td className="border border-slate-600">
  <p><span
 >บันทึกภาพถ่าย
  ภาพเคลื่อนไหว และ/หรือ เสียง เมื่อบริษัทจัดงานอีเว้นท์ สัมมนา
  หรือกิจกรรมส่งเสริมการขายอื่นๆ โดยภาพถ่ายดังกล่าวมีลักษณะเป็นการเก็บภาพบรรยากาศของงาน</span></p>
  </td>
 </tr>
</table>

<p><span>&nbsp;</span></p>

<p>สิทธิและวิธีการใช้สิทธิของท่าน</p>

<p><span>ภายใต้พระราชบัญญัติข้อมูลส่วนบุคคล
พ.ศ. </span><span>2562 &nbsp;</span><span>ท่านในฐานะเจ้าของข้อมูลส่วนบุคคลมีสิทธิในการดำเนินการใดๆ
เกี่ยวกับข้อมูลส่วนบุคคลของท่าน ดังต่อไปนี้</span></p>

<p><span>1.&nbsp;&nbsp; &nbsp;&nbsp;</span><span>สิทธิในการรับสำเนาข้อมูลส่วนบุคคล
และรับข้อมูลอื่น ๆ เช่น
วิธีการที่บริษัทรวบรวมหรือประมวลผลข้อมูลส่วนบุคคลของท่าน
และเหตุผลที่บริษัทประมวลผลข้อมูลส่วนบุคคลของท่าน
โดยทั่วไปท่านจะได้รับข้อมูลส่วนบุคคลของท่านในรูปแบบอิเล็กทรอนิกส์</span></p>

<p><span>2.&nbsp;&nbsp; &nbsp;&nbsp;</span><span>สิทธิในการแก้ไขข้อมูลส่วนบุคคลที่ไม่ถูกต้อง
ไม่สมบูรณ์ หรือทำมูลที่ทำให้เกิดความเข้าใจผิดเกี่ยวกับท่าน</span></p>

<p><span>3.&nbsp;&nbsp; &nbsp;&nbsp;</span><span>สิทธิในการขอลบข้อมูล
ทำลายข้อมูล หรือทำให้ข้อมูลนั้นไม่สามารถระบุตัวบุคคลได้</span></p>

<p><span>4.&nbsp;&nbsp; &nbsp;&nbsp;</span><span>สิทธิในการขอระงับการใช้ข้อมูลส่วนบุคคลของท่าน</span></p>

<p><span>5.&nbsp; &nbsp;&nbsp;</span><span>สิทธิในการโอนหรือขอให้บริษัทโอนข้อมูลส่วนบุคคลไปยังผู้ควบคุมข้อมูลอื่น
ตราบเท่าที่พื้นฐานทางกฎหมายในการประมวลผลข้อมูลส่วนบุคคลนั้นกำหนดอำนาจให้ดำเนินการได้
หรือจำเป็นสำหรับการปฏิบัติงานตามสัญญาที่ได้ทำไว้กับท่าน
และการประมวลผลสามารถดำเนินการได้โดยวิธีอัตโนมัติ</span></p>

<p><span>6.&nbsp;&nbsp; &nbsp;&nbsp;</span><span>สิทธิในการโต้แย้งคัดค้านการดำเนินการใด
ๆ ซึ่งเป็นไปตามวัตถุประสงค์ที่ถูกต้องตามกฎหมาย หรือพื้นฐานทางกฎหมายอื่น ๆ</span></p>

<p><span>7.&nbsp;&nbsp; &nbsp;&nbsp;</span><span>สิทธิในการถอนความยินยอม
ท่านสามารถถอนความยินยอมที่เคยให้ไว้กับบริษัทในเวลาใดก็ได้
อย่างไรก็ตามบริษัทอาจดำเนินการเก็บ ใช้
หรือเปิดเผยข้อมูลส่วนบุคคลของท่านตามหลักเกณฑ์ทางกฎหมายอื่น</span></p>

<p><span>หากท่านต้องการใช้สิทธิของท่าน
สอบถามข้อสักถาม
หรือร้องเรียนเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลที่ไม่เป็นไปตามกฎหมาย
ท่านสามารถส่งคำร้องขอใช้สิทธิของท่านมายังอีเมล </span><span>DPO@DTGO.com
</span><span>หรือ กรอกคำร้องขอออนไลน์
โดยบริษัทอาจขอให้ท่านระบุตัวตนของท่านก่อนที่บริษัทจะให้บริการตามคำขอ
บริษัทจะดำเนินการตอบกลับเป็นลายลักษณ์อักษรทางอีเมลทันทีที่สามารถดำเนินการได้
ซึ่งโดยปกติจะไม่เกิน </span><span>30 </span><span>วันนับแต่วันที่ได้รับคำร้องขอใช้สิทธิ
พร้อมข้อมูลประกอบที่ชัดเจนและเพียงพอจากท่าน</span></p>

<p><span>อย่างไรก็ตาม
บริษัทมีสิทธิที่จะปฏิเสธคำร้องขอใช้สิทธิของท่านในกรณีที่การใช้สิทธิดังกล่าวเข้าข้อยกเว้นที่กฎหมายกำหนด
ทั้งนี้ โดยทั่วไปแล้วบริษัทจะไม่คิดค่าธรรมเนียมในการจัดการคำร้องขอใช้สิทธิ
แต่หากบริษัทพิจารณาแล้วเห็นว่าคำร้องขอใช้สิทธิของท่านไม่มีเหตุอันควรหรือซับซ้อน
บริษัทขอสงวนสิทธิเรียกเก็บค่าธรรมเนียมในการดำเนินการตามคำร้องขอใช้สิทธิตามสมควร</span></p>

<p><span>นอกจากนี้
ท่านมีสิทธิยื่นคำร้องต่อคณะกรรมการคุ้มครองข้อมูล
เมื่อบริษัทปฏิเสธคำร้องขอใช้สิทธิของท่าน
และท่านเห็นว่าการปฏิเสธนั้นไม่มีเหตุผลสมควร
หรือเมื่อบริษัทไม่ได้ตอบรับคำร้องขอท่านภายในระยะเวลาที่กำหนดไว้</span></p>

<p>การถอนความยินยอมและผลกระทบที่เป็นไปได้จากการถอนความยินยอม</p>

<p><span>ในกรณีที่บริษัทเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลโดยอาศัยความยินยอมของท่าน
ท่านมีสิทธิที่จะถอนความยินยอมของท่านที่ให้ไว้กับบริษัทได้ตลอดเวลา
ซึ่งการถอนความยินยอมนี้จะไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้ เปิดเผย
หรือประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมไปแล้ว</span></p>

<p><span>หากท่านถอนความยินยอมที่ได้ให้ไว้กับบริษัทหรือปฏิเสธไม่ให้ข้อมูลบางอย่าง
อาจส่งผลให้บริษัทไม่สามารถดำเนินการเพื่อบรรลุวัตถุประสงค์บางส่วนหรือทั้งหมดตามที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ได้</span></p>

<p><span>ในกรณีที่บริษัทจำเป็นต้องได้รับความยินยอมจากบุคคลอื่น
ท่านรับรองว่าท่านมีอำนาจกระทำการแทนเจ้าของข้อมูลส่วนบุคคลในการรับทราบนโยบายความเป็นส่วนตัวนี้และในการให้ความยินยอมแก่บริษัทในการประมวลผลข้อมูลส่วนบุคคล</span></p>

<p>บริษัทคุ้มครองข้อมูลส่วนบุคคลของท่านอย่างไร</p>

<p><span>บริษัทได้จัดเตรียมมาตรการรักษาความปลอดภัยทั้งเชิงการบริหารจัดการองค์กรและเชิงเทคนิคอย่างเหมาะสม
เพื่อป้องกันข้อมูลส่วนบุคคลจากการประมวลผลโดยไม่ได้รับอนุญาต
หรือไม่ชอบด้วยกฎหมาย ป้องกันการทำลาย การสูญหาย เสียหายหรือการเปลี่ยนแปลงแก้ไข
โดยอุบัติเหตุหรือไม่ชอบด้วยกฎหมาย ยังรวมถึงป้องกันการใช้ การเปิดเผย
หรือการเข้าถึงข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต เช่น
จำกัดกลุ่มบุคคลที่สามารถเข้าถึงข้อมูลส่วนบุคคล
จำกัดประเภทข้อมูลส่วนบุคคลที่สามารถเข้าถึงได้ กำหนดมาตรการทางวินัยกรณีมีการใช้
หรือเปิดเผยข้อมูลโดยไม่ชอบ เป็นต้น</span><span>&nbsp;</span></p>

<p><span>&nbsp;</span></p>

<p>การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</p>

<p><span>บริษัทขอสงวนสิทธิในการปรับปรุงนโยบายนี้ไม่ว่าด้วยเหตุผลใดตามที่เห็นสมควร
รวมถึงในกรณีที่เป็นการปฏิบัติตามกฎหมาย นโยบายของรัฐบาล กฎ ระเบียบ
และข้อผูกพันอื่น ๆ ที่มีลักษณะคล้ายคลึงกัน &nbsp;เมื่อมีการเปลี่ยนแปลงๆ
ใดจะถูกประกาศในเว็บไซต์ของบริษัทที่</span><span>&nbsp;</span><span><a href="https://www.dtgo.com/privacy"
target="_blank" rel="noreferrer"><span>https://www.dtgo.com/privacy</span></a></span><span>โดยการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้จะมีผลบังคับใช้ ณ
วันที่ประกาศนโยบายฉบับใหม่</span><span>&nbsp;</span></p>

<p>ท่านจะสามารถติดต่อบริษัทได้อย่างไร</p>

<p><span>“</span><span>บริษัท”
ถือว่าเป็นผู้ควบคุมข้อมูลภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. </span><span>2562 </span><span>หากท่านมีคำถามใด ๆ
หรือต้องการใช้สิทธิของท่าน ท่านสามารถติดต่อบริษัทได้ตามที่อยู่ดังต่อไปนี้</span></p>

<p>เรียน:<b><span>&nbsp;</span></b><span>&nbsp;</span><span>ฝ่ายคุ้มครองข้อมูลส่วนบุคคล</span></p>

<p>ที่อยู่:<span>&nbsp;&nbsp;</span><span>บริษัท ดีทีจีโอ คอร์ปอเรชั่น จำกัด &nbsp;</span></p>

<p><span>&nbsp; &nbsp; &nbsp; &nbsp; </span><span>เลขที่ 695  หมู่ที่ 12 ตำบลบางแก้ว อำเภอบางพลี จ.สมุทรปราการ 10540</span></p>

<p>อีเมล:<b><span>&nbsp;</span></b><span>&nbsp;DPO@DTGO.com</span></p>

<p>โทร:<b><span>&nbsp;</span></b><span> &nbsp;&nbsp;02-742-9141</span></p>

<p><span>Version 27/6/2564 00:05:18</span></p>

<p><span>&nbsp;</span></p>

                            </div>

                        
                            <div style={{height: "25vh"}}>
                            </div>
                            <div className="mt-3 px-3 py-3 fixed bottom-0 left-0 right-0 w-full bg-white border-t border-ggrey-200">
                                <div className="bg-brown-200 py-3 px-4 rounded-2xl mb-2">
                                    <label className="inline-block">
                                        <span className="inline-block text-sm">
                                            <input
                                                type="checkbox"
                                                checked={isAcceptConsent}
                                                onChange={handleChange}
                                                className="align-top inline-block w-4 h-4 bg-gray-100 rounded focus:ring-yyellow-900 dark:focus:ring-yyellow-900 border-yyellow-900"
                                            />
                                            <span className="ml-2 align-top">
                                                I have read and accept the <u>Privacy Policy</u>
                                            </span>
                                        </span>
                                    </label>
                                </div>
                                <button className={clsx('px-3 py-3 w-full text-md font-semibold rounded-xl cursor-pointer', !isAcceptConsent && 'bg-ggrey-200 text-neutral-500 disabled', isAcceptConsent && 'bg-yyellow-500  text-black')} onClick={handleAccept}>Accept</button> 
                            </div>
                        </div>
                      </div>
                </Container>
            </div>
        </MsalAuthenticationTemplate>
    </>
  );
}
