import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";
import axios from "axios";
import ImageIconCafe from "../images/icons/v4/missiondetail/coffee_icon.svg";
import ImageIconRedeem from "../images/icons/v4/redeem/redeemed-tag.svg";
import ImageIconCompleted from "../images/icons/v4/missionstatus/icon-missioncomplete.svg";
import ImageIconReading from "../images/icons/v4/icon-reward-reading-society.svg";
import ImageIconShopOutLoud from "../images/icons/v4/icon-reward-shopoutloud.svg";
import ProgressCardButton from "../components/ProgressCardButton";
import { Link } from "react-router-dom";
import ProgressCard from "../components/ProgressCard";
import LogoMissionLanding from "../images/logos/fyxd-logo-header-mission-main.svg";
import ImageTabMission from "../images/icons/v4/missiondetail/target_icon.svg";
import ImageTabReward from "../images/icons/v4/missiondetail/prize_icon.svg";
import ImageMissionStarCafe from "../images/icons/v4/missionlanding/mission-sip-stamp.png";
import ImageMissionShopOutLoud from "../images/icons/v4/missionlanding/mission-shop-out-loud.png";
import ImageMissionSociety from "../images/icons/v4/missionlanding/mission-dtgo-reading-society.png";
import { setCookie, getCookie, removeCookie } from "../utils/cookies";
import IconRedeemNow from "../images/icons/v4/missionstatus/icon-redeemnow.svg";
import IconRedeemed from "../images/icons/v4/missionstatus/icon-redeemed.svg";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { Navbar } from "./components/navbar";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
export default function MissionLandingPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [activeFilter, setActiveFilter] = useState("all");
  const [countCookie, setCookieCount] = useState(0);
  const [load, setLoad] = useState(true);
  const [status, setStatus] = useState("");
  const [redeemStarCafe, setRedeemStarcafe] = useState("");
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const baseURI = process.env.REACT_APP_BASE_URI;
  const [accessToken, setAccessToken] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [missions, setMissions] = useState([]);
  const [rewards, setRewards] = useState([]);
  let Email = "";
  const analytics = getAnalytics();

  const name = accounts[0] && accounts[0].name;
  const email = accounts[0] && accounts[0].username;

  if (email !== undefined) {
    // userName = name;
    Email = email;
  }

  const requestToken = async () => {
    await requestAccessToken();
  };

  const requestAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setAccessToken(response.idToken);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleTabNavMissionClick = () => {
    logEvent(analytics, "Dilly dailly kaffe banner button", {
      userId: name,
      visitation_count: "mission1_banner_count",
    });
  };
  const handleTabRewardClick = () => {
    logEvent(analytics, "Click on voucher", {
      userId: name,
      visitation_count: "voucher1_enter_count",
    });
  };
  const getMission = async () => {
    setLoad(true);
    console.log("getMission");
    try {
      const res = await axios.get(
        `${baseURI}/famzmember3/v1/fyxd/user/missionreward`,
        { headers: { Authorization: "Bearer " + accessToken } }
      );
      setMissions(res.data.data.mission); // Set mission array
      setRewards(res.data.data.reward);
      console.log(res.data);
      setLoad(false);
    } catch (error) {
      console.error(error);
      requestToken();
    }
  };

  const tabs = [
    {
      id: 0,
      title: "All Mission",
      content: "Content for All Mission",
      imgsrc: ImageTabMission,
    },
    {
      id: 1,
      title: "Rewards",
      content: "Content for Rewards",
      imgsrc: ImageTabReward,
    },
  ];

  const filters = [
    { title: "All", value: "all" },
    { title: "Completed/Expired", value: "completed" },
  ];

  useEffect(() => {
    const status = getCookie("status");
    const redeem = getCookie("starcafe");
    setRedeemStarcafe(redeem);
    setStatus(status);
    console.log(status);
    const fetchCookie = () => {
      const t = getCookie("count");
      console.log(`form redeem ${t}`);
      const x = Number(t);
      setCookieCount(x);
    };

    fetchCookie();
  }, []);
  // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (id) {
      setActiveTab(parseInt(id));
    }
  }, [id]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    requestToken();
    getMission();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Email.length > 0 && accessToken.length > 0) getMission();
    if (accessToken.length === 0) requestToken();
    // eslint-disable-next-line
  }, [Email, accessToken, isAuthenticated]);

  const authRequest = {
    ...loginRequest,
  };

  // Count the number of identical rewards (based on name or other criteria)
  const groupedRewards = rewards.reduce((acc, reward) => {
    if (reward.status === "avaliable") {
      const key = reward.name; // Group by name or other criteria (e.g., voucher_code)
      if (!acc[key]) {
        acc[key] = { ...reward, count: 1 };
      } else {
        acc[key].count += 1;
      }
    }
    return acc;
  }, {});

  const groupedRewardsRedeemed = rewards.reduce((acc, reward) => {
    if (reward.status === "redeemed") {
      const key = reward.name; // Group by name or other criteria (e.g., voucher_code)
      if (!acc[key]) {
        acc[key] = { ...reward, count: 1 };
      } else {
        acc[key].count += 1;
      }
    }
    return acc;
  }, {});

  const groupedRewardsExpired = rewards.reduce((acc, reward) => {
    if (reward.status === "expired") {
      const key = reward.name; // Group by name or other criteria (e.g., voucher_code)
      if (!acc[key]) {
        acc[key] = { ...reward, count: 1 };
      } else {
        acc[key].count += 1;
      }
    }
    return acc;
  }, {});

  const rewardList = Object.values(groupedRewards);

  const rewardListRedeemed = Object.values(groupedRewardsRedeemed);
  const rewardListExpired = Object.values(groupedRewardsExpired);
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
      >
        <div className="w-full" style={{ width: "100vw", height: "120vh" }}>
          <div className="static w-full missioncard-gradient p-3 text-center text-white">
            <div className="mb-2 mt-1 text-center ">
              <img
                src={LogoMissionLanding}
                alt="Logo"
                style={{ marginLeft: "0.5rem", display: "inline-block" }}
                className="mx-auto w-[298px] h-[38px]"
              />
            </div>
          </div>
          <div className="mb-4 ">
            <div className="bg-white rounded-t-lg">
              <div className="mb-4">
                <nav className="flex justify-center missioncard-gradient">
                  {tabs.map((tab, index) => (
                    <>
                      <button
                        key={tab.id}
                        className={`py-2 w-full rounded-t-2xl inline-block font-semibold focus:outline-none ${
                          activeTab === index
                            ? "bg-white text-yellow-900"
                            : "text-gray-600 hover:text-yellow-900 bg-gray-100"
                        }`}
                        onClick={() => handleTabClick(index)}
                      >
                        <img
                          src={tab.imgsrc}
                          alt=""
                          className="inline-block mb-1"
                          style={{
                            padding: "1.5px",
                            width: "1.8rem",
                            height: "2rem",
                          }}
                        />
                        {tab.title}
                      </button>
                    </>
                  ))}
                </nav>
              </div>

              <div className="py-2">
                <div className="flex justify-center space-x-2 text-xs ">
                  {filters.map((filter, index) => (
                    <button
                      key={index}
                      className={`py-2 px-4 rounded-full font-medium focus:outline-none ${
                        activeFilter === filter.value
                          ? "bg-yellow-400 text-yellow-900"
                          : "bg-white border border-yellow-300 text-yellow-900 hover:bg-yellow-100"
                      }`}
                      onClick={() => setActiveFilter(filter.value)}
                    >
                      {filter.title}
                    </button>
                  ))}
                </div>
              </div>
              <div className="py-2 px-2">
                <Container>
                  {tabs.map((tab, index) => (
                    <div
                      key={index}
                      className={`tab-panel w-full min-h-screen ${
                        activeTab === index ? "" : "hidden"
                      }`}
                    >
                      {index === 0 && (
                        <>
                          <div className="grid grid-cols-1  gap-1 py-3">
                            {load ? (
                              <ReactLoading
                                className="mx-auto"
                                type="spokes"
                                color="#000"
                              />
                            ) : (
                              <>
                                {missions.map((mission, idx) => (
                                  <>
                                    <div key={idx}>
                                      <div className="relative mb-2 mt-2 mx-auto">
                                        <img
                                          src={mission.banner_img} // Placeholder image 300x150
                                          alt="Placeholder"
                                          className="mx-auto  h-auto rounded-t-lg "
                                        />
                                        <Link
                                          to={"/mission/1"}
                                          onClick={handleTabNavMissionClick}
                                        >
                                          {mission.status === "2" ? (
                                            <ProgressCard
                                              title="Sip and Stamp"
                                              progress={mission.progress}
                                              redeemed={true}
                                              total={mission.target}
                                            />
                                          ) : (
                                            <ProgressCardButton
                                              title="Sip and Stamp"
                                              progress={mission.progress}
                                              total={mission.target}
                                              buttonText="View mission"
                                            />
                                          )}
                                        </Link>
                                        {mission.progress === 10 && (
                                          <>
                                            {status === "redeemed" ? (
                                              <>
                                                {/* <img
                                              src={IconRedeemed}
                                              className="mx-auto w-[200px] absolute top-1 right-20  mb-6 "
                                              alt="Redeemed"
                                            /> */}
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={IconRedeemNow}
                                                  className="w-[6vh] h-[12vh] absolute left-2 top-0 -mt-5 "
                                                  alt="Redeem Now"
                                                />
                                                <img
                                                  src={ImageIconCompleted} // Placeholder image 300x150
                                                  alt="Placeholder"
                                                  className="mx-auto py-1 w-[200px] absolute top-1 right-20  mb-6 filter-yellow-tint"
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </>
                            )}
                          </div>
                        </>
                      )}
                      {index === 1 && (
                        <>
                          {load ? (
                            <ReactLoading
                              className="mx-auto"
                              type="spokes"
                              color="#000"
                            />
                          ) : (
                            <div className="grid grid-cols-2 gap-4 py-2 xl:grid-cols-3">
                              <>
                                {rewardList
                                  .filter(
                                    (reward) =>
                                      activeFilter === "all" ||
                                      (activeFilter === "completed" &&
                                        reward.status === "redeemed")
                                  )
                                  .map((reward) => (
                                    <div
                                      key={reward.id}
                                      className="bg-white shadow rounded-2xl text-center relative"
                                    >
                                      <div className="mx-2 mb-3">
                                        <Link
                                          to={`/reward/starcafe/${reward.voucher_code}`}
                                          onClick={handleTabRewardClick}
                                        >
                                          <div className="flex flex-col space-y-4">
                                            <div className="relative">
                                              <img
                                                src={reward.voucher_img}
                                                alt={reward.name}
                                                className="mx-auto py-3 w-[70%] mt-4"
                                              />
                                              <span className="inline-block text-sm">
                                                {reward.name}
                                              </span>
                                              <span className="inline-block text-sm text-gray-400">
                                                {reward.expired_at !== null
                                                  ? new Date(
                                                      reward.expired_at
                                                    ).toLocaleDateString(
                                                      "en-GB"
                                                    )
                                                  : "31/12/2024"}
                                              </span>
                                              <span className="absolute top-1 right-1 bg-yellow-200 rounded-full px-2 py-1 text-xs font-bold">
                                                x{reward.count}
                                              </span>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                                {rewardListRedeemed.map((reward) => (
                                  <div
                                    key={reward.id}
                                    className="bg-white shadow rounded-2xl text-center relative"
                                  >
                                    <div className="mx-2 mb-3">
                                      {/* <a
                                        href={`#/reward/starcafe/${reward.voucher_code}`}
                                      > */}
                                      <div className="flex flex-col space-y-4">
                                        <div className="relative">
                                          <img
                                            src={ImageIconCafe} // Placeholder image 300x150
                                            alt="Placeholder"
                                            className="mx-auto py-3 w-[70%] filter grayscale mt-4 "
                                          />
                                          <img
                                            src={ImageIconRedeem} // Placeholder image 300x150
                                            alt="Placeholder"
                                            className="mx-auto py-3 w-[85%] absolute top-8 right-3 mb-6 "
                                          />
                                          <span className="inline-block text-sm">
                                            {reward.name}
                                          </span>
                                          <span className="inline-block text-sm text-gray-400">
                                            {reward.expired_at !== null
                                              ? new Date(
                                                  reward.expired_at
                                                ).toLocaleDateString("en-GB")
                                              : "31/12/2024"}
                                          </span>
                                          <span className="absolute top-1 right-1 bg-yellow-200 rounded-full px-2 py-1 text-xs font-bold filter grayscale">
                                            x{reward.count}
                                          </span>
                                        </div>
                                      </div>
                                      {/* </a> */}
                                    </div>
                                  </div>
                                ))}
                              </>

                              {rewardListExpired.map((reward) => (
                                <div
                                  key={reward.id}
                                  className="bg-gray-100 shadow  rounded-2xl text-center relative"
                                >
                                  <div className="mx-2 mb-3">
                                    {/* <a
                                        href={`#/reward/starcafe/${reward.voucher_code}`}
                                      > */}
                                    <div className="flex flex-col space-y-4">
                                      <div className="relative">
                                        <img
                                          src={ImageIconCafe} // Placeholder image 300x150
                                          alt="Placeholder"
                                          className="mx-auto py-3 w-[70%] filter grayscale mt-4 "
                                        />
                                        <span className="inline-block text-sm">
                                          {reward.name}
                                        </span>
                                        <span className="inline-block text-sm text-gray-400">
                                          {reward.expired_at !== null
                                            ? new Date(
                                                reward.expired_at
                                              ).toLocaleDateString("en-GB")
                                            : "31/12/2024"}
                                        </span>
                                        <span className="absolute top-1 right-1 bg-yellow-200 rounded-full px-2 py-1 text-xs font-bold filter grayscale">
                                          x{reward.count}
                                        </span>
                                      </div>
                                    </div>
                                    {/* </a> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </Container>
              </div>
            </div>
          </div>
          <div className="h-12" />
          {/* Navbar */}
          <Navbar path="/missionlanding/0" />
        </div>
      </MsalAuthenticationTemplate>
    </>
  );
}
