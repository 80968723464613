import React from "react";

const ProgressCard = ({
  title,
  progress,
  total,
  completed,
  expire,
  fully,
  redeemed,
}) => {
  const progressPercentage = (progress / total) * 100;
  let statusText = "";
  let statusColor = "";
  switch (true) {
    case completed:
      statusText = "Completed!";
      statusColor = "text-green-500";
      break;
    case expire:
      statusText = "Expired";
      statusColor = "text-red-500";
      break;
    case fully:
      statusText = "Fully Redeemed";
      statusColor = "text-red-500";
      break;
    case redeemed:
      statusText = "Completed! (Redeemed)";
      statusColor = "text-red-500";
      break;
    default:
      break;
  }

  return (
    <div
      className="bg-[#FFF0D7] justify-between item-center rounded-b-2xl "
      style={{ backgroundColor: expire || fully ? "#DBDBDB" : "#FFF0D7" }}
    >
      <div className="mx-3 flex flex-col space-y-2 py-3">
        <h2 className="text-baseline font-bold">{title}</h2>
        {!completed && !expire && !fully && !redeemed && (
          <>
            <span className="text-sm text-gray-500">{`${progress}/${total}`}</span>
            <div className="relative h-4 bg-white rounded-full">
              <div
                className="absolute top-0 left-0 h-4 bg-[#FFC657] rounded-full"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </>
        )}
        {(completed || expire || fully || redeemed) && (
          <span
            className={`text-center ${statusColor} font-bold ${statusColor}`}
          >
            {statusText}
          </span>
        )}
      </div>
    </div>
  );
};

export default ProgressCard;
