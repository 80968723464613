import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/index.css";

import ImageIconShopOutLoud from "../images/icons/v4/highlight/shop_out_loud.png";
import ImageIconReadingSociety from "../images/icons/v4/highlight/reading_society.png";
import ImageSipAndCollect from "../images/icons/v4/highlight/ship_and_stamp.png";
import ImageCoffeeSet from "../images/icons/v4/highlight/coffee_set.png";

export default function HighligtPage(props) {
  return (
    <>
      <div style={{ width: "100vw", height: "100vh" }}>
        <Container className="mt-4">
          <a href="#/">
            <h1 className="float-left">{`<`}</h1>
          </a>
          <h2
            className="heading--main text-center"
            style={{ fontWeight: "300" }}
          >
            Highlight
          </h2>

          <div className="mt-5">
            <div className="grid-container">
              <div className="grid-item">
                <a href="#/mission/1">
                  <img src={ImageSipAndCollect} alt="Sip and Collect" />
                  <span className="text-sm mx-2">Sip and Stamp</span>
                  <button className="float-right text-xs mb-1">View</button>
                </a>
              </div>
              {/* <div className="grid-item">
                <img src={ImageIconReadingSociety} alt="DTGO Reading Society" />
                <span className="text-sm mx-2">DTGO Reading Society</span>
                <button className="float-right text-xs mb-1">View</button>
              </div>
              <div className="grid-item">
                <img src={ImageIconShopOutLoud} alt="Shop Out Loud" />
                <span>Shop Out Loud</span>
                <button className="float-right text-xs mb-1">View</button>
              </div>
              <div className="grid-item">
                <img src={ImageCoffeeSet} alt="Coffee Set" />
                <span>Coffee Set</span>
                <button className="float-right text-xs mb-1">View</button>
              </div> */}
            </div>
          </div>
        </Container>
        {/* <Navbar path="/info" /> */}
      </div>
    </>
  );
}
