import { useContext, useState, useEffect, useQuery } from "react";
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/index.css";
import axios from 'axios';
import ReactLoading from 'react-loading';
import {QRCode} from 'react-qrcode-logo';
import Pusher from 'pusher-js';
import Modal from 'react-modal';
import { subHours } from 'date-fns';


import LogoFyxdLoader from "../images/logos/fyxd-logo-loader.svg";
import IconRefresh from "../images/icons/v2/refresh.svg";
import ChevronLeft from "../images/icons/v2/chevron-left.svg";
import ClosedCircle from "../images/icons/v2/closed-circle.svg";
import CircleTick from "../images/icons/v2/circle-tick.svg";
import DLogoColor from "../images/logos/d-logo-color.svg";
import PromptPayLogo from "../images/logos/promptpay.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import Error from './error-page';

import { TransactionStatus } from "../enums";

const DEFAULT_AMOUNT= 250;
const DEFAULT_COUNTDOWN_SECONDS = 60 * 10;

// This feature flag determines if we are using the daily limit feature or the per transaction
// maximum feature. 
const ENABLE_DAILY_LIMIT = false;

const TopupSteps = {
  STEP_AMOUNT: 0,
  STEP_QR: 1,
  STEP_SUCCESS: 2
}


// TODO: Switch back
// const baseURI = "https://uat-api.infinyxpos.com";
const baseURI = "https://prod-api.infinyxpos.com";

export default function TopupPage() {

    const [searchParams] = useSearchParams();

    const initialTopupAmount = searchParams.get("topup_amount");

    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const [idToken, setIdToken] = useState('');
    const [authenticated, setAuthenticated] = useState(false);

    const [transactionId, setTransactionId] = useState("");
    const [transactionDate, setTransactionDate] = useState("");
    const [transactionTime, setTransactionTime] = useState("");

    const email = accounts[0] && accounts[0].username;

    const Email = email;
     
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(false);
    const [load, setLoad] = useState(true);
    const [barcodeInfo, setBarcodeInfo] = useState("");
    const [expiresAt, setExpiresAt] = useState("");
    const [isExpired, setExpired] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [remainingTopupFyxd, setRemainingTopupFyxd] = useState(0.00);
    const [step, setStep] = useState(TopupSteps.STEP_AMOUNT);
    const [topupAmount, setTopupAmount] = useState(initialTopupAmount ?? DEFAULT_AMOUNT);
    const [channel, setChannel] = useState("Unknown");
    
    const [isValidMinimum, setValidMinimum] = useState(true);
    const [isValidMaximum, setValidMaximum] = useState(true);
    const [isValidDivisible, setValidDivisible] = useState(true);

  // const getFYXDDashboardData = async (token) => {

  function formatTimestamp(date) {

    // Get day, month, year, hour, and minutes
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so we add 1
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date and time
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  function formatDate(date) {

    // Get day, month, year, hour, and minutes
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so we add 1
    const year = date.getFullYear();

    // Format the date and time
    return `${day}-${month}-${year}`;
  }
  function formatTime(date) {

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Format the date and time
    return `${hours}:${minutes}`;
  }

  const requestAccessToken = async () => {
      const request = {
          ...loginRequest,
          account: accounts[0]
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
        setIdToken(response.idToken);
      }).catch((e) => {
          console.log(e);
      });
  }

  const requestToken = async () => {
      await requestAccessToken();
  }

  const PaymentChannels = {
    KPlus: "kplus",
    PromptPay: "promptpay"
  }
  const getChannelDisplay = (input) => {
    console.log(input);
    switch(input){
      case PaymentChannels.KPlus:
        return "K PLUS";
      case PaymentChannels.PromptPay:
        return "PromptPay";
      default:
        return "PromptPay";
    }
  }
  
  useEffect(() => {
    if(topupAmount % 50 !== 0) {
      const rounded50 = roundUpToNearest50(topupAmount);
      setTopupAmount(rounded50);
    }
  }, []);

  useEffect(() => {
    setAuthenticated(isAuthenticated);
    requestAccessToken()
    
    // if(!isAuthenticated) {
    //     navigate("/login");
    // } else {
    //     requestToken();
    // }
    // setLoad(false);
    }, [authenticated]);

    useEffect(() => {
      if(idToken && idToken.length > 0){
        fetchInitialData();
      }
      if(idToken.length === 0)
        requestToken();
    }, [idToken, isAuthenticated])
    const fetchInitialData = async () => {
      {
        setLoad(true);
        // get transactions first to see if there are any pending
        await axios.post(`${baseURI}/autotopup/v1/fyxd/transactions/clear`, 
          {},
          {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
        )
        const resp = await axios.get(`${baseURI}/autotopup/v1/fyxd/transactions?status=${TransactionStatus.Pending}`, 
            {
              headers: {
                Authorization: `Bearer ${idToken}`
              }
            }
          )
          let isFound = false;
          for(const txn of resp.data.data){
            console.log(txn);
            const diff = (new Date(txn.created_at).getTime() + 60*10*1000) - new Date().getTime();
            console.log(diff);
            if(diff > 0){
              setTransactionAsCurrent(txn);
              setStep(TopupSteps.STEP_QR)
              subscribePusher();
              setLoad(false);
              isFound = true;
              break;
            }
          }
          if(!isFound){
            // if not go to profile
            getProfile(idToken).then(()=>{
              subscribePusher();
              setLoad(false);
            })
          }
      }
    }
    
    
    const incrementTopupAmount = (num) => {
      setTopupAmount(Number(topupAmount)+Number(num));
    };

    const  roundUpToNearest50 = (value) => {
      return Math.ceil(value / 50) * 50;
    };

    useEffect(() => {
      if(topupAmount > 100000) {
        setTopupAmount(100000);
      }

      setValidMinimum( topupAmount !== "" && topupAmount >= 50 );
      setValidDivisible( topupAmount !== "" && topupAmount % 50 === 0 );
      if(ENABLE_DAILY_LIMIT){
        setValidMaximum( topupAmount !== "" && topupAmount <= remainingTopupFyxd );
      } else {
        setValidMaximum( topupAmount !== "" && topupAmount <= 100_000 );
      }
      
    }, [topupAmount, remainingTopupFyxd]) 

    useEffect(() => {
      let timerId;
  
      if (runTimer) {
        timerId = setInterval(() => {
          setCountDown((countDown) => countDown - 1);
        }, 1000);
      } else {
        clearInterval(timerId);
      }
  
      return () => clearInterval(timerId);
    }, [runTimer]);
  
    useEffect(() => {
      if (countDown < 0 && runTimer) {
        setRunTimer(false);
        setCountDown(0);
        setExpired(true);
        try{
          const response = cancelTransaction(transactionId);
          console.log(response?.data);
        } catch(error) {
            console.log(error);
        }
      }
    }, [countDown, runTimer]);
    

    const generateTransactionReqBody = (amount, timestampStr) => {
      return {
        payment_method_type: "kbank",
        payment_type: "dynamic",
        amount,
        timestamp: timestampStr
      }
    }
    const getBarcodeInfo = async () => {
        setLoad(true);
        const body = generateTransactionReqBody(topupAmount, new Date().toString());
        axios.post(`${baseURI}/autotopup/v1/fyxd/transactions`, 
          body,
          {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
        )
          .then(function (response) {
            /*
            response body format:
            {
              status: string;
              transaction: FyxdTransactionInstance;
              error_code: string;
              error_message: string;
            }
            */
            // TODO: check for status
            const {transaction} = response.data;
            
            setTransactionAsCurrent(transaction);
        })
          .catch(function (error) {
            console.log(error);
            const balance = error?.response?.data?.remaining_topup_balance;
            if(balance){
              setRemainingTopupFyxd(balance);
            }
            setBarcodeInfo("");
            setExpiresAt("");
            setError(true);
            setErrorMessage("Error occurred while fetching QR, please try again");
            setLoad(false);
            setRunTimer(false);
            setCountDown(0);
        });
    }

    const setTransactionAsCurrent = (transaction) => {
      setBarcodeInfo(transaction.payload);
      const createdAt = subHours(new Date(transaction.created_at), 7);
      const expiryDate = new Date(createdAt.getTime() + DEFAULT_COUNTDOWN_SECONDS * 1000);
      const diff = expiryDate.getTime() - new Date().getTime();
      setExpiresAt(formatTimestamp(expiryDate));
      setTransactionDate(formatDate(createdAt));
      setTransactionTime(formatTime(createdAt));
      setCountDown(Math.floor(diff/1000));
      setExpired(false);
      setError(false);
      setErrorMessage("");
      setRunTimer(true);
      setLoad(false);
      setTransactionId(transaction.transaction_id);
    }

    const getProfile = async (token) => {
      // TODO: try disabling this if ENABLE_DAILY_LIMIT is false to 
      // see if we can skip without issues. This would improve initial load times
      try{

        const response = await axios.get(`${baseURI}/autotopup/v1/fyxd/profile`, 
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        const {member} = response.data
        setRemainingTopupFyxd(member.remaining_topup_fyxd);
      } catch(error) {
          console.log(error);
      }
  }

    const subscribePusher = () => {
          // Enable pusher logging - don't include this in production
          Pusher.logToConsole = true;

          let pusher = new Pusher('41f645ee262451810d50', {
            cluster: 'ap1'
          });
          let pusherChannel = pusher.subscribe(Email);
          pusherChannel.bind('topup-notify', function(data) {
            console.log(data)
            setChannel(getChannelDisplay(data.transaction.channel));
            setStep(TopupSteps.STEP_SUCCESS)
          });
    }

    const pollingInquiryKbankStatus = async () => {
      const intervalId = setInterval( async () => {

        const res = await axios.get(`${baseURI}/autotopup/v1/fyxd/transactions/${transactionId}`, 
          {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
        );

        const { data } = res.data;

        if(data.status === 1) {
          setChannel(getChannelDisplay(data.channel));
          setStep(TopupSteps.STEP_SUCCESS);
          clearInterval(intervalId);
        }
      }, 2000); // 2 seconds in milliseconds
  
      return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
    };

    useEffect(() => {
      if(step === TopupSteps.STEP_QR) {
        if(!load && barcodeInfo === "") {
          getBarcodeInfo();
        }
          pollingInquiryKbankStatus();
      }
    }, [step, load]);
  
    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);


    function resetValue() {
      setTopupAmount("");
    }

    const cancelTransaction = async (txnId) => {
      try{
         await axios.put(`${baseURI}/autotopup/v1/fyxd/transactions/${txnId}/cancel`, {},
          {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }
        )
        setTransactionId("");
      } catch(err){
        console.error(err);
      }
        
    }

    const handleCancel = async () => {
      // this if condition handles the case where an error occurs
      // while generating a QR code, in which case transactionId would be blank
      // and it would just create errors when we try to cancel it
      if(transactionId){
        try{
          const response = await cancelTransaction(transactionId);
          console.log(response?.data);
          navigate(-1);

        } catch(error) {
            console.log(error);
        }
      } else {
        navigate(-1);
      }
      
    }

    const handleRefresh = async () => {
      try{
        // this if condition handles the case where an error occurs
        // while generating a QR code, in which case transactionId would be blank
        // and it would just create errors when we try to cancel it
        if(transactionId){
          const response = await cancelTransaction(transactionId);
          console.log(response?.data);
        }
        getBarcodeInfo(); 
      } catch(error) {
          console.log(error);
      }
      
    }


    const styles = {
      headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '60px',
        position: 'relative',
        borderBottom: '2px solid #f6f6f6'
      },
      backButton: {
        position: 'absolute',
        left: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        cursor: 'pointer'
      },
      title: {
        fontSize: '18px',
        fontWeight: 'bold'
      },
      topupQr: {
        height: '15rem'
      }
    };

    const authRequest = {
      ...loginRequest
    };

  const getTopupLimitString = () => {
    if(ENABLE_DAILY_LIMIT){
      return `Remaining daily top-up amount is ${remainingTopupFyxd.toLocaleString()} Baht`
    } else {
      return "Maximum top-up amount is 100,000 Baht"
    }
    
  }

  const handleTopupAmountChange = (inputStr) => {
    // inputStr is a string from the text input field, need to clean
    // since we display it as a LocaleString, there will be comma separators to remove
    const temp = inputStr.replaceAll(',','');
    // only update setTopupAmount if the input is a number
    if(!isNaN(Number(temp))){
      setTopupAmount(Number(temp))
    }
  }

  return (  <>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
            errorComponent={Error} 
        >
          <div style={{"width": "100vw", "height": "100vh"}}>           
              {load && <div>
                <div style={{display:"flex", justifyContent:"center",alignItems:"center"}} className="topup-loader-overlay">
                  <div className="topup-loader-modal">
                    <img className="" alt="" src={LogoFyxdLoader}/>
                  </div>
                  
                </div>
              </div> 
              }
              <Container className="mt-4">
                
                {step === TopupSteps.STEP_AMOUNT && 
                
                <div>
                  <div style={styles.headerContainer}>
                      <button style={styles.backButton}  onClick={()=>{navigate(-1);}}>
                          <img src={ChevronLeft} alt=""/>
                        </button>
                      <div style={styles.title}>
                        Top up
                      </div>
                  </div>
                  <div className="topup_amount-box">
                    <div className="topup_amount-header">Top-up amount</div>
                    <div className="topup_amount-input-container">
                      <span className="symbol">฿</span>
                      <div className="divider"></div>
                      <input 
                        type="string" 
                        value={topupAmount.toLocaleString()} 
                        onChange={(e) => 
                          handleTopupAmountChange(e.target.value)
                        } 
                        className="number-input"
                        placeholder="Enter amount here"
                      />
                      <img src={ClosedCircle} className="cross-icon" onClick={resetValue}></img>
                    </div>
                    {topupAmount!== "" && <div className="mt-2 topup_amount-text">You will receive {topupAmount.toLocaleString()} FYX-D</div>}
                    <div className="mt-2 topup_amount-button-container">
                      <div className="topup_amount-button" onClick={()=>incrementTopupAmount(50)}>+ 50</div>
                      <div className="topup_amount-button" onClick={()=>incrementTopupAmount(100)}>+ 100</div>
                      <div className="topup_amount-button" onClick={()=>incrementTopupAmount(500)}>+ 500</div>
                      <div className="topup_amount-button" onClick={()=>incrementTopupAmount(1000)}>+ 1,000</div>
                    </div>
                    <div className="mt-4 text-sm">
                      {isValidMinimum ? 
                      <div> 
                        <img src={CircleTick} alt="" className="topup_valid-icon"/>
                        <div style={{display:"inline-block", verticalAlign:"middle"}}>
                          Minimum top-up amount is 50 Baht
                        </div>
                        
                      </div> :
                      <div className="topup_invalid-text">
                        &times; Minimum top-up amount is 50 Baht
                      </div>
                      }
                      {isValidMaximum ? 
                      <div> 
                        <img src={CircleTick} alt="" className="topup_valid-icon"/>
                        <div className="inline-block align-middle">
                          {getTopupLimitString()}
                        </div>
                        
                      </div> :
                      <div className="topup_invalid-text">
                        &times; {getTopupLimitString()}
                      </div>
                      }
                      {isValidDivisible ? 
                      <div> 
                        <img src={CircleTick} alt="" className="topup_valid-icon"/>
                        <div style={{display:"inline-block", verticalAlign:"middle"}}>
                          Top-up amount must be divisible by 50
                        </div>
                      </div> :
                      <div className="topup_invalid-text">
                        &times; Top-up amount must be divisble by 50
                      </div>
                      }
                      
                    </div>

                    <button className="topup_next-button" disabled={!isValidDivisible || !isValidMinimum || !isValidMaximum} onClick={()=>setStep(TopupSteps.STEP_QR)}>Next</button>
                  </div>
                </div>
                }
                {step === TopupSteps.STEP_QR && 
                <div style={{display:"flex", flexDirection:"column", gap:"0.75rem", alignItems:"center", alignSelf:"stretch"}} className="px-2 py-4">
                  <div style={{width: "fit-content", display: "flex", fontWeight:"600", fontSize:"1.25rem", alignItems:"center", justifyItems:"center" }} className="mx-auto">Top-up amount: {topupAmount} Baht</div>
                  {(isExpired || isError) ? <div style={{width: "14.75rem", color:"#E46666"}} className="mx-auto mt-4 flex font-normal text-center">
                    {isError ? errorMessage : "The QR code has expired. Please refresh the QR code."}
                  </div> :
                  <div style={{width: "14.75rem", color:"#8F9091"}} className="mx-auto mt-4 flex font-normal text-center">
                    Please screenshot and upload this QR code to your bank’s app.
                  </div>}

                  <div className="text-center">
                    <div className={`mx-auto ${(isExpired || isError) && "opacity-10"}`} >
                      { 
                      // these conditions are for making sure the component doesn't appear 
                      // until we get the payload from kbank
                      !load && barcodeInfo.length > 0 && <QRCode 
                      size={250} 
                      ecLevel="L" 
                      value={barcodeInfo} 
                      logoImage={PromptPayLogo} 
                      logoWidth={50} 
                      logoHeight={37} />}
                    </div>
                    

                      
                  </div>
                  {expiresAt !== "" && <div className="text-center" style={{color:"#B58C3E"}}>
                    <span style={{display:"block"}}>QR Valid thru: {expiresAt}</span> 
                    <span style={{display:"block"}}>( QR มีอายุ 10 นาที)</span>
                  </div>}
                  <div className="text-center">
                      {/* <span>Count down timer</span> */}
                      <span>{minutes}:{seconds}</span>
                      
                  </div>
                  <div className="w-100 ">
                    <button 
                      className="topup-refresh-button mx-auto w-100 flex justify-center items-center" 
                      onClick={handleRefresh}
                    >
                      <img style={{marginRight:"0.25rem"}} alt="" src={IconRefresh}/>
                      <span>Refresh QR</span>
                    </button>
                  </div>
                  <div className="w-100 ">
                    <button className="topup-cancel-button mx-auto w-100" onClick={handleCancel}>
                      <span>Cancel Top-up</span>
                    </button>
                  </div>
                  
                </div>
                }
                
                {step === TopupSteps.STEP_SUCCESS && 
                <div className="topup_amount-box">

                    <div style={{width: "fit-content", display: "flex", fontWeight:"600", fontSize:"1rem", alignItems:"center", justifyItems:"center" }} className="mx-auto">Top up Successful (QR)</div>
                    <div className="grid space-y-4 mt-8">
                      <div className="grid grid-cols-2 space-between">
                        <div className="col-span-1 text-left">Payment Channel</div>
                        <div className="col-span-1 text-right">{channel}</div>
                      </div>
                      <div className="grid grid-cols-2 space-between font-bold">
                        <div className="col-span-1 text-left">Top-up Amount</div>
                        <div className="col-span-1 text-right">฿ {Number(topupAmount).toFixed(2)}</div>
                      </div>
                      <div className="grid grid-cols-2 space-between font-bold">
                        <div className="col-span-1 text-left">Received FYX-D</div>

                        <div className="col-span-1 text-right">
                          <img src={DLogoColor} alt="" className="inline-block w-5 h-5 flex-shrink-0 mr-1"/>
                          {Number(topupAmount).toFixed(2)
                        }</div>
                      </div>
                      </div>
                      <div className="grid mt-8 space-y-4 items-center align-middle">
                        <div className="grid grid-cols-2 space-between">
                          <div className="col-span-1 text-left">Transaction ID</div>
                          <div className="col-span-1 text-right">{transactionId}</div>
                        </div>
                        <div className="grid grid-cols-2 space-between">
                          <div className="col-span-1 text-left">Transaction Date</div>
                          <div className="col-span-1 text-right">{transactionDate}</div>
                        </div>
                        <div className="grid grid-cols-2 space-between">
                          <div className="col-span-1 text-left">Transaction Time</div>
                          <div className="col-span-1 text-right">{transactionTime}</div>
                        </div>
                      </div>
                      

                      

                    <button onClick={()=>{
                      // the navigate(0) below is necessary to force the re-render of the home screen
                      // once we redirect back to it, otherwise the wallet balance may not be updated
                      // reference: https://stackoverflow.com/questions/74525254/usenavigate-hook-does-not-re-render-components-can-i-force-it
                      navigate('/');
                      navigate(0);
                      }} className="topup_next-button">Back to Home</button>

                </div>
                
                }
              </Container>

          </div>
        </MsalAuthenticationTemplate>
    </>
  );
}